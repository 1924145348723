import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SSOConectorService, Token } from '@wizsolucoes/ngx-wiz-sso';
import { ValidateCPFService } from 'src/app/core/services/validate-cpf.service';
import { Profile } from 'src/app/interfaces/profile';
import { Tenant } from 'src/app/interfaces/tenant';
import { AdminService } from 'src/app/services/admin.service';
import { AppConfiguration } from 'src/app/services/configuration/configuration';
import { ConfigurationInMemoryService } from 'src/app/services/configuration/in-memory/configuration-in-memory.service';
import { TenantService } from 'src/app/services/configuration/tenant/tenant.service';
import { ProfileService } from 'src/app/services/profile.service';
import { PublicAuthService } from 'src/app/services/public-auth.service';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
  // formulario utilizado para extracao de dados para efetuar login ou cadastrar usuario
  public loginForm: FormGroup;
  public hidePassword = true;
  public configuration: AppConfiguration | undefined;
  public selected = "";

  private tenants: Tenant[] = [];
  private mensagemCpf = "";
  private mensagemSenha = "";
  private isLogging = false;
  private isLoading = false;
  public defaultLogo: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly validateCPFService: ValidateCPFService,
    private readonly configInMemoryService: ConfigurationInMemoryService,
    private readonly publicAuthService: PublicAuthService,
    private readonly ssoConectorService: SSOConectorService,
    private readonly adminService: AdminService,
    private readonly profileService: ProfileService,
    private readonly router: Router,
    private readonly tenantService: TenantService
  ) {
    this.loginForm = this.formBuilder.group({
      cpf: ['', [Validators.required]],
      password: ['', [Validators.required]],
      tenant: ['', [Validators.required]]
    });
  }

  public get Logging(): boolean {
    return this.isLogging;
  }

  public get MensagemErroCpf(): string {
    return this.mensagemCpf;
  }

  public get MensagemErroSenha(): string {
    return this.mensagemSenha;
  }

  public get LoadingTenants(): boolean {
    return this.isLoading;
  }

  public get Tenants(): Tenant[] {
    return this.tenants;
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    this.configInMemoryService.configurationCurrent$.subscribe((res) => {
      if (Object.keys(res).length === 0) {
        this.defaultLogo = './assets/logo-login-default.png'
      }
      this.configuration = res;
    });
  }

  public canLogin(): boolean {
    if (this.loginForm.value.cpf === '')
      return false;
    if (this.loginForm.value.password === '')
      return false;
    if (this.loginForm.value.tenant === '')
      return false;
    return true;
  }

  public onKeyUpCpf(): void {
    this.mensagemCpf = '';
    this.mensagemSenha = '';
    this.tenants = [];
    let cpf = this.loginForm.value.cpf;
    this.loginForm.setValue({ cpf: cpf, password: '', tenant: '' });

    if (this.validateCpf(cpf))
      this.loadingTenants(cpf);
  }

  public onKeyUpSenha(): void {
    this.mensagemSenha = '';
  }


  // função que verifica se o CPF é válido ou não
  private validateCpf(cpf: string): boolean {
    if (!cpf || cpf.length === 0)
      return false;
    if (this.validateCPFService.validate(cpf))
      return true;
    this.mensagemCpf = 'CPF inválido. Por gentileza, tente novamente!';
    return false;
  }

  // funcao que processa a lista de tenants para o CPF
  private loadingTenants(cpf: string) {
    this.isLoading = true;
    this.publicAuthService.getTenantsByCpf(cpf).subscribe(
      (res) => {
        this.tenants = res;
        if (this.tenants.length === 1)
          this.selected = this.tenants[0].shortName;
        else
          this.selected = '';
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.mensagemCpf = 'Login inválido. Seu usuário não possui acesso ao sistema!'
      }
    );
  }

  public login(): void {
    this.isLogging = true;
    this.mensagemSenha = '';
    this.ssoConectorService.loginWithCredentials(
      {
        userName: this.validateCPFService.removeDigits(this.loginForm.value.cpf),
        password: this.loginForm.value.password
      }
    )
      .subscribe(
        (res) => {
          this.routeByProfile(res);
          this.isLogging = false;
        },
        (error) => {
          this.isLogging = false;
          this.mensagemSenha = 'Senha incorreta. Tente novamente!';
        }
      );
  }

  private routeByProfile(token: Token): void {
    let config = this.tenants.find((x) => x.shortName === this.loginForm.value.tenant);
    this.configInMemoryService.saveConfiguration(config as AppConfiguration);
    this.tenantService.setTenant(this.loginForm.value.tenant);
    this.adminService.getProfileByUserInToken(token, this.loginForm.value.tenant).subscribe(
      (res) => {
        console.log('res', res)
        if (res.length === 0)
          this.routeNoProfile();
        else {
          this.routeWithProfile(res[0]);
          localStorage.setItem('isAdmin', 'true')
        }
      },
      (err) => {

      }
    )
  }

  private routeNoProfile(): void {
    this.publicAuthService
      .checkCPFToNaWiz(this.loginForm.value.cpf)
      .subscribe(
        (res) => {
          if (res === null) {
            this.router.navigate(['login']); // Reinicia o processo de login
          } else {
            localStorage.setItem('wizer', JSON.stringify(res)); // Armazena o objeto to na Wiz
            this.router.navigate(['onboarding']);
          }
        },
        (err) => {
        }
      );
  }

  private routeWithProfile(profile: Profile) {
    const token = SSOConectorService.isLogged();
    const jwtData = this.profileService.parseJwt(token.hash); // resgatando token
    const obj = {
      // objeto que sera salvo no local storage, p/ que o header e foto no teams utilize os campos necessarios
      emailCorporativo:
        jwtData[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
        ],
      nome: jwtData[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
      ],
      cpf: jwtData.documento,
    };
    window.localStorage.setItem('wizer', JSON.stringify(obj));
    this.router.navigate(['admin']);
  }
}
