<section class="login-page">
  <div class="login-page-container">
    <div class="login-form">
      <div class="box-login-page">
        <div
          class="content mw40"
          [ngClass]="{
            'margin-left': !registrationSuccess,
            'centralize-vertically': registrationSuccess
          }"
        >
          <div class="login-card">
            <div class="login-info">
              <!-- Animacao quando o wizer vai criar conta coporativa -->
              <div *ngIf="createAccountSSO && animationController" class="pyro">
                <div class="before"></div>
                <div class="after"></div>
              </div>
              <div class="conteudo-login">
                <div class="welcome" *ngIf="showWelcome; else show_back">
                  <div class="title">Boas vindas a</div>
                  <img
                    [class.logo-margin]="showInputSenha"
                    [src]="configuration?.themeAttributes?.logo ? configuration?.themeAttributes?.logo : this.defaultLogo"
                    alt=""
                  />
                </div>
                <ng-template #show_back>
                  <div
                    *ngIf="!registrationSuccess"
                    [ngClass]="{
                      'top-section mb16': showHeader,
                      'tal mb16': !showHeader
                    }"
                  >
                    <div
                      *ngIf="showHeader"
                      class="back-button"
                      (click)="voltarInserirSenha()"
                    >
                      <span
                        style="margin-right: 8px; color: var(--primary-medium)"
                        class="fas fa-arrow-left"
                      ></span>
                      <span>Voltar</span>
                    </div>
                    <img
                      [src]="configuration?.themeAttributes?.logo"
                      alt="logo"
                      style="max-width: 100px"
                    />
                  </div>
                </ng-template>
                <form [formGroup]="loginForm">
                  <div class="form-content">
                    <!-- ###### INPUTS QUANDO EH UM SIMPLES LOGIN ####### -->
                    <div *ngIf="showInputCPF" class="cpf">
                      <span class="cpf-input-text"
                        >Para começar, digite seu CPF</span
                      >
                      <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>CPF</mat-label>
                        <input
                          title="cpf"
                          matInput
                          type="cpf"
                          maxlength="14"
                          mask="000.000.000-00"
                          name="cpf"
                          formControlName="cpf"
                          (keyup)="onKeyUpCpf()"
                        />
                      </mat-form-field>
                    </div>

                    <div
                      *ngIf="loadingCpf"
                      class="form-group mt-3 colorSpinner"
                    >
                      <span>
                        <em class="fas fa-circle-notch fa-spin mr-2"></em>
                        Carregando
                      </span>
                    </div>
                    <div *ngIf="showInputSenha" class="senha">
                      <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>Senha</mat-label>
                        <input
                          title="password"
                          [type]="hide ? 'password' : 'text'"
                          matInput
                          formControlName="senha"
                        />
                        <link
                          href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
                          rel="stylesheet"
                        />
                        <mat-icon
                          matSuffix
                          fontSet="material-icons-outlined"
                          (click)="hide = !hide"
                          >{{
                            hide ? 'visibility' : 'visibility_off'
                          }}</mat-icon
                        >
                      </mat-form-field>
                    </div>

                    <!-- Mensagens de erro  -->
                    <div *ngIf="mensagemErro" class="error-message">
                      <img
                        src="../../../assets/general/to-na-wiz/auth/warning.svg"
                        alt=""
                      />
                      <span>
                        {{ mensagemErro }}
                      </span>
                    </div>

                    <div class="submit-buttons">
                      <button
                        *ngIf="showLoginButton || isSimpleLogin"
                        type="submit"
                        id="login"
                        [ngClass]="{
                          'submit-button': loginEnabled(),
                          'disabled-submit': !loginEnabled()
                        }"
                        (click)="login()"
                        [disabled]="!loginEnabled()"
                      >
                        <span *ngIf="!loadingButton">Entrar</span>
                        <span *ngIf="loadingButton">
                          <em class="fas fa-circle-notch fa-spin mr-2"></em>
                          Carregando
                        </span>
                      </button>
                      <button
                        *ngIf="isSimpleLogin"
                        class="font-esqueci-senha secondary"
                        (click)="esqueciSenha()"
                      >
                        Esqueci a senha
                      </button>
                    </div>

                    <!-- ###### INPUTS DE ESQUECI A SENHA ####### -->

                    <div *ngIf="loadingUserToNaWiz" class="mt-5 text-center">
                      <!-- <img
                        class="img-fluid loadingIzzy"
                        src="../../../assets/multitenant/loading.svg"
                        alt="Gif loading"
                      /> -->
                      <p><label for="">Carregando...</label></p>
                      <div class="spinner-grow" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>

                    <div class="cellphone-recovery" *ngIf="showEsqueciSenha">
                      <div class="title">
                        <span>Esqueceu a senha? Sem problemas!</span>
                      </div>
                      <span class="subtitle">
                        Basta nos informar os últimos 4 digitos do seu celular
                        que enviaremos um código para você criar uma nova senha.
                      </span>

                      <div class="last-digits" formGroupName="cellphone">
                        <input
                          title="firstDigit"
                          formControlName="firstDigit"
                          [ngClass]="{
                            digit: !invalidCell,
                            'invalid-digit': invalidCell
                          }"
                          type="text"
                          maxlength="1"
                          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                        <input
                          title="secondDigit"
                          formControlName="secondDigit"
                          [ngClass]="{
                            digit: !invalidCell,
                            'invalid-digit': invalidCell
                          }"
                          type="text"
                          maxlength="1"
                          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                        <input
                          title="thirdDigit"
                          formControlName="thirdDigit"
                          [ngClass]="{
                            digit: !invalidCell,
                            'invalid-digit': invalidCell
                          }"
                          type="text"
                          maxlength="1"
                          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                        <input
                          title="fourthDigit"
                          formControlName="fourthDigit"
                          [ngClass]="{
                            digit: !invalidCell,
                            'invalid-digit': invalidCell
                          }"
                          type="text"
                          maxlength="1"
                          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                      </div>

                      <div *ngIf="invalidCell" class="error-message">
                        <img
                          src="./assets/general/to-na-wiz/auth/warning.svg"
                          alt=""
                        />
                        <span> Número de celular inválido </span>
                      </div>

                      <button
                        type="submit"
                        id="login"
                        (click)="confirmaEmailEnvioCod()"
                        [disabled]="!canSubmitCell()"
                      >
                        <span *ngIf="!loadingCodCelular">enviar código</span>
                        <span *ngIf="loadingCodCelular">
                          <em class="fas fa-circle-notch fa-spin mr-2"></em>
                          Enviando
                        </span>
                      </button>
                    </div>

                    <!-- ####### Loading quando vai criar conta no AD ######### -->
                    <div *ngIf="loadingCriandoContaAD" class="mt-5 text-center">
                      <div class="col-12">
                        <h3>Aguarde! Estamos preparando tudo para você!</h3>
                      </div>
                      <!-- <img
                        class="img-fluid loadingIzzy"
                        src="../../../assets/multitenant/loading.svg"
                        alt="Gif loading"
                      /> -->
                      <p><label for="">Carregando...</label></p>
                      <div class="spinner-grow" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>

                    <!-- ####### INPUTS PARA CRIAR NOVA SENHA ######### -->
                    <div *ngIf="showInputNovaSenha">
                      <div class="senha">
                        <h4>
                          Já criamos seu email corporativo! Agora falta só a sua
                          senha
                        </h4>
                        <p>
                          Essa senha será usada para acessar seu email, a
                          Intranet e a plataforma Tô na Wiz!
                        </p>
                      </div>

                      <div class="form-content">
                        <div class="password-field">
                          <mat-form-field
                            style="width: 100%"
                            appearance="outline"
                          >
                            <mat-label>Crie sua senha</mat-label>
                            <input
                              title="password"
                              [type]="hide ? 'password' : 'text'"
                              matInput
                              formControlName="senha"
                              (keyup)="testaSenha($any($event.target).value)"
                              autocomplete="off"
                            />
                            <link
                              href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
                              rel="stylesheet"
                            />
                            <mat-icon
                              matSuffix
                              fontSet="material-icons-outlined"
                              (click)="hide = !hide"
                              >{{ hide ? 'visibility_off' : 'visibility' }}
                            </mat-icon>
                          </mat-form-field>
                        </div>
                        <div class="password-requisites">
                          <div class="first-col">
                            <ul>
                              <li>
                                <span
                                  *ngIf="
                                    !requisitePassword.hasMinCarac;
                                    else checked
                                  "
                                >
                                  <img
                                    src="./assets/general/to-na-wiz/auth/unchecked.svg"
                                    alt=""
                                  />
                                </span>
                                <span> Mínimo de 12 caracteres </span>
                              </li>
                              <li>
                                <span
                                  *ngIf="
                                    !requisitePassword.hasLowerCase;
                                    else checked
                                  "
                                >
                                  <img
                                    src="./assets/general/to-na-wiz/auth/unchecked.svg"
                                    alt=""
                                  />
                                </span>
                                <span> 1 caractere minúsculo </span>
                              </li>

                              <li>
                                <span
                                  *ngIf="
                                    !requisitePassword.noContainsUsername;
                                    else checked
                                  "
                                >
                                  <img
                                    src="./assets/general/to-na-wiz/auth/unchecked.svg"
                                    alt=""
                                  />
                                </span>
                                <span>
                                  Não conter partes do seu nome:
                                  {{ displayName }}
                                </span>
                              </li>
                            </ul>
                            <ng-template #checked>
                              <img
                                src="../../../assets/general/to-na-wiz/auth/checked.svg"
                                alt=""
                              />
                            </ng-template>
                          </div>
                          <div class="sec-col">
                            <ul>
                              <li>
                                <span
                                  *ngIf="
                                    !requisitePassword.hasUpperCase;
                                    else checked
                                  "
                                >
                                  <img
                                    src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                                    alt=""
                                  />
                                </span>
                                <span> 1 caractere maiúsculo </span>
                              </li>
                              <li>
                                <span
                                  *ngIf="
                                    !requisitePassword.hasNumeric;
                                    else checked
                                  "
                                >
                                  <img
                                    src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                                    alt=""
                                  />
                                </span>
                                <span> 1 número </span>
                              </li>
                              <li>
                                <span
                                  *ngIf="
                                    !requisitePassword.passwordCoincides;
                                    else checked
                                  "
                                >
                                  <img
                                    src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                                    alt=""
                                  />
                                </span>
                                <span> Senhas devem coincidir </span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="password-field">
                          <mat-form-field
                            style="width: 100%"
                            appearance="outline"
                          >
                            <mat-label>Confirme sua senha</mat-label>
                            <input
                              title="test-password"
                              [type]="hideConfirmation ? 'password' : 'text'"
                              matInput
                              formControlName="confirmaSenha"
                              (keyup)="testPasswordEquality()"
                              autocomplete="off"
                            />
                            <link
                              href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
                              rel="stylesheet"
                            />
                            <mat-icon
                              matSuffix
                              fontSet="material-icons-outlined"
                              (click)="hideConfirmation = !hideConfirmation"
                              >{{
                                hideConfirmation
                                  ? 'visibility'
                                  : 'visibility_off'
                              }}</mat-icon
                            >
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="redefine-button">
                        <button
                          type="submit"
                          (click)="createNewPassword()"
                          [disabled]="!passwordCheck()"
                        >
                          DEFINIR SENHA
                        </button>
                      </div>
                    </div>

                    <!-- ############ INPUT REFERENTE A TELEFONE ############## -->
                    <div class="cellphone-input" *ngIf="showInputCelular">
                      <h4 class="title-message">Estamos quase terminando!</h4>
                      <p class="explanation">
                        Agora só precisamos do seu número de celular. Se
                        esquecer sua senha, o código para redefini-la será
                        enviado por SMS para o número que você cadastrar aqui.
                      </p>
                      <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>Número de celular</mat-label>
                        <input
                          title="cel"
                          matInput
                          type="celular"
                          maxlength="14"
                          mask="(00) 000000000"
                          name="celular"
                          formControlName="celular"
                        />
                      </mat-form-field>

                      <button
                        type="submit"
                        id="login"
                        (click)="confirmCellphone()"
                      >
                        <span>Finalizar</span>
                      </button>
                    </div>

                    <!-- ############# INPUTS REFERENTES A ESCOLHA DE EMAILS CORPORATIVOS ################-->
                    <div *ngIf="showSocialName" class="social-name">
                      <h4 class="title-message">
                        Vamos criar sua conta corporativa!
                      </h4>
                      <p class="text-message">
                        Primeiro, selecione qual nome você gostaria de usar no
                        seu email:
                      </p>

                      <div class="name-form">
                        <mat-radio-group formControlName="useSocialName">
                          <mat-radio-button [value]="false" [checked]="true">
                            Quero usar meu <strong>nome de registro.</strong>
                          </mat-radio-button>

                          <div
                            class="name-explanation"
                            *ngIf="!loginForm.value.useSocialName"
                          >
                            <div class="disclaimer">
                              O nome civil ou nome de registro é a denominação
                              inscrita no registro civil (Certidão de
                              nascimento, RG e etc).
                            </div>
                          </div>

                          <mat-radio-button [value]="true">
                            Quero usar meu <strong>nome social</strong>.
                          </mat-radio-button>

                          <div
                            class="name-explanation"
                            *ngIf="loginForm.value.useSocialName"
                          >
                            <div class="disclaimer">
                              <span>
                                Nome social se refere à designação pela qual a
                                pessoa travesti, transgênero ou transexual se
                                identifica e é socialmente reconhecida.
                              </span>
                            </div>
                          </div>
                        </mat-radio-group>
                      </div>

                      <button
                        type="submit"
                        class="submit-button"
                        (click)="checkSocialName()"
                        [disabled]="showSocialName === null"
                      >
                        <span>Próximo</span>
                      </button>
                    </div>

                    <div class="social-input" *ngIf="socialNamePage">
                      <div class="title">
                        <span
                          >Legal! Agora precisamos saber qual é seu nome
                          social.</span
                        >
                      </div>

                      <div class="social-name-example">
                        <strong> Lembre-se em não utilizar: </strong>
                        apelidos, sobrenome não registrado e/ou user de redes
                        sociais no campo abaixo.
                      </div>

                      <div class="name-input">
                        <mat-form-field appearance="outline">
                          <mat-label>Nome completo</mat-label>
                          <input
                            matInput
                            title="apelido"
                            formControlName="apelido"
                          />
                        </mat-form-field>
                      </div>

                      <div class="warning">
                        <img
                          src="../../../assets/general/to-na-wiz/auth/yellow-warning.svg"
                          alt=""
                        />
                        <span
                          ><strong>Atenção!</strong> O seu nome social só
                          permite a alteração do primeiro nome ou nome composto,
                          ok? Então certifique-se de escrever seu sobrenome
                          igual está nos seus documentos :)</span
                        >
                      </div>

                      <mat-checkbox (change)="confirmSocialName($event)">
                        Declaro me identificar como uma pessoa travesti e
                        transexual / transgênero ao fazer uso do nome social.
                      </mat-checkbox>

                      <button
                        type="submit"
                        class="submit-button"
                        (click)="changeName()"
                        [disabled]="!checkApelido()"
                      >
                        <span>Próximo</span>
                      </button>
                    </div>

                    <div
                      class="corporate-email"
                      *ngIf="showCreateEmailCorporativo"
                    >
                      <label class="title" for="email"
                        >Uhul, é hora de escolher seu email!
                      </label>

                      <div class="explanation-text">
                        Com base no seu nome, trouxemos algumas opções de email
                        para você, pode escolher o que preferir! Vamos lá?
                      </div>

                      <div *ngIf="loadingEmailRecomendado">
                        <img
                          class="img-fluid"
                          src="/assets/general/to-na-wiz/login/img/icons/loading-input.png"
                          alt=""
                        />
                      </div>

                      <span
                        *ngIf="loadingEmailRecomendado"
                        class="icon-spinner ml-2"
                      >
                        <em class="fas fa-circle-notch fa-spin mr-2"></em>
                      </span>

                      <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>Escolha seu email!</mat-label>
                        <mat-select
                          *ngIf="!loadingEmailRecomendado"
                          formControlName="emailCorporativo"
                        >
                          <mat-option
                            *ngFor="let item of emailRecomendado"
                            [value]="item"
                          >
                            {{ item.email }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <button
                        type="submit"
                        class="submit-button"
                        (click)="preEmailSelected()"
                        [disabled]="
                          loginForm.value.emailCorporativo === 'defaultEmail'
                        "
                      >
                        <span>Próximo</span>
                      </button>
                    </div>

                    <!-- ###### MENSAGEM QUANDO NAO HA EMAIL DISPONIVEL ###### -->
                    <div
                      *ngIf="showContatoGestor"
                      class="row d-flex justify-content-center"
                    >
                      <div class="col-8 d-flex align-items-center">
                        <h3>
                          Ops! algo deu errado na criação da sua conta
                          corporativa.
                        </h3>
                      </div>
                      <div class="col-4">
                        <img
                          class="img-fluid"
                          width="70vw"
                          src="/assets/general/to-na-wiz/login/img/icons/izzy ops.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <div
                      *ngIf="mensagemErroEmail"
                      class="col-12 alert alert-warning alert-dismissible fade show mt-4 info-gestor"
                    >
                      <strong>Não foi possível gerar email corporativo.</strong>
                      {{ mensagemErroEmail }}
                      <button type="button" class="close" data-dismiss="alert">
                        &times;
                      </button>
                    </div>

                    <!-- ###### MENSAGEM QUANDO JA TEM EMAIL COPORATIVO ###### -->
                    <div
                      *ngIf="
                        temEmailCorporativo === true &&
                        showEmailCorporativo === true
                      "
                      class=""
                    >
                      <p>
                        Parece que você já tem email corporativo, então
                        pularemos essa etapa! Aperte em continuar.
                      </p>

                      <span>E-mail cadastrado:</span>
                      <input
                        title="email"
                        type="text"
                        class="form-control inputForm"
                        value="{{ objToNaWiz.emailCorporativo }}"
                        disabled
                      />

                      <button
                        *ngIf="temEmailCorporativo"
                        type="submit"
                        class="btn btnNext mt-4"
                        (click)="escolherSenha()"
                      >
                        <span>Continuar</span>
                      </button>
                    </div>

                    <!-- ############ INPUTS REFERENTE A CONFIRMACAO DE EMAIL SELECIONADO  #############-->
                    <div *ngIf="showConfirmationEmailSelected">
                      <div class="info-email">
                        <h4 class="title">Tudo certo?</h4>
                        <p>
                          Você escolheu seu email de preferência. Confirma aqui
                          se esta é a opção correta?
                        </p>
                        <p>
                          Lembre-se que esse será seu email corporativo a partir
                          de agora e que aqui na Wiz ele é seu passaporte pra
                          várias experiências.
                        </p>

                        <div class="selected-email">
                          <span class="title">Email escolhido</span>
                          <span class="email">
                            {{ loginForm.value.emailCorporativo.email }}
                          </span>
                        </div>
                        <button
                          type="submit"
                          id="login"
                          (click)="confirmEmailSelected()"
                        >
                          <span>Confirmar escolha</span>
                        </button>
                      </div>
                    </div>

                    <div class="altered-pass" *ngIf="registrationSuccess">
                      <!-- <div class="icon">
                                      <img class="img-fluid" width="150vw"
                                          src="/assets/general/to-na-wiz/login/img/icons/izzy-sucesso.svg" alt="" />
                                  </div> -->

                      <h3 class="title-message">
                        Uhul, cadastro completo! Estamos muito felizes de te ver
                        por aqui :)
                      </h3>

                      <h4 class="text-message">
                        Agora você já pode usar seu CPF e senha para entrar na
                        plataforma Tô na Wiz e começar a se ambientar na sua
                        nova empresa!
                      </h4>

                      <div class="back-button">
                        <button type="submit" (click)="voltarInserirSenha()">
                          <span>Entrar no Onboarding</span>
                        </button>
                      </div>
                    </div>

                    <!-- ########### AVISO DE ERRO QUANDO A API NAO RESPONDE COMO ESPERADO ############# -->
                    <div
                      *ngIf="isReqError"
                      class="row d-flex justify-content-center"
                    >
                      <!-- <div class="col-8 d-flex align-items-center">
                                      <h3>Ops algo deu errado!</h3>
                                  </div> -->
                      <div class="col-4">
                        <!-- <img class="img-fluid" width="70vw"
                                          src="/assets/general/to-na-wiz/login/img/icons/izzy ops.png" alt="" /> -->
                      </div>
                      <div
                        class="col-12 alert alert-info alert-dismissible fade show mt-4"
                      >
                        Infelizmente tivemos um erro para criar e salvar sua
                        conta em nosso sistema, procure o time do COP que está
                        fazendo a sua admissão.
                        <!-- <button type="button" class="close" data-dismiss="alert">
                                          &times;
                                      </button> -->
                      </div>
                    </div>
                    <!-- ########### AVISO DE ERRO QUANDO A API NAO RESPONDE COMO ESPERADO ############# -->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="call-user min-h-screen"
      style="
        background-image: url(./assets/image-login-default.JPG);
      "
    ></div>
  </div>
  <div class="footer">
    <p>powered by</p>
    <img width="100" src="https://catalogoprdstg.blob.core.windows.net/tonawiz/Wizco.svg" alt="logo wizco" />
  </div>
</section>

<!-- LOGIN TO NA WIZ  -->

<!-- <div class="content-all" [ngClass]="{ 'blue-bg centralize-horizontally': registrationSuccess }">

</div> -->
