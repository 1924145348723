import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { defineCustomElements as wizPrivacy } from '@wizsolucoes/wiz-privacy/loader';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

wizPrivacy();
