import { Injectable } from '@angular/core';

declare let gtag: (
  arg0: string,
  arg1: string,
  arg2: { event_category: string; event_label: string; value: number }
) => void;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  public eventEmitter(
    eventAction: string,
    eventCategory: string,
    eventLabel = '',
    eventValue = 0
  ) {
    gtag('event', eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
    });
  }
}
