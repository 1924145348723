import { Component } from '@angular/core';

@Component({
  selector: 'app-page-admin-buttons',
  templateUrl: './page-admin-buttons.component.html',
  styleUrls: ['./page-admin-buttons.component.scss']
})
export class PageAdminButtonsComponent {

}
