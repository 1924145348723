export default {
  // "brandName": "Default",
  // "theme": {
  //   "main-01": "#0038A8",
  //   "main-02": "#F9DD16",
  //   "primary-extra-dark": "#001745",
  //   "primary-darkest": "#002673",
  //   "primary-dark": "#0035A0",
  //   "primary-medium": "#004CE5",
  //   "primary-light": "#3A7CFF",
  //   "primary-lightest": "#8FB4FF",
  //   "primary-extra-light": "#E3ECFF",
  //   "secondary-extra-dark": "#332D01",
  //   "secondary-darkest": "#554B02",
  //   "secondary-dark": "#766803",
  //   "secondary-medium": "#A99504",
  //   "secondary-light": "#EBCF06",
  //   "secondary-lightest": "#FBE85A",
  //   "secondary-extra-light": "#FDF6BD",
  //   "font-default": "'Figtree', sans-serif"
  // },
  // "url": "/promotiva",
  // "logo": "https://www.redepromotiva.com.br/web/site/assets/img/logo-header.png",
  // "favicon": "https://www.redepromotiva.com.br/web/site/assets/img/logo-header.png"
};
