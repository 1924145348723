import { Injectable } from '@angular/core';
import { AppConfiguration } from '../configuration/configuration';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  setChangePrimaryButton(
    document: Document,
    propertyMap: AppConfiguration
  ): void {
    const style = document.createElement('style');
    style.innerHTML = `
      .group-steps .step.current span {
        color:${propertyMap['primary-color-400']}!important;
      }
    `;
    document.head.appendChild(style);
  }
  setCSSVariables(document: Document, propertyMap: AppConfiguration): void {
    document.body.style.setProperty(
      `--primary-darkest`,
      propertyMap['primaryDarkest']
    );
    document.body.style.setProperty(
      `--primary-dark`,
      propertyMap['primaryDark']
    );
    document.body.style.setProperty(
      `--primary-medium`,
      propertyMap['primaryMedium']
    );
    document.body.style.setProperty(
      `--primary-light`,
      propertyMap['primaryLight']
    );
    document.body.style.setProperty(
      `--primary-lightest`,
      propertyMap['primaryLightest']
    );
    document.body.style.setProperty(
      `--primary-extra-light`,
      propertyMap['primaryExtraLight']
    );
    document.body.style.setProperty(
      `--secondary-extra-dark`,
      propertyMap['secondaryExtraDark']
    );
    document.body.style.setProperty(
      `--secondary-darkest`,
      propertyMap['secondaryDarkest']
    );
    document.body.style.setProperty(
      `--secondary-dark`,
      propertyMap['secondaryDark']
    );
    document.body.style.setProperty(
      `--secondary-medium`,
      propertyMap['secondaryMedium']
    );
    document.body.style.setProperty(
      `--secondary-light`,
      propertyMap['secondaryLight']
    );
    document.body.style.setProperty(
      `--secondary-lightest`,
      propertyMap['secondaryLightest']
    );
    // You can use this loop if the API retuns CSS properites in snake case (Eg. primary-color)
    Object.keys(propertyMap).forEach((key) => {
      document.body.style.setProperty(`--${key}`, propertyMap[key]);
    });
  }
}
