import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxWizSSOModule } from '@wizsolucoes/ngx-wiz-sso';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
//import { NgxMaskModule } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageAuthenticationComponent } from './pages/page-authentication/page-authentication.component';
import { PageOnboardingComponent } from './pages/page-onboarding/page-onboarding.component';
import { PagetourComponent } from './pages/page-tour/page-tour.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgxHotjarModule } from 'ngx-hotjar';
import { CaseTimelineComponent } from './components/admin/case-timeline/case-timeline.component';
import { HeaderComponent } from './components/admin/header/header.component';
import { PreviousRouteComponent } from './components/admin/previous-route/previous-route.component';
import { ChatBotComponent } from './components/chat-bot/chat-bot.component';
import { PageAdminEditColabComponent } from './pages/page-admin-edit-colab/page-admin-edit-colab.component';
import { PageAdminHomeComponent } from './pages/page-admin-home/page-admin-home.component';
import { PageAdminNewColabComponent } from './pages/page-admin-new-colab/page-admin-new-colab.component';
import { PageAdminNewOperadorComponent } from './pages/page-admin-new-operador/page-admin-new-operador.component';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { ButtonComponent } from './shared/button/button.component';

import { MatDividerModule } from '@angular/material/divider';
import { ClipboardModule } from 'ngx-clipboard';

import {
  BotDirective,
  BotService,
  ComService,
  NgxMicrosoftBotFrameworkModule,
  StyleSetDirective,
} from 'ngx-microsoft-bot-framework';
import { PopUpComponent } from './components/pesquisa-ex/pop-up/pop-up.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';

import { CommonModule, DatePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgApplicationInsightsModule } from '@wizsolucoes/ng-application-insights';
import { AdmissionStepsRedesignComponent } from './components/onboarding-redesign/admission-steps-redesign/admission-steps-redesign.component';
import { MobileAdmissionStepsComponent } from './components/onboarding-redesign/mobile-admission-steps/mobile-admission-steps.component';
import { ProfileRedesignComponent } from './components/onboarding-redesign/profile-redesign/profile-redesign.component';
import { SideNavComponent } from './components/onboarding-redesign/step-explanation/side-nav/side-nav.component';
import { StepExplanationComponent } from './components/onboarding-redesign/step-explanation/step-explanation.component';
import { DefaultButtonPulseExComponent } from './components/pesquisa-ex/default-button-pulse-ex/default-button-pulse-ex.component';
import { RangeInputEmojiComponent } from './components/pesquisa-ex/range-input-emoji/range-input-emoji.component';
import { TenantInterceptor } from './core/interceptor/tenant.interceptor';
import { LandingPagePesquisaExV2Component } from './pages/landing-page-pesquisa-ex-v2/landing-page-pesquisa-ex-v2.component';
import { CodeInputComponent } from './pages/new-password/components/code-input/code-input.component';
import { InitializeFrontService } from './services/initialize-front.service';
import { ConcluidoButtonComponent } from './shared/concluido-button/concluido-button.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageAdminButtonsComponent } from './pages/page-admin-buttons/page-admin-buttons.component';

//export let options: Partial<null | IConfig> | (() => Partial<IConfig>) = {};

/*export function initializeApp(
  mfService: InitializeFrontService
): () => Promise<void> {
  return () => mfService.initialise();
}*/

@NgModule({
  declarations: [
    AppComponent,
    PageOnboardingComponent,
    PageAuthenticationComponent,
    PagetourComponent,
    HeaderComponent,
    CaseTimelineComponent,
    PageAdminNewColabComponent,
    PageAdminHomeComponent,
    PageAdminEditColabComponent,
    PageAdminNewOperadorComponent,
    ButtonComponent,
    PreviousRouteComponent,
    ChatBotComponent,
    NewPasswordComponent,
    PopUpComponent,
    CodeInputComponent,
    ProfileRedesignComponent,
    AdmissionStepsRedesignComponent,
    StepExplanationComponent,
    SideNavComponent,
    MobileAdmissionStepsComponent,
    RangeInputEmojiComponent,
    DefaultButtonPulseExComponent,
    LandingPagePesquisaExV2Component,
    ConcluidoButtonComponent,
    PageLoginComponent,
    PageAdminButtonsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxWizSSOModule.forRoot(environment.ssoConfig),
    FormsModule,
    ReactiveFormsModule,
    NgxHotjarModule.forRoot(environment.hotjar),
    InfiniteScrollModule,
    NgxMicrosoftBotFrameworkModule,
    NgApplicationInsightsModule.forRoot({
      enabled: environment.production,
      instrumentationKey: 'cb461bc7-f80e-4b02-a16a-76b501f92c12',
    }),
    BrowserAnimationsModule,
    ClipboardModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatDividerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    YouTubePlayerModule,
    CommonModule,
    //NgxMaskModule,
  ],
  exports: [
    AppComponent,
    PageOnboardingComponent,
    PageAuthenticationComponent,
    PagetourComponent,
    HeaderComponent,
    CaseTimelineComponent,
    PageAdminNewColabComponent,
    PageAdminHomeComponent,
    PageAdminEditColabComponent,
    PageAdminNewOperadorComponent,
    ButtonComponent,
    PreviousRouteComponent,
    ChatBotComponent,
    NewPasswordComponent,
    PopUpComponent,
    CodeInputComponent,
    ProfileRedesignComponent,
    AdmissionStepsRedesignComponent,
    StepExplanationComponent,
    SideNavComponent,
    MobileAdmissionStepsComponent,
    RangeInputEmojiComponent,
    DefaultButtonPulseExComponent,
    LandingPagePesquisaExV2Component,
  ],
  providers: [
    GoogleAnalyticsService,
    ComService,
    BotDirective,
    StyleSetDirective,
    BotService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    }/*,
    InitializeFrontService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [InitializeFrontService],
    },*/
    // { provide: ErrorHandler, useClass: NgApplicationInsightsErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
