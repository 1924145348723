import { Component } from '@angular/core';

@Component({
  selector: 'app-page-tour',
  templateUrl: './page-tour.component.html',
  styleUrls: ['./page-tour.component.scss'],
})
export class PagetourComponent {
  constructor() {}

  public informacoes = [
    {
      title: `Conheça o Corporativo`,
      description: `Essa é a área que cuida do suporte ao vendedores de seguros e
    produtos financeiros.`,
      url: `7yVWzLzvaeg`,
      img: `../../../assets/general/to-na-wiz/tuor/wiz.png`,
    },
    // {
    //   title: `Conheça a Rede`, description: `Essa é a área que cuida do suporte ao vendedores de seguros e produtos
    //  financeiros.`, url: `q57vg9Ze32I`, img: `../../../assets/general/to-na-wiz/tuor/wiz.png`
    // },
    {
      title: `Conheça a área do Corporate`,
      description: `Essa é a diretoria que cuida da Wiz como um todo.`,
      url: `zZzJAiMoRgk`,
      img: `../../../assets/general/to-na-wiz/tuor/wiz-coorporate.png`,
    },
    {
      title: `Conheça a Wiz Parceiros`,
      description: `Essa é a área que cuida do suporte e do desenvolvimento da
     plataforma Parceiros.`,
      url: `8JDERJ7sGws`,
      img: `../../../assets/general/to-na-wiz/tuor/wiz-parceiros.png`,
    },
    // {
    //   title: `Conheça a B2U`, description: `Essa é principal distribuidora de seguros do conglomerado Wiz.`,
    //   url: `axywVm6w854`, img: `../../../assets/general/to-na-wiz/tuor/wiz-b2u.png`
    // },
    {
      title: `Conheça a Wiz Concept`,
      description: `Essa é a responsável por cuidar de todo o pós venda dos
    segudos e serviços financeiros, anteriormente era a BPO.`,
      url: `MnyddTD8l8I`,
      img: `../../../assets/general/to-na-wiz/tuor/wiz-bpo.png`,
    },
  ];

  public informacoesSemVideo = [
    {
      title: `Conheça a Wiz Benefícios`,
      description: `Essa ajuda na comercialização de planos de saúde e odontológico para pessoas
      físicas e jurídicas de pequeno e médio porte, coletivo por adesão e grandes contas.`,
      img: `./assets/general/to-na-wiz/tuor/wiz-saude.png`,
      imgExample: `./assets/general/to-na-wiz/tuor/wiz-saude-img.png`,
    },
    {
      title: `Conheça o Conseg`,
      description: `Essa atua na distribuição de seguros e serviços financeiros em redes de concessionárias. `,
      img: `./assets/general/to-na-wiz/tuor/wiz-conseg.png`,
      imgExample: `./assets/general/to-na-wiz/tuor/wiz-conseg-img.png`,
    },
    // {
    //   title: `Conheça a Gr1d`, description: `A GR1D é uma frente do negócio Wiz que tem o objetivo ser uma grande facilitadora para
    //   empresas, usando tecnologia e inovação. A plataforma de negócios GR1D é um marketplace de APIs que conecta diversos ecossistemas,
    //   permitindo de forma eficiente e colaborativa o desenvolvimento de inúmeras soluções.
    //   `,
    //   img: `./assets/general/to-na-wiz/tuor/wiz-gr1d.png` , imgExample: `./assets/general/to-na-wiz/tuor/wiz-gr1d-img.png`
    // },
  ];
}
