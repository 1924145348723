<section class="progress-content">
  <div class="overall-progress">
    <h3 [ngClass]="{ 'center-title': mobileVersion }">Seu progresso</h3>
    <div class="progress-display">
      <div class="pbar"></div>
      <div
        class="comp-pbar"
        [ngStyle]="{
          'width.%': mobileVersion
            ? completePercentage
            : completePercentage * 0.9
        }"
      ></div>
      <span class="complete-percentage">
        <strong>{{ completePercentage }}% </strong>
        concluído
      </span>
    </div>
  </div>
  <div class="all-steps" *ngIf="completedSteps">
    <div class="three-step">
      <div
        class="connecting-bar-1"
        [ngClass]="{
          'completed-progress':
            completedSteps[0].completed && completedSteps[1].completed
        }"
      ></div>
      <!-- <div class="completed-bar-1" *ngIf="completedSteps[0].completed"></div> -->

      <div
        (click)="sendStep(1)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[1]?.completed }"
      >
        <div class="step-header">
          <div class="emojis">
            <img
              *ngIf="completedSteps[1].completed"
              class="check"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
              alt="feito"
            />
            <img
            *ngIf="!completedSteps[1].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-documentos.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[1].completed"></app-concluido-button>
        </div>
        <h3>Entregue seus documentos</h3>
      </div>

      <div
        class="connecting-bar-2"
        [ngClass]="{
          'completed-progress':
            completedSteps[2].completed && completedSteps[1].completed
        }"
      ></div>
      <div class="completed-bar-2" *ngIf="completedSteps[1].completed"></div>

      <div
        (click)="sendStep(2)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[2].completed }"
      >
        <div class="step-header">
          <div class="emojis">
            <img
              *ngIf="completedSteps[2].completed"
              class="check"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
              alt="feito"
            />
            <img
              *ngIf="!completedSteps[2].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-beneficios.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[2].completed"></app-concluido-button>
        </div>
        <h3>Entenda seus benefícios</h3>
      </div>
      <div
        (click)="sendStep(3)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[3].completed }"
      >
        <div class="step-header">
          <div class="emojis">
            <img
              *ngIf="completedSteps[3].completed"
              class="check"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
              alt="feito"
            />
            <img
              *ngIf="!completedSteps[3].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-lupa.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[3].completed"></app-concluido-button>
        </div>
        <h3>Conheça o Grupo Wiz Co</h3>
      </div>
    </div>

    <div
      class="vertical-bar-right-1"
      [ngClass]="{
        'completed-progress':
          completedSteps[2].completed && completedSteps[3].completed
      }"
    ></div>
    <div
      class="comp-vertical-bar-right-1"
      *ngIf="completedSteps[2].completed"
    ></div>

    <div class="three-step">
      <div
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[6].completed }"
      >


        <div class="emojis">
          <img
            *ngIf="completedSteps[6].completed"

            src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
            alt="feito"
          />
          <img
          *ngIf="!completedSteps[6].completed"
            src="./assets/general/to-na-wiz/icons-time-line/icon-celebrate.png"
            alt=""
          />
        </div>

        <h3 class="start-date">
          Você começa no dia {{ objToNaWiz.dataContratacao | date : 'dd/MM' }}
        </h3>
      </div>
      <div
        (click)="sendStep(5)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[5].completed }"
      >
        <div class="step-header">
          <div class="emojis">
            <img
              *ngIf="completedSteps[5].completed"
              class="check"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
              alt="feito"
            />
            <img
            *ngIf="!completedSteps[5].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-time.png"
              alt=""
            />
          </div>
          <app-concluido-button  [completedStep]="completedSteps[5].completed"></app-concluido-button>
        </div>
        <h3>Prepare-se para o 1º dia</h3>
      </div>
      <div
        class="connecting-bar-2"
        [ngClass]="{
          'completed-progress':
            completedSteps[4].completed && completedSteps[3].completed
        }"
      ></div>
      <div class="completed-bar-3" *ngIf="completedSteps[3].completed"></div>

      <div
        (click)="sendStep(4)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[4].completed }"
      >
        <div class="step-header">
          <div class="emojis">
            <img
              *ngIf="completedSteps[4].completed"
              class="check"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
              alt="feito"
            />
            <img
            *ngIf="!completedSteps[4].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-aviao.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[4].completed"></app-concluido-button>
        </div>
        <h3>Descubra nossa cultura</h3>
      </div>

      <div
        class="connecting-bar-1"
        [ngClass]="{
          'completed-progress':
            completedSteps[5].completed && completedSteps[4].completed
        }"
      ></div>
      <div class="completed-bar-4" *ngIf="completedSteps[4].completed"></div>
    </div>

    <div
      class="vertical-bar-left-1"
      [ngClass]="{
        'completed-progress':
          completedSteps[5].completed && completedSteps[6].completed
      }"
    ></div>
    <div
      class="comp-vertical-bar-left-1"
      *ngIf="completedSteps[5].completed"
    ></div>

    <div class="three-step">
      <div
        class="connecting-bar-1"
        [ngClass]="{
          'completed-progress':
            completedSteps[7].completed && completedSteps[6].completed
        }"
      ></div>
      <div class="completed-bar-1" *ngIf="completedSteps[6].completed"></div>
      <div
        (click)="sendStep(7)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[7].completed }"
      >
        <div class="step-header">
          <div class="emojis">
            <img
              *ngIf="completedSteps[7].completed"
              class="check"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
              alt="feito"
            />
            <img
            *ngIf="!completedSteps[7].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-coracao.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[7].completed"></app-concluido-button>
        </div>
        <h3>Mostre seu orgulho em se tornar Wizzer</h3>
      </div>

      <div
        class="connecting-bar-2"
        [ngClass]="{
          'completed-progress':
            completedSteps[8].completed && completedSteps[7].completed
        }"
      ></div>
      <div class="completed-bar-2" *ngIf="completedSteps[7].completed"></div>

      <div
        (click)="sendStep(8)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[8].completed }"
      >

        <div class="step-header">
          <div class="emojis">
            <img
          *ngIf="completedSteps[8].completed"
          class="check"
          src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
          alt="feito"
        />
            <img
            *ngIf="!completedSteps[8].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-livros.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[8].completed"></app-concluido-button>
        </div>
        <h3>Resgate seus mimos</h3>
      </div>

      <div
        (click)="sendStep(9)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[9].completed }"
      >

        <div class="step-header">
          <div class="emojis">
            <img
          *ngIf="completedSteps[9].completed"
          class="check"
          src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
          alt="feito"
        />
            <img
            *ngIf="!completedSteps[9].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-laptop.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[9].completed"></app-concluido-button>
        </div>
        <h3>Conheça nossas ferramentas</h3>
      </div>
    </div>

    <div
      class="vertical-bar-right-2"
      [ngClass]="{
        'completed-progress':
          completedSteps[9].completed && completedSteps[10].completed
      }"
    ></div>
    <div
      class="comp-vertical-bar-right-2"
      *ngIf="completedSteps[8].completed"
    ></div>

    <div class="three-step">
      <div
        class="connecting-bar-2"
        [ngClass]="{
          'completed-progress':
            completedSteps[10].completed && completedSteps[9].completed
        }"
      ></div>
      <div class="completed-bar-3" *ngIf="completedSteps[9].completed"></div>
      <div
        (click)="sendStep(12)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[12].completed }"
      >

        <div class="step-header">
          <div class="emojis">
            <img
          *ngIf="completedSteps[12].completed"
          class="check"
          src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
          alt="feito"
        />
            <img
              *ngIf="!completedSteps[12].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-emoji.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[12].completed"></app-concluido-button>
        </div>
        <h3>Compareça ao Wiz Welcome</h3>
      </div>

      <div
        class="connecting-bar-1"
        [ngClass]="{
          'completed-progress':
            completedSteps[7].completed && completedSteps[6].completed
        }"
      ></div>
      <div class="completed-bar-1" *ngIf="completedSteps[6].completed"></div>

      <div
        (click)="sendStep(11)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[11].completed }"
      >

        <div class="step-header">
          <div class="emojis">
            <img
              *ngIf="completedSteps[11].completed"
              class="check"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
              alt="feito"
            />
            <img
            *ngIf="!completedSteps[11].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-caneta.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[11].completed"></app-concluido-button>
        </div>
        <h3>Assine alguns termos importantes</h3>
      </div>
      <div
        (click)="sendStep(10)"
        class="step"
        [ngClass]="{ 'step-incomplete': !completedSteps[10].completed }"
      >

        <div class="step-header">
          <div class="emojis">
            <img
              *ngIf="completedSteps[10].completed"
              class="check"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
              alt="feito"
            />
            <img
            *ngIf="!completedSteps[10].completed"
              src="./assets/general/to-na-wiz/icons-time-line/icon-mao.png"
              alt=""
            />
          </div>
          <app-concluido-button [completedStep]="completedSteps[10].completed"></app-concluido-button>
        </div>
        <h3>Conheça as nossas ações de diversidade</h3>
      </div>
    </div>

    <div
      class="vertical-bar-left-2"
      [ngClass]="{
        'completed-progress':
          completedSteps[12].completed && completedSteps[11].completed
      }"
    ></div>
    <div
      class="comp-vertical-bar-left-2"
      *ngIf="completedSteps[11].completed"
    ></div>

    <div class="one-step">
      <div
        class="last-step"
        [ngClass]="{ 'step-incomplete': !completedSteps[13].completed }"
      >
        <img
          *ngIf="completedSteps[13].completed"
          class="check"
          src="./assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
          alt="feito"
        />

        <div class="emojis">
          <img
            src="./assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-14.svg"
            alt=""
          />
        </div>

        <h3>Onboarding concluído!</h3>
      </div>
    </div>
  </div>
</section>
