import { PublicAuthService } from 'src/app/services/public-auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ThemingService } from './services/theming/theming.service';
import { ConfigurationService } from './services/configuration/configuration.service';
import hostTenantMap from './services/configuration/host-to-tenant-map';
import { DOCUMENT } from '@angular/common';
import { ConfigurationInMemoryService } from './services/configuration/in-memory/configuration-in-memory.service';
import { AppConfiguration } from './services/configuration/configuration';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoadingConfiguration: boolean | undefined;
  public configuration: AppConfiguration | undefined;

  constructor(
    @Inject(DOCUMENT) private document: Document,

    private readonly router: Router,
    private themingService: ThemingService,
    private configurationService: ConfigurationService,
    private publicAuthService: PublicAuthService,
    private inMemoryService: ConfigurationInMemoryService
  ) {
    this.configureApplication();
    this.eventRoutesGoogleAnalytics();
  }

  ngOnInit(): void {
    this.inMemoryService.configurationCurrent$.subscribe((res) => {
      this.configuration = res;
      this.configureApplication();
    });

    this.checkTenantShortName();
  }

  checkTenantShortName() {
    if (localStorage.getItem('connect-wiz:theme') == '{}') {
      window.localStorage.clear();
    }
  }

  public eventRoutesGoogleAnalytics() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && environment.production) {
        gtag('config', 'UA-93628202-30', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  private configureApplication(): void {
    this.isLoadingConfiguration = true;
    this.publicAuthService.authWithoutLogin().subscribe((res) => {
      let token = res.access_token;
      this.configurationService.getConfig(token).subscribe((res: any) => {
        this.themingService?.setCSSVariables(
          this.document,
          res?.themeAttributes?.theme
        );
        this.document.title = res?.themeAttributes?.brandName !== undefined
            ? `To Na ${res?.themeAttributes?.brandName}`
            : 'Onboarding';
        if (res.specialTheme) {
          this.themingService.setChangePrimaryButton(this.document, res?.theme);
        }
        this.isLoadingConfiguration = false;
      });
    });
  }


  private whoami(): string {
    const url = new URL(window.location.href);
    return hostTenantMap[url.host];
  }
}
