import { Component, OnInit } from '@angular/core';
import { PreviousRouteService } from '../../../services/previous-route.service';

@Component({
  selector: 'app-previous-route',
  templateUrl: './previous-route.component.html',
  styleUrls: ['./previous-route.component.scss'],
})
export class PreviousRouteComponent implements OnInit {
  constructor(private readonly previousRouteService: PreviousRouteService) {}

  previousRoute: any;
  nameToPreviousRoute = '';
  currentRoute: any;
  nameToCurrentRoute = '';

  ngOnInit() {
    this.currentRoute = this.previousRouteService.getCurrentUrl();
    this.setupInitial();
  }

  public setupInitial(): void {
    // Renomeando para rota anterior
    this.nameToPreviousRoute = 'HOME';

    // Renomeando para rota atual
    if (this.currentRoute === '/colaboradores') {
      this.nameToCurrentRoute = 'Lista com todos novos colaboradores';
    } else if (this.currentRoute === '/new-colab') {
      this.nameToCurrentRoute = 'Cadastrar novo colaborador';
    } else if (this.currentRoute.includes('new-operator')) {
      this.nameToCurrentRoute = 'Cadastrar novo administrador';
    } else if (this.currentRoute.includes('edit-colab')) {
      this.nameToCurrentRoute = 'Pagina do colaborador';
    } else {
      this.nameToCurrentRoute = '';
    }
  }
}
