export class RemovePerfilSSO {
  email: string | undefined;
  corpID: string | undefined;
  perfil: string | undefined;

  constructor(email?: string, corpID?: string, perfil?: string) {
    this.email = email;
    this.corpID = corpID;
    this.perfil = perfil;
  }
}
