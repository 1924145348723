import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-range-input-emoji',
  templateUrl: './range-input-emoji.component.html',
  styleUrls: ['./range-input-emoji.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeInputEmojiComponent),
      multi: true,
    },
  ],
})
export class RangeInputEmojiComponent implements ControlValueAccessor {
  @Input() input!: number;
  @Input() title!: string;
  @Input() id!: string;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: number) {
    this.input = input;
  }
}
