import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Paginacao } from '../../../models/paginacao.model';
import { Filter } from '../../../models/filter.model';
import { AdminService } from '../../../services/admin.service';
import { Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ValidateCPFService } from '../../../core/services/validate-cpf.service';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-case-timeline',
  templateUrl: './case-timeline.component.html',
  styleUrls: ['./case-timeline.component.scss'],
})
export class CaseTimelineComponent implements OnInit {
  // TODO: COLOCAR NO LUGAR CORRETO
  public valueDate: any;
  public userToUpdate: any;

  public editForm: UntypedFormGroup;
  public form: UntypedFormGroup;
  public masterSelected: boolean;
  public checklist: any;
  public checkedList: any;
  public messageEditUserSuccess: string;
  public messageEditUserErro: string;
  public body: any;

  // Utilizado para verificar mudança de valor no input do search.
  public inputSearch = new UntypedFormControl();
  public searchForm = this.fb.group({
    inputSearch: this.inputSearch,
  });

  // Guarda os usuarios retornado pela pesquisa
  public objData: any[];

  public loadingUsers: boolean;
  public resultadoVazio: boolean; // variavel p controle de mensagem de quando ha busca tem resultado vazio

  // Variavel que guarda perfil do usuário
  public userProfile = '';

  // objeto de paginacao para o filter
  public objPaginacao: Paginacao = {
    page: 1,
    pageSize: 10,
  };

  // filtro utilizado para pesquisa
  public filterObj: Filter = {
    cpf: '',
    unidadeDeNegocio: '',
    nome: '',
    dataContratacao: '',
    ordenacao: 'contratacao',
    direcao: 'decrescente',
    paginacao: this.objPaginacao,
  };

  public mensagemErroCPF: string;

  public toggleDirecaoFilter: boolean;
  public searchBy = 'nome';
  public valueSearchBy = '';

  // viewchlid utilizado para donwload de arquivo
  @ViewChild('downloadZipLink', { static: false })
  private readonly downloadZipLink: ElementRef;

  constructor(
    private readonly adminService: AdminService,
    private readonly router: Router,
    private readonly fb: UntypedFormBuilder,
    public profile: ProfileService,
    public validateCpf: ValidateCPFService
  ) {}

  public bu: any;

  public businessUnits: any = [
    { value: '', name: 'Todas Unidades' },
    { value: 'beneficios', name: 'Benefícios' },
    { value: 'benup', name: 'Ben.up' },
    { value: 'bpo', name: 'Wiz Concept' },
    { value: 'conseg', name: 'Conseg' },
    { value: 'corporate', name: 'Wiz Corporate Partners' },
    { value: 'corporativo', name: 'Wiz Corporativo' },
    { value: 'parceiros', name: 'Wiz Parceiros' },
  ];

  // metodo para verificar se o usuario chegou no fim da pagina
  public onScroll() {
    this.filterObj.paginacao!.page += 1; // sinaliza proxima pagina no filter
    this.getUsersList(this.filterObj); // busca usuarios de acordo com o filter
  }

  public ngOnInit() {
    this.bu = '';

    // TODO COLOCAR NO LUGAR DPS
    this.editForm = this.fb.group({
      idNewWizer: [''],
      dataIntegracao: [null, ''],
    });
    this.searchUserByName(); // eh inicializado para escutar eventuais mudancas no campo input nome
    this.objData = []; // inicializa array
    this.getUsersList(this.filterObj); // pega os ultimos usuarios cadastrados e apresenta assim que a pagina eh carregada
    this.userProfile = this.profile.checkUserProfile()!;
  }

  public getCompletedSteps(steps: any): string {
    if (steps) {
      let completed = 0;

      steps.forEach((step: any) => {
        if (step.dataConclusao) {
          completed++;
        }
      });

      return `${completed.toString()} / 14`;
    } else {
      return 'Ocorreu um erro inesperado!';
    }
  }

  // metodo para buscar usuarios do onboarding de acordo com o filter especificado
  public getUsersList(objFilter: any) {
    this.loadingUsers = true;
    this.resultadoVazio = false;
    this.adminService.getUsers(objFilter).subscribe((obj) => {
      obj.data.forEach((item) => {
        if (item !== undefined) {
          if (item.EmailCorporativo) {
            item.EmailCorporativo = item.EmailCorporativo.toLocaleLowerCase();
          }
          item.isChecked = false;
          this.objData.push(item);
        }
        //console.log(this.objData);
      });
      if (this.objData.length === 0) {
        this.resultadoVazio = true;
      }
      this.loadingUsers = false;
    });
  }

  // metodo para fazer donwload em formato excel, a partir dos usuarios listados
  public async downloadReport(): Promise<void> {
    const d = new Date();
    const day = d.getUTCDate().toString().padStart(2, '0');
    const month = (d.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear().toString();
    const dateStr = `ConsultaToNaWiz_${day}_${month}_${year}.xlsx`;
    const filter = this.filterObj;
    filter.export = true;
    const blob: Blob = await this.adminService.downloadResource(filter);
    const url = window.URL.createObjectURL(blob);
    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = dateStr;
    link.click();
    window.URL.revokeObjectURL(url);
    filter.export = false;
  }

  // redireciona para pagina de edicao de acordo com o usuario que recebeu o click
  public editUser(user: any): void {
    if (this.userProfile === 'operador') {
      this.router.navigateByUrl('login');
    } else {
      this.router.navigate(['edit-colab', user.cpf.toString()]);
    }
  }

  // metodo para pesqusiar usuario por nome no onboarding
  public searchUserByName() {
    this.inputSearch.valueChanges
      .pipe(
        // utilizado para evitar multiples hits no back. So consulta num intervalo de 1seg que o usuario parou de digitar
        debounceTime(1000),
        switchMap((inputSearch) => {
          this.mensagemErroCPF = '';

          if (inputSearch === ('' || undefined)) {
            // controle para evitar consulta extra na API
            return EMPTY;
          }

          // controle necessario para o filtro nao ir com campos errados
          if (this.searchBy === 'nome') {
            // verifica se pesquisa eh feita por nome
            this.filterObj.nome = inputSearch;
            this.filterObj.cpf = '';
          } else if (this.validateCpf.validate(inputSearch)) {
            // fluxo de pesquisa por cpf e valida cpf antes de enviar requisicao
            inputSearch = inputSearch.split('.').join('');
            inputSearch = inputSearch.split('-').join('');
            this.filterObj.nome = '';
            this.filterObj.cpf = inputSearch;
          } else {
            this.mensagemErroCPF = 'Digite um CPF válido';
            return EMPTY;
          }

          this.filterObj.paginacao!.page = 1; // sempre que pesquisa ele começa novamente da pagina 1
          this.loadingUsers = true;
          this.objData = []; // zerando resultado de busca anteriores
          this.resultadoVazio = false;
          return this.adminService.getUsers(this.filterObj);
        })
      )
      .subscribe((res) => {
        this.objData = [];
        res.data?.forEach((item) => {
          if (item !== undefined) {
            if (item.EmailCorporativo) {
              item.EmailCorporativo = item.EmailCorporativo.toLocaleLowerCase();
            }
            this.objData.push(item);
          }
        });
        if (this.objData.length === 0) {
          this.resultadoVazio = true;
        }

        this.loadingUsers = false;
      });
  }

  // funcao simulando toggle para ordenacao por data de contratacao
  public sortDataContratacaoUsers(orderBy: string) {
    this.toggleDirecaoFilter = !this.toggleDirecaoFilter;

    this.objData = [];
    this.filterObj.paginacao!.page = 1; // sempre que pesquisa ele começa novamente da pagina 1

    // if para simular efeito de toggle na direcao da ordenacao
    if (this.toggleDirecaoFilter) {
      this.filterObj.direcao = 'crescente';
    } else {
      this.filterObj.direcao = 'decrescente';
    }

    this.getUsersList(this.filterObj);
  }

  // funcao que da suporte a busca informando se a busca eh feita por nome ou cpf
  public tradeSearchBy(value: string) {
    this.searchBy = value;
    this.resultadoVazio = false;
    if (value === 'nome') {
      this.mensagemErroCPF = '';
    }
  }

  // metodo para adicionar no filtro de pesquisa, a unidade de negocio que sera feita pesquisa dos colaboradores
  public filterUniNeg(un: string) {
    this.filterObj.unidadeDeNegocio = un;
    // this.buttonSearch(un);
  }

  // botao para efetuar pesquisa dos usuarios do onboarding de acordo com o filter
  public buttonSearch(inputSearch: string) {
    this.objData = [];
    this.filterObj.paginacao!.page = 1; // sempre que pesquisa ele começa novamente da pagina 1

    // controle necessario para o filtro nao ir com campos errados
    if (this.searchBy === 'nome') {
      // verificação necessaria para o filtro nao receber valores erroneos
      this.filterObj.nome = inputSearch;
      this.filterObj.cpf = '';
    } else {
      this.filterObj.cpf = inputSearch;
      this.filterObj.nome = '';
    }
    this.getUsersList(this.filterObj);
  }
}
