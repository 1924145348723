<div
  class="content-all"
  [ngClass]="{ 'blue-bg centralize-horizontally': isSuccessUpdatedPassword }"
>
  <div
    class="login-and-logo"
    [ngClass]="{ 'centralize-vertically': isSuccessUpdatedPassword }"
  >
    <div
      class="login-content"
      [ngClass]="{ 'margin-left mw35': !isSuccessUpdatedPassword }"
    >
      <div class="conteudo-login">
        <div class="password-form">
          <div class="top-section" *ngIf="!isSuccessUpdatedPassword">
            <div class="back-button" [routerLink]="['/login']">
              <img
                src="../../../assets/general/to-na-wiz/auth/back-arrow.svg"
                alt="back arrow"
              />
              <span>Voltar para login</span>
            </div>
            <img
              [src]="configuration?.themeAttributes?.logo"
              alt="wiz logo"
              style="height: 40px;"
            />
          </div>
          <!-- <app-code-input (submitCode)="checkCode($event)" *ngIf="showCodePage"></app-code-input> -->
          <div
            class="password-creation"
            *ngIf="showInputsResetPassword && !isSuccessUpdatedPassword"
          >
            <div class="code-input">
              <span class="title">Digite o código enviado por SMS</span>
              <form [formGroup]="codeForm">
                <div class="last-digits">
                  <input
                    title="input1"
                    formControlName="input1"
                    [ngClass]="{
                      digit: !invalidCode,
                      'invalid-digit': invalidCode
                    }"
                    type="text"
                    maxlength="1"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  />
                  <input
                    title="input2"
                    formControlName="input2"
                    [ngClass]="{
                      digit: !invalidCode,
                      'invalid-digit': invalidCode
                    }"
                    type="text"
                    maxlength="1"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  />
                  <input
                    title="input3"
                    formControlName="input3"
                    [ngClass]="{
                      digit: !invalidCode,
                      'invalid-digit': invalidCode
                    }"
                    type="text"
                    maxlength="1"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  />
                  <input
                    title="input4"
                    formControlName="input4"
                    [ngClass]="{
                      digit: !invalidCode,
                      'invalid-digit': invalidCode
                    }"
                    type="text"
                    maxlength="1"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  />
                  <input
                    title="input5"
                    formControlName="input5"
                    [ngClass]="{
                      digit: !invalidCode,
                      'invalid-digit': invalidCode
                    }"
                    type="text"
                    maxlength="1"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  />
                  <input
                    title="input6"
                    formControlName="input6"
                    [ngClass]="{
                      digit: !invalidCode,
                      'invalid-digit': invalidCode
                    }"
                    type="text"
                    maxlength="1"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  />
                </div>
              </form>
            </div>
            <div class="invalid-code" *ngIf="invalidCode">
              <img
                src="../../../assets/general/to-na-wiz/auth/warning.svg"
                alt=""
              />
              <span>Código inválido</span>
            </div>

            <form [formGroup]="resetPasswordForm">
              <div class="form-group">
                <div class="password-field">
                  <!-- <label class="title" for="senha">Crie uma nova senha</label>
                  <input title="senha" type="password" name="senha" class="form-control inputForm" id="senha"
                    placeholder="Sua nova senha" formControlName="senha" (keyup)="testaSenha($any($event.target).value)"
                    autocomplete="off"> -->
                  <mat-form-field
                    [ngSyle]="{ width: '100%' }"
                    appearance="outline"
                  >
                    <mat-label>Crie uma nova senha</mat-label>
                    <input
                      title="senha"
                      [type]="hide ? 'password' : 'text'"
                      matInput
                      formControlName="senha"
                      (keyup)="testaSenha(resetPasswordForm.value.senha)"
                      autocomplete="off"
                    />
                    <link
                      href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
                      rel="stylesheet"
                    />
                    <mat-icon
                      matSuffix
                      fontSet="material-icons-outlined"
                      (click)="hide = !hide"
                      >{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon
                    >
                  </mat-form-field>
                </div>
                <div class="password-requisites">
                  <div class="first-col">
                    <ul>
                      <li>
                        <span
                          *ngIf="!requisitePassword.hasMinCarac; else checked"
                        >
                          <img
                            src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                            alt=""
                          />
                        </span>
                        <span> Mínimo de 8 caracteres </span>
                      </li>
                      <li>
                        <span
                          *ngIf="!requisitePassword.hasLowerCase; else checked"
                        >
                          <img
                            src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                            alt=""
                          />
                        </span>
                        <span> 1 caractere minúsculo </span>
                      </li>

                      <li>
                        <span
                          *ngIf="
                            !requisitePassword.noContainsUsername;
                            else checked
                          "
                        >
                          <img
                            src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                            alt=""
                          />
                        </span>
                        <span>
                          Não conter partes do seu nome: {{ objToNaWiz?.nome }}
                        </span>
                      </li>
                    </ul>
                    <ng-template #checked>
                      <img
                        src="../../../assets/general/to-na-wiz/auth/checked.svg"
                        alt=""
                      />
                    </ng-template>
                  </div>
                  <div class="sec-col">
                    <ul>
                      <li>
                        <span
                          *ngIf="!requisitePassword.hasUpperCase; else checked"
                        >
                          <img
                            src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                            alt=""
                          />
                        </span>
                        <span> 1 caractere maiúsculo </span>
                      </li>
                      <li>
                        <span
                          *ngIf="!requisitePassword.hasNumeric; else checked"
                        >
                          <img
                            src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                            alt=""
                          />
                        </span>
                        <span> 1 número </span>
                      </li>
                      <li>
                        <span
                          *ngIf="
                            !requisitePassword.passwordCoincides;
                            else checked
                          "
                        >
                          <img
                            src="../../../assets/general/to-na-wiz/auth/unchecked.svg"
                            alt=""
                          />
                        </span>
                        <span> Senhas devem coincidir </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="password-field">
                  <mat-form-field
                    [ngSyle]="{ width: '100%' }"
                    appearance="outline"
                  >
                    <mat-label>Confirme sua senha</mat-label>
                    <input
                      title="confirmaSenha"
                      [type]="hideConf ? 'password' : 'text'"
                      matInput
                      formControlName="confirmaNovaSenha"
                      (keyup)="testPasswordEquality($event)"
                      autocomplete="off"
                    />
                    <link
                      href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
                      rel="stylesheet"
                    />
                    <mat-icon
                      matSuffix
                      fontSet="material-icons-outlined"
                      (click)="hideConf = !hideConf"
                      >{{
                        hideConf ? 'visibility' : 'visibility_off'
                      }}</mat-icon
                    >
                  </mat-form-field>
                </div>

                <div *ngIf="loadingReset" class="form-group mt-3 colorSpinner">
                  <span>
                    <em class="fas fa-circle-notch fa-spin mr-2"></em>
                    Carregando
                  </span>
                </div>

                <div class="redefine-button">
                  <button
                    type="submit"
                    (click)="createNewPassword()"
                    [disabled]="!canResetPassword()"
                  >
                    Definir senha
                  </button>
                </div>
              </div>
            </form>
            <!-- Mensagens de erro  -->
            <div *ngIf="mensagemErro" class="mensagem-error mt-3">
              <img
                src="./assets/general/to-na-wiz/login//img/icons/alert.png"
                width="17"
                class="mr-2"
                alt=""
              />{{ mensagemErro }}
            </div>
          </div>
        </div>

        <div class="altered-pass" *ngIf="isSuccessUpdatedPassword">
          <div class="icon">
            <img
              class="img-fluid"
              width="150vw"
              src="/assets/general/to-na-wiz/login/img/icons/izzy-sucesso.svg"
              alt=""
            />
          </div>

          <h3 class="title-message">Prontinho, senha alterada!</h3>

          <h6 class="text-message">
            Agora você já pode usar seu CPF e senha nova para acessar o Tô na
            Wiz.
          </h6>

          <div class="back-button">
            <button type="submit" [routerLink]="['/login']">
              <span>fazer login no tô na wiz</span>
            </button>
          </div>
        </div>

        <div *ngIf="isReqError" class="row">
          <div class="col-12 d-flex justify-content-center">
            <img
              class="img-fluid"
              width="150vw"
              src="/assets/general/to-na-wiz/login/img/icons/izzy_triste.png"
              alt=""
            />
          </div>

          <div class="col-12 d-flex justify-content-center">
            <h3 class="titleMessage">Ooops! Erro ao tentar trocar a senha</h3>
          </div>

          <div class="col-12 d-flex justify-content-center">
            <h6 class="secondTitleMessage">Por favor tente novamente</h6>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="div-img-animation" *ngIf="!isSuccessUpdatedPassword">
      <div class="row justify-content-center align-self-center">
        <img src="../../../assets/general/to-na-wiz/auth/slider_1.svg" alt="">
      </div>
    </div> -->
  </div>
</div>
