<div [hidden]="hide">
    <div *ngIf="!controlContent" class="pop-up">

        <div class="close-button" (click)="close()">
            <img src="./assets/general/to-na-wiz/pesquisa-ex-v2/close.svg" alt="fechar">
        </div>

        <div class="row-flex">
            <div class="text-question">
                Qual é a sua percepção com o processo de contratação e entrega de documentos?
            </div>
            <div class="icon-izzy">
                <img src="./assets/general/to-na-wiz/pesquisa-ex-v2/izzy-thinking.svg" alt="izzy">
            </div>
        </div>

        <form [formGroup]="formAnswer">
            <app-range-input-emoji ngDefaultControl formControlName="rangeAnswer"></app-range-input-emoji>
        </form>

        <div class="buttons">
            <app-default-button-pulse-ex
                textButton="QUERO FALAR MAIS"
                (callFunction)="redirectToLandingPage()">
            </app-default-button-pulse-ex>

            <app-default-button-pulse-ex
                textButton="ENVIAR RESPOSTA"
                styleButton="outlined"
                [fitWidth]="false"
                (callFunction)="sendAnswer()"
                [isLoading]="loadingSubmitAnswer"
                [disabled]="!formAnswer.valid">
            </app-default-button-pulse-ex>
        </div>
    </div>

    <div *ngIf="controlContent" class="pop-up pop-up-thanks">
        <div class="close-button" (click)="close()">
            <img src="./assets/general/to-na-wiz/pesquisa-ex-v2/close.svg" alt="fechar">
        </div>

        <div class="msg-and-icon">
            <div class="title-and-subtitle">
                <div class="icon-title">
                    <img class="" src="./assets/general/to-na-wiz/pesquisa-ex-v2/check-modal.svg" alt="img check">
                    <span>Obrigado!</span>
                </div>

                <div class="subtitle">
                    Sua resposta foi registrada
                </div>
            </div>

            <img src="./assets/general/to-na-wiz/pesquisa-ex-v2/izzy-happy.svg" alt="izzy">
        </div>

        <app-default-button-pulse-ex
            class="button"
            textButton="QUERO FALAR MAIS"
            (callFunction)="redirectToLandingPage()"
            [fitWidth]="true">
        </app-default-button-pulse-ex>
    </div>

</div>
