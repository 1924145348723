<div class="content-all">
  <div class="logo-title-description">
    <img src="./assets/general/to-na-wiz/pesquisa-ex-v2/logo-wiz.svg" alt="" />
    <h1>Pesquisa de satisfação</h1>
    <p>
      A sua opinião é importante para continuarmos aprimorando nossa jornada
      juntos. Vai ser rápido, tá?
    </p>
  </div>

  <div
    *ngIf="!controlContent"
    class="card custom-scroll"
    [formGroup]="formAnswer"
  >
    <div *ngIf="showInputMotivo" class="section-inputs-motivo">
      <h3>O que motivou sua nota {{ valueInputReceived }}?</h3>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="motivoNota"
        class="radio-buttons-section"
        ngDefaultControl
      >
        <div *ngFor="let item of optionsToShow" class="item-radio">
          <mat-radio-button [value]="item">{{ item }}</mat-radio-button>
        </div>
      </mat-radio-group>
      <div *ngIf="formAnswer.value.motivoNota === 'Outro'" class="input-outro">
        <input
          title="motivoNota"
          class="form-control"
          formControlName="campoAbertoMotivoNota"
          placeholder="Escreva:"
          type="text"
        />
      </div>
    </div>

    <div *ngIf="!showInputMotivo">
      <div>
        <input
          title="motivoNota"
          class="form-control"
          formControlName="campoAbertoMotivoNota"
          placeholder="O que motivou sua nota?"
          type="text"
        />
      </div>
    </div>

    <div *ngIf="msgWarning" class="alert alert-warning" role="alert">
      <div class="d-flex align-items-center" [innerHTML]="msgWarning"></div>
    </div>

    <textarea
      formControlName="sugestao"
      title="sugestao"
      cols="30"
      rows="10"
      placeholder="Você gostaria de fazer uma sugestão, elogio ou crítica? Fique à vontade."
    ></textarea>

    <div class="buttons-section">
      <button
        type="button"
        class="outlined-btn"
        routerLink="/onboarding"
        routerLinkActive="router-link-active"
      >
        <img
          src="./assets/general/to-na-wiz/pesquisa-ex-v2/arrow_back.svg"
          alt="voltar"
        />
        VOLTAR
      </button>

      <button
        type="submit"
        (click)="sendAnswer()"
        [disabled]="!formAnswer.valid"
      >
        <span *ngIf="!loadingSubmitAnswer">ENVIAR</span>
        <mat-spinner
          [diameter]="20"
          [style.color]="'#fff'"
          *ngIf="loadingSubmitAnswer"
        ></mat-spinner>
      </button>
    </div>
  </div>

  <div *ngIf="controlContent" class="card-tks card">
    <img
      class=""
      src="./assets/general/to-na-wiz/pesquisa-ex-v2/check-modal.svg"
      alt="img check"
    />
    <h2>Obrigado!</h2>
    <p>Sua resposta foi registrada</p>

    <div class="buttons-section">
      <button
        type="button"
        routerLink="/onboarding"
        routerLinkActive="router-link-active"
      >
        VOLTAR PARA O TÔ NA WIZ
      </button>
    </div>
  </div>
</div>
