import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainServiceService } from 'src/app/services/main-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PesquisaExService {
  public jornadas = environment.gateway;

  constructor(public mainService: MainServiceService) {}

  public sendAnswer(data: any): Observable<any> {
    return this.mainService.request<any>(
      'post',
      this.jornadas,
      `/pesquisa/adicionar`,
      data
    );
  }

  public getQuestToDoByCpf(cpf: string): Observable<any> {
    const queryString = `?cpf=${cpf}`;
    return this.mainService.request<any>(
      'get',
      this.jornadas,
      `/pesquisa/afazer${queryString}`
    );
  }
}
