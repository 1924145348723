import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
})
export class CodeInputComponent implements OnInit {
  public codeArray = new Array<string>(6);
  public code: string;
  public loadingCode: boolean;
  public canSubmit: boolean;
  @Output() submitCode = new EventEmitter<any>();

  public codeForm = this.fb.group({
    input1: ['', [Validators.required]],
    input2: ['', [Validators.required]],
    input3: ['', [Validators.required]],
    input4: ['', [Validators.required]],
    input5: ['', [Validators.required]],
    input6: ['', [Validators.required]],
  });

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.canSubmit = false;
  }

  public concatenateCode(): void {
    const form = this.codeForm.value;
    this.code =
      form.input1 +
      form.input2 +
      form.input3 +
      form.input4 +
      form.input5 +
      form.input6;
  }

  public confirmaCodigo(): void {
    this.concatenateCode();
    const response = {
      confirmationCode: this.code,
      showCodePage: false,
    };

    this.submitCode.emit(response);
  }

  public canSubmitCode(): boolean {
    const inputAB =
      this.codeForm.controls.input1.valid &&
      this.codeForm.controls.input2.valid;
    const inputCD =
      this.codeForm.controls.input3.valid &&
      this.codeForm.controls.input4.valid;
    const inputEF =
      this.codeForm.controls.input5.valid &&
      this.codeForm.controls.input6.valid;

    this.canSubmit = inputAB && inputCD && inputEF;

    return this.canSubmit;
  }
}
