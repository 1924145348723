import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommomValidatorsService {
  constructor() {}

  verificaNumeroCelular(value: string): boolean {
    const regexNumber = /^((1[1-9])|([2-9]\d))((3\d{3}\d{4})|(9\d{3}\d{5}))$/;
    if (regexNumber.test(value)) {
      return true;
    }
    return false;
  }
}
