import { Injectable } from '@angular/core';
import { MainServiceService } from './main-service.service';
import { Observable } from 'rxjs';
import { ProfileUsersList } from '../models/profile-users-list.model';
import { UsersResult } from '../models/users-result.model';
import { Filter } from '../models/filter.model';
import { UserEditResult } from '../models/user-edit-result.model';
import { User } from '../models/user.model';
import { FuncionarioModel } from '../models/funcionario-model';
import { RemovePerfilSSO } from '../models/remove-perfil-sso';
import { UpdateSSO } from '../models/update-sso-model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Profile } from '../interfaces/profile';
import { Token } from '@wizsolucoes/ngx-wiz-sso';
import { AccessToken } from './public-auth.service';
import { CaseModel } from '../models/case-model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public jornadas = environment.gateway;
  public tnwApi = environment.tnwApi;
  public workflowApi = environment.workflowApi;

  constructor(
    public mainService: MainServiceService,
    public httpClient: HttpClient
  ) {}

  public getProfileUsers(userProfile: string): Observable<ProfileUsersList> {
    return this.mainService.request<ProfileUsersList>(
      'get',
      this.jornadas,
      `api/profile/${userProfile}`
    );
  }

  public setUserInProfile(body: object): Observable<any> {
    return this.mainService.request<ProfileUsersList>(
      'put',
      this.jornadas,
      'api/account/perfil',
      body
    );
  }

  public setUpdateUser(body: object): Observable<any> {
    return this.mainService.request<ProfileUsersList>(
      'put',
      this.jornadas,
      'api/v1/onboarding/UpdateAll',
      body
    );
  }

  public removeUserInProfile(dados: RemovePerfilSSO): Observable<any> {
    const queryString = `?corpID=${dados.corpID}&perfil=${
      dados.perfil
    }&email=${encodeURIComponent(dados.email!)}`;
    return this.mainService.request<ProfileUsersList>(
      'delete',
      this.jornadas,
      `api/account/perfil${queryString}`
    );
  }

  public updateAccountSSO(user: UpdateSSO): Observable<any> {
    return this.mainService.request<ProfileUsersList>(
      'put',
      this.jornadas,
      'api/account',
      user
    );
  }

  public getUsers(filter: Filter): Observable<UsersResult> {
    return this.mainService.request<UsersResult>(
      'post',
      this.tnwApi,
      `api/v1/onboarding/onboardings`,
      filter,
      'private'
    );
  }
  public async downloadResource(filter: Filter): Promise<Blob> {
    return this.mainService.requestFile(
      `api/v1/onboarding/onboardings`,
      filter
    );
  }

  public updateUser(
    idNewWizer: string,
    user: User,
  ): Observable<UserEditResult> {

    return this.mainService.request<UserEditResult>(
      'put',
      this.tnwApi,
      `api/v1/onboarding/${idNewWizer}`,
      user,
      'private'
    );
  }

  public createUser(user: User): Observable<UserEditResult> {
    return this.mainService.request<UserEditResult>(
      'post',
      this.tnwApi,
      `api/v1/onboarding`,
      user,
      'private'
    );
  }

  public getUserByCpf(cpf: string): Observable<User> {
    return this.mainService.request<User>(
      'get',
      this.tnwApi,
      `api/v1/onboarding/cpf/${cpf}`
    );
  }

  public searchUserADByCpf(cpf: string): Observable<User> {
    return this.mainService.request<User>(
      'get',
      this.jornadas,
      `api/acesso/usuario/${cpf}`
    );
  }

  public token(): Observable<any> {
    const ssoConfig = environment.ssoConfig
    let formData = new FormData()
    formData.append('grant_type', 'client_credentials')
    formData.append('client_id', 'tonawiz')
    formData.append('client_secret', ssoConfig.clientSecret)
    formData.append('scope', 'tonawiz idwiz.api_access')

    return this.httpClient.post<AccessToken>( `${environment.sso}/connect/token`, formData)
  }

  public searchFuncAll(
    nome: string,
    token: string
  ): Observable<FuncionarioModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient.get<FuncionarioModel[]>(
      `${this.workflowApi}/api/v1/caso/funcionario/nome/${nome}`,
      httpOptions
    );
  }

  public searchCase(
    caseNumber: string,
    token: string
  ): Observable<CaseModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient.get<CaseModel[]>(
      `${this.tnwApi}/api/v1/onboarding/bonita/case/${caseNumber}`,
      httpOptions
    );
  }



  public getProfileByUserInToken(token: Token, shortName: string): Observable<Profile[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token.hash}`,
        'X-Tenant': shortName,
      }),
    };

    return this.httpClient.get<Profile[]>(
      `${this.tnwApi}/api/v1/usuario/profiles`,
      httpOptions
    );
  }
}
