<app-header></app-header>

<app-previous-route></app-previous-route>

<div class="row content-all">
  <div class="col-12 mt-4 mb-4 shadow bg-white + item">
    <h3>Cadastrar Novo Administrador</h3>
    <form [formGroup]="cadastrarForm">
      <div class="profileList">
        <label> Pesquisar funcionário </label>
        <span *ngIf="loadingEmployee" class="icon-spinner fa-lg ml-3"
          ><em class="fas fa-spinner fa-pulse"></em
        ></span>
        <input
          title="nomeFuncionario"
          name="employee"
          formControlName="employeeName"
          class="form-control form-control"
          type="text"
          autocomplete="off"
          placeholder="Pesquisar Funcionario"
        />
        <div class="result-auto-complete">
          <div class="item-container">
            <a
              *ngFor="let e of conteudoEmployee; let i = index"
              class="item-result"
              (click)="setInformationEmployee(e)"
            >
              <div class="item-list">
                {{ e.nome }}
              </div>
            </a>
          </div>
        </div>
        <label> Selecione perfil </label>
        <select title="profile" formControlName="profile" class="form-control">
          <option *ngFor="let profile of profiles" value="{{ profile }}">
            {{ profile }}
          </option>
        </select>
        <label> Email do funcionário </label>
        <input
          title="emailOp"
          class="profileList form-control"
          type="text"
          #emailOperador
          name="Email"
          placeholder="Digite o e-mail do operador"
          sizeLabel="7"
          formControlName="emailOp"
          [disabled]="isSuccesAddPerfil"
        />
      </div>
    </form>
    <div *ngIf="isSuccesAddPerfil" class="row alert alert-success mr-3">
      Usuário(a) cadastrado(a) com sucesso!
    </div>
    <div *ngIf="mensagemErroCadastro" class="row alert alert-danger mr-3">
      {{ mensagemErroCadastro }}
    </div>

    <div class="row d-flex justify-content-end mr-3 mt-2">
      <button
        type="button"
        class="btn"
        (click)="verificaSSO()"
        [disabled]="cadastrarForm.value.emailOp === ''"
      >
        Atualizar Perfil
      </button>
    </div>
  </div>

  <div class="col-12 mt-3 shadow bg-white + item">
    <h3>Lista de Operadores</h3>
    <div
      *ngIf="mensagemErroRemocao"
      class="row alert alert-danger mr-3 mt-4 mb-4"
    >
      {{ mensagemErroRemocao }}
    </div>
    <div
      *ngIf="mensagemErroLoading"
      class="row alert alert-danger mr-3 mt-4 mb-4"
    >
      {{ mensagemErroLoading }}
    </div>
    <h5 class="title-second">Filtrar tipos de perfis</h5>
    <select
      title="perfis"
      (change)="loadOperadores($any($event.target).value)"
      class="col-4 form-control"
    >
      <option *ngFor="let profile of profiles" value="{{ profile }}">
        {{ profile }}
      </option>
    </select>
    <br />
    <!-- <h5 class="title-second mt-5">Lista de Operadores</h5> -->
    <div *ngIf="loadingOperadores" class="row d-flex justify-content-center">
      <span class="icon-spinner fa-3x"
        ><em class="fas fa-spinner fa-spin"></em
      ></span>
    </div>
    <div *ngIf="!loadingOperadores" class="row d-flex">
      <div class="col-3 info" *ngFor="let c of listOperators; let i = index">
        <span class="mr-2" (click)="removePerfil(c)"
          ><em class="fas fa-user-minus"></em
        ></span>
        {{ c.nomeCompleto }}
      </div>
    </div>
  </div>
</div>
