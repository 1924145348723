<section class="progress-content">

    <div class="overall-progress">
        <h3>Seu progresso</h3>
        <div class="progress-display">
            <div class="pbar">'</div>
            <div class="comp-pbar" [ngStyle]="{'width.%': completePercentage}">'</div>
            <span class="complete-percentage">
                <strong>{{completePercentage}}% </strong>
                concluído
            </span>
        </div>
    </div>

    <div class="all-steps" *ngIf="completedSteps">

        <!-- <div class="step-with-bar">
            <div (click)="sendStep(0)" class="step first-step"
                [ngClass]="{'step-incomplete':!completedSteps[0].completed}">
                <img *ngIf="completedSteps[0].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-1.svg" alt="">
                </div>
                <h3>Sua jornada começa aqui!</h3>
                <p>Você aceitou a proposta e se tornou um Wizzer</p>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[0].completed && completedSteps[1].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[0].completed">-</div>
        </div> -->

        <div class="step-with-bar">

            <div (click)="sendStep(1)" class="step" [ngClass]="{'step-incomplete':!completedSteps[1].completed}">
                <img *ngIf="completedSteps[1].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-2.svg" alt="">
                </div>
                <h3>Entregue seus documentos</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[2].completed && completedSteps[1].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[1].completed">-</div>
        </div>

        <div class="step-with-bar">

            <div (click)="sendStep(2)" class="step" [ngClass]="{'step-incomplete':!completedSteps[2].completed}">
                <img *ngIf="completedSteps[2].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-3.svg" alt="">
                </div>
                <h3>Entenda seus benefícios</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[2].completed && completedSteps[3].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[2].completed">-</div>
        </div>

        <div class="step-with-bar">

            <div (click)="sendStep(3)" class="step" [ngClass]="{'step-incomplete':!completedSteps[3].completed}">
                <img *ngIf="completedSteps[3].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-6.svg" alt="">
                </div>
                <h3>Conheça o grupo Wiz Co</h3>
            </div>


            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[3].completed && completedSteps[2].completed}">
                -
            </div>
            <div class="completed-bar" *ngIf="completedSteps[3].completed">-</div>
        </div>

        <div class="step-with-bar">
            <div (click)="sendStep(4)" class="step" [ngClass]="{'step-incomplete':!completedSteps[4].completed}">
                <img *ngIf="completedSteps[4].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-5.svg" alt="">
                </div>
                <h3>Descubra nossa cultura</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[5].completed && completedSteps[4].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[4].completed">-</div>
        </div>

        <div class="step-with-bar">

            <div (click)="sendStep(5)" class="step" [ngClass]="{'step-incomplete':!completedSteps[5].completed}">
                <img *ngIf="completedSteps[5].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-4.svg" alt="">
                </div>
                <h3>Prepare-se para o 1º dia</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[6].completed && completedSteps[5].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[5].completed">-</div>
        </div>

        <div class="step-with-bar">

            <div class="step" [ngClass]="{'step-incomplete':!completedSteps[6].completed}">
                <img *ngIf="completedSteps[6].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-7.svg" alt="">
                </div>
                <h3 class="start-date">Você começa na Wiz dia {{objToNaWiz.dataContratacao | date:'dd/MM'}}</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[7].completed && completedSteps[6].completed}">
                -</div>
            <div class="completed-bar" *ngIf="completedSteps[6].completed">-</div>
        </div>

        <div class="step-with-bar">
            <div (click)="sendStep(7)" class="step" [ngClass]="{'step-incomplete':!completedSteps[7].completed}">
                <img *ngIf="completedSteps[7].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-8.svg" alt="">
                </div>
                <h3>Mostre seu orgulho em se tornar Wizzer</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[8].completed && completedSteps[7].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[7].completed">-</div>
        </div>

        <div class="step-with-bar">

            <div (click)="sendStep(8)" class="step" [ngClass]="{'step-incomplete':!completedSteps[8].completed}">
                <img *ngIf="completedSteps[8].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-9.svg" alt="">
                </div>
                <h3>Resgate seus mimos</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[9].completed && completedSteps[8].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[8].completed">-</div>
        </div>

        <div class="step-with-bar">

            <div (click)="sendStep(9)" class="step" [ngClass]="{'step-incomplete':!completedSteps[9].completed}">
                <img *ngIf="completedSteps[9].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-12.svg" alt="">
                </div>
                <h3>Conheça nossas ferramentas</h3>
            </div>


            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[10].completed && completedSteps[9].completed}">
            </div>
            <div class="completed-bar" *ngIf="completedSteps[9].completed"></div>
        </div>

        <div class="step-with-bar">

            <div (click)="sendStep(10)" class="step" [ngClass]="{'step-incomplete':!completedSteps[10].completed}">
                <img *ngIf="completedSteps[10].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-11.svg" alt="">
                </div>
                <h3>Nossas ações de diversidade</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[11].completed && completedSteps[10].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[10].completed">-</div>
        </div>

        <div class="step-with-bar">

            <div (click)="sendStep(11)" class="step" [ngClass]="{'step-incomplete':!completedSteps[11].completed}">
                <img *ngIf="completedSteps[11].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-10.svg" alt="">
                </div>
                <h3>Assine alguns termos importantes</h3>
            </div>

            <div class="connecting-bar"
                [ngClass]="{'completed-progress': completedSteps[12].completed && completedSteps[11].completed}">-
            </div>
            <div class="completed-bar" *ngIf="completedSteps[11].completed">-</div>
        </div>

        <div class="step-with-bar">

            <div (click)="sendStep(12)" class="step" [ngClass]="{'step-incomplete':!completedSteps[12].completed}">
                <img *ngIf="completedSteps[12].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito">
                <div class="emojis">
                    <img src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-13.svg" alt="">
                </div>
                <h3>Compareça ao Wiz Welcome</h3>
            </div>

            <div class="connecting-bar" [ngClass]="{'completed-progress': completedSteps[13].completed}">
                -</div>
            <div class="completed-bar" *ngIf="completedSteps[12].completed">-</div>
        </div>

        <div class="step" [ngClass]="{'step-incomplete':!completedSteps[13].completed}">
            <div class="emojis">
                <img *ngIf="completedSteps[13].completed " class="check"
                    src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="feito"> <img
                    src="../../../assets/general/to-na-wiz/to-na-wiz/profile/emojis/emoji-14.svg" alt="">
            </div>
            <h3>Onboarding concluído!</h3>
        </div>
    </div>

</section>
