import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Observer, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

import { catchError } from 'rxjs/operators';
import { SSOConectorService, Token } from '@wizsolucoes/ngx-wiz-sso';

@Injectable({
  providedIn: 'root',
})
export class MainServiceService {
  constructor(public http: HttpClient) {}

  public request<T>(
    method: string,
    relativeApi: string,
    path: string,
    data?: any,
    rtype?: any
  ): Observable<T> {
    method = method.toLowerCase();
    if (
      [
        'get',
        'head',
        'post',
        'put',
        'delete',
        'connect',
        'options',
        'trace',
        'patch',
      ].indexOf(method) === -1
    ) {
      return throwError(`${method} não é permitido!`);
    }
    return this._request(method, relativeApi, path, data, rtype);
  }

  public async requestFile(path: string, data?: any): Promise<any> {
    const objHeaders = {
      'Content-Type': 'application/json',
    };
    const options: any = {
      headers: objHeaders,
      responseType: 'blob' as 'json',
    };
    return this.http
      .post<Blob>(`${environment.gateway}/${path}`, data, options)
      .toPromise();
  }

  public getToken(): string {
    let token: Token;
    let hash = '';
    try {
      // when using sso it uses this lines below
      token = SSOConectorService.isLogged();
      hash = token.hash;
    } catch (error) {
      console.error(error);
    }

    return hash;
  }

  public _request<T>(
    method: string,
    relativeApi: string,
    path: string,
    data?: any,
    type?: any
  ): Observable<T> {
    const options: any = {
      body: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: type === 'private' ? `Bearer ${this.getToken()}` : '',
      },
    };

    // tslint:disable-next-line: deprecation
    return new Observable((observer: Observer<any>) => {
      this.http
        .request<T>(method, `${relativeApi}/${path}`, options)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            observer.error(error ? error.error : '');
            return this.handleError(error);
          })
        )
        .subscribe(
          (obj) => observer.next(obj),
          () => observer.complete()
        );
    });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(`${error.url} -> ${error.status} - ${error.statusText}`);
  }
}
