import { Component, OnInit } from '@angular/core';
import { PublicAuthService } from '../../services/public-auth.service';
import { SSOConectorService } from '@wizsolucoes/ngx-wiz-sso';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { EmailRecomendado } from '../../models/email-recomendado.model';
import {
  UntypedFormBuilder,
  Validators,
  ReactiveFormsModule,
  FormGroup,
} from '@angular/forms';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { UpdatePasswordService } from '../../services/update-password.service';
import { CommomValidatorsService } from '../../core/services/commom-validators.service';
import { ValidateCPFService } from 'src/app/core/services/validate-cpf.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ThemingService } from 'src/app/services/theming/theming.service';
import { AppConfiguration } from 'src/app/services/configuration/configuration';
import { ConfigurationInMemoryService } from 'src/app/services/configuration/in-memory/configuration-in-memory.service';
import { LocalStorageManagerService } from 'src/app/services/local-storage-manager.service';
import { EmployeeData } from 'src/app/models/employee-data.model';
import { TenantService } from 'src/app/services/configuration/tenant/tenant.service';
import { environment } from 'src/environments/environment';
import { Tenant } from 'src/app/interfaces/tenant';

@Component({
  selector: 'app-page-authentication',
  templateUrl: './page-authentication.component.html',
  styleUrls: ['./page-authentication.component.scss'],
  providers: [
    // LocalStorageManagerService,
    { provide: 'scope', useValue: 'auth' },
  ],
})
export class PageAuthenticationComponent implements OnInit {
  // variaveis para controle de loading
  public loadingCpf: boolean;
  public loadingCriandoContaAD: boolean;
  public loadingEmailRecomendado: boolean;
  public loadingButton: boolean;
  public loadingUserToNaWiz: boolean;
  public loadingCodEmail: boolean;
  public loadingCodCelular: boolean;

  // variaveis que fazem o controle dos inputs que devem ser apresentados, de acordo com o cenario de login
  public showInputCPF: boolean; // controla input de cpf
  public showInputSenha: boolean; // controla input de senha
  public showInputNovaSenha: boolean; // controla input de nova senha
  public showInputApelido: boolean; // controla input de como prefere ser chamado
  public showCreateEmailCorporativo: boolean; // controla apresentacao do select box referente a escolha de email corporativo
  public showCreateCelular: boolean; // controla apresentacao do select box referente ao numero de celular
  public showEmailCorporativo: boolean; // controla apresentacao do email corporativo associado ao usuario
  public showCelular: boolean; // controla apresentacao do celular do usuario
  public showConfirmationEmailSelected: boolean; // controla apresentacao de input de email pre selecionado
  public showContatoGestor: boolean; // controla mensagem de contato com gestor, quando nao ha email disponivel
  public isValidPassword: boolean; // controla se a nova senha eh valida
  public isSimpleLogin: boolean; // controla os inputs de login comum
  showLoginButton: boolean;
  public isReqError: boolean; // controle a mensagem de erro quando alguma requisicao esta errada
  public temEmailCorporativo = false; // controle a verificao se usuario tem email disponivel
  public naoTemEmailDisponivel: boolean; // controla apresentacao de erro quando nao ha email disponiveis
  public actualCPF: string; // variavel de controle pra evitar multiples hits no end point, quando fizer login
  public createAccountSSO: boolean; // variavel utlizada para sinalar que sera criada um conta no SSO.
  public showEsqueciSenha: boolean; // variavel de controle para exibição dos campos referentes ao esqueci senha
  public emailCorporativoAD: string; //  variavel que sera utilizada para armazenar email corporativo para envio de criação de conta no AD
  public celularAD: number;
  public nomeAD: string; // variavel que sera utilizada para armazenar nome para envio de criação de conta no AD

  // objetos
  public emailRecomendado: EmailRecomendado[]; // lista com os email sugeridos
  public objToNaWiz: any; // objeto preenchido com informacao do colaborador retornado do banco tonawiz.
  public requisitePassword = {
    // objeto para a validacao de atributos respectivos a requisito de senha
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumeric: false,
    hasMinCarac: false,
    noContainsUsername: true,
    passwordCoincides: false,
  };

  public animationController = false; // variavel para controle de animacao

  public mensagemErro: string; // variavel que guarda mensagem de erro que sera apresentada no template.
  public mensagemErroEmail: string; // variavel utilizada para retorno pro usuario de erro na pesquisa de email

  // formulario utilizado para extracao de dados para efetuar login ou cadastrar usuario
  public loginForm: FormGroup;

  public invalidCell: boolean;
  public showWelcome: boolean;
  public showSocialName: boolean;
  public showInputCelular: boolean;
  public socialNamePage: boolean;
  public displayName: any;
  public registrationSuccess: boolean;
  public showHeader = true;
  public hide = true;
  public hideConfirmation = true;
  public socialNameConfirmed: boolean;
  public tenantLogo: string;
  public configuration: AppConfiguration | undefined;
  private token: string;
  public defaultLogo: string;

  constructor(
    private readonly fb: UntypedFormBuilder,
    public auth: PublicAuthService,
    private readonly sso: SSOConectorService,
    private readonly router: Router,
    public profile: ProfileService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public resetPassword: UpdatePasswordService,
    public commomValidatorService: CommomValidatorsService,
    public validateCPFService: ValidateCPFService,
    private inMemoryService: ConfigurationInMemoryService,
    private readonly route: ActivatedRoute,
    private readonly tenantService: TenantService,
    private localStorageService: LocalStorageManagerService
  ) {
    this.socialNameConfirmed = false;

    this.loginForm = this.fb.group({
      cpf: [''],
      celular: [''],
      cellphone: this.fb.group({
        firstDigit: ['', [Validators.required]],
        secondDigit: ['', [Validators.required]],
        thirdDigit: ['', [Validators.required]],
        fourthDigit: ['', [Validators.required]],
      }),
      senha: ['', [Validators.required]],
      confirmaSenha: [''],
      emailCorporativo: ['defaultEmail'],
      apelido: [''],
      useSocialName: [false],
    });
  }

  ngOnInit() {
    this.inMemoryService.configurationCurrent$.subscribe((res) => {
      if (Object.keys(res).length === 0) {
        this.defaultLogo = './assets/logo-login-default.png'
      }
      this.configuration = res;
    });
    // controlando quais campos devem ser apresentados primeiramente
    this.showInputCPF = true;
    this.showLoginButton = false;
    this.showWelcome = true;
    this.showInputSenha = false;

    this.auth.authWithoutLogin().subscribe(
    (res: { access_token: string }) => {
      this.token = res.access_token
    },
    (err: any) => {
      console.log(err)
    })
  }

  public onKeyUpCpf(): void {
    if (this.validaCPF()) this.searchCPF();
  }

  public validaCPF() {
    let cpf = this.loginForm.value.cpf as string;
    this.mensagemErro = '';
    if (!cpf || cpf.length === 0) return false;
    if (this.validateCPFService.validate(cpf)) return true;
    this.mensagemErro = 'CPF inválido. Por gentileza, tente novamente!';
    return false;
  }

  // funcao que permite a consulta no sso se cpf eh valido
  public searchCPF() {
    let cpf = this.loginForm.value.cpf as string;
    this.loadingCpf = true;
    // verificando cpf no SSO
    this.auth.checkCPFSSO(cpf).subscribe(
      (res: any) => {
        // se existir no TNW e SSO, deve-se redirecionar para a tela de login padrão
        if (res.sucesso)
          this.router.navigate(['login']);
        else
          this.getDataWizer();
      },
      (err) => {
        console.log('err', err)
        this.resetShowInputs();
        this.showLoginButton = false;
      }
    );
  }

  private getDataWizer() {
    let cpf = this.loginForm.value.cpf as string;
    let tenant: Tenant;

    // Capturar o tenant vinculado a esse CPF
    this.auth.getTenantsByCpf(cpf).subscribe(
      (res) => {
        if (res.length === 1)
        {
          tenant = res[0];
          // Aplicar o tema
          this.localStorageService.ShortName = tenant.shortName;
          this.localStorageService.Tenant = tenant.id;
          this.inMemoryService.saveConfiguration(tenant as AppConfiguration);
          this.tenantService.setTenant(tenant.shortName);

          this.auth.getUserData(cpf).subscribe(
            (res: EmployeeData) => {
              this.loadingCpf = false;
              this.showWelcome = false;
              this.showInputCPF = false;
              localStorage.setItem('wizer', JSON.stringify(res));
              this.verficaPossuiEmailCorporativo();
            },
            (err) => {
              console.log('err', err)
              this.resetShowInputs();
              this.showLoginButton = false;
              this.mensagemErro =
                'Você não está cadastrado nessa unidade. Por gentileza, verifique se o link está correto para entrar no Onboarding!';
            }
          );
        }
        else
          this.router.navigate(['login']);
      },
      () => {
        this.resetShowInputs();
        this.showLoginButton = false;
      }
    );
  }

  public escolherApelido() {
    this.resetShowInputs();
    this.showWelcome = false;
    this.showInputApelido = true;
  }

  public escolherSenha() {
    if (this.loginForm.value.useSocialName) {
      this.displayName = this.loginForm.value.apelido;
    } else {
      this.displayName = this.objToNaWiz.nome;
    }

    // libera campos para criacao de senha
    this.showHeader = false;
    this.showEmailCorporativo = false;
    this.showCelular = false;
    this.showInputCPF = false;
    this.showInputNovaSenha = true;
    this.runAnimation();
    this.createAccountSSO = true;
  }

  public createNewPassword() {
    if (this.confirmPassword()) {
      // se a senha atende os requisitos entao cria conta no ad
      this.showInputNovaSenha = true;
      this.addCellphoneNumber();
    }
  }

  public verficaPossuiEmailCorporativo() {
    const wizerString = localStorage.getItem('wizer') as any;
    this.objToNaWiz = JSON.parse(wizerString);
    if (this.objToNaWiz?.emailCorporativo) {
      // Verificação de excessao quando usuario ja tem email coporativo!
      this.temEmailCorporativo = true;
      this.showEmailCorporativo = true;
      this.showLoginButton = false;
      this.loginForm.patchValue({
        emailCorporativo: this.objToNaWiz?.emailCorporativo,
      });
      //console.log('já tem email corporativo: ', this.objToNaWiz.emailCorporativo); // console.log utilizado para debbug
    } else {
      //console.log('não tem email corporativo!'); // console.log utilizado para debbug
      this.showLoginButton = false;
      this.showWelcome = false;
      this.showHeader = true;
      this.showSocialName = true;
      this.showLoginButton = false;

      // this.checkSocialName();
      // this.createEmailCorporativo();
    }
  }

  public checkSocialName(): void {
    if (this.loginForm.value.useSocialName) {
      this.showSocialName = false;
      this.socialNamePage = true;
    } else {
      this.showSocialName = false;
      this.showCreateEmailCorporativo = true;
      this.createEmailCorporativo();

    }
  }

  public changeName(): void {
    this.socialNamePage = false;
    this.showCreateEmailCorporativo = true;
    this.createEmailCorporativo();
  }

  public confirmPassword() {
    // faz validacao da nova senha, nos requisitos e forca de senha.
    const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,})/;
    this.mensagemErro = '';
    this.isValidPassword = regexp.test(this.loginForm.value.senha);

    if (this.loginForm.value.senha !== this.loginForm.value.confirmaSenha) {
      // verifica se senhas sao iguais
      this.mensagemErro = 'Senhas estão diferentes.';
      //console.log('Senha diferente: ', this.loginForm.value.senha + ' - ' + this.loginForm.value.confirmaSenha); // console log debbug
      return false;
    }

    let noContainsUsername = true;
    const partsOfThreeLetters = this.objToNaWiz?.nome
      .match(/.{3}/g)
      .concat(
        this.objToNaWiz.nome.substr(1).match(/.{3}/g),
        this.objToNaWiz.nome.substr(2).match(/.{3}/g)
      );
    noContainsUsername = !partsOfThreeLetters?.some((x: any) =>
      this.loginForm.value.senha.toLowerCase().includes(x.toLowerCase())
    );

    if (!this.isValidPassword || !noContainsUsername) {
      this.mensagemErro = 'Senha não atende os requisitos listados.';
      //console.log('Senha não atende os requisitos listados', this.isValidPassword); // console log debbug
      return false;
    } else {
      return true;
    }
  }

  public testaSenha(inputNovaSenha: string) {
    // funcao para testar forca de senha de acordo com os requisitos

    const regexLowerCase = /^(?=.*[a-z])/;
    const regexUpperCase = /^(?=.*[A-Z])/;
    const regexNumerics = /^(?=.*\d)/;
    const regexMinCarac = /^(?=.{12,})/;

    this.requisitePassword.hasLowerCase = regexLowerCase.test(inputNovaSenha);
    this.requisitePassword.hasUpperCase = regexUpperCase.test(inputNovaSenha);
    this.requisitePassword.hasNumeric = regexNumerics.test(inputNovaSenha);
    this.requisitePassword.hasMinCarac = regexMinCarac.test(inputNovaSenha);
    this.requisitePassword.passwordCoincides =
      this.loginForm.value.senha === this.loginForm.value.confirmaSenha;

    // rule to not contain the user’s account name or parts of the user’s full name that exceed two consecutive characters
    if (inputNovaSenha.length > 2) {
      const partsOfThreeLetters = this.displayName
        .match(/.{3}/g)
        .concat(
          this.displayName.substr(1).match(/.{3}/g),
          this.displayName.substr(2).match(/.{3}/g)
        );
      this.requisitePassword.noContainsUsername = !partsOfThreeLetters.some(
        (x: any) =>
          inputNovaSenha.toLowerCase().includes(
            x
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );
    }
  }

  public createEmailCorporativo() {
    // funcao para criacao de email corporativo
    //console.log('Agora vai criar email corporativo! Com o nome: ', this.objToNaWiz.nome); // console log debbug
    if (!this.objToNaWiz.unidadeDeNegocio) {
      this.objToNaWiz.unidadeDeNegocio = 'corporativo';
    }

    if (!this.loginForm.value.useSocialName) {
      this.getEmailSugeridos(
        this.objToNaWiz.nome,
        this.objToNaWiz.idNewWizer,
        this.objToNaWiz.unidadeDeNegocio.toLowerCase(),
        this.token
      );
    } else {
      this.getEmailSugeridos(
        this.loginForm.value.apelido,
        this.objToNaWiz.idNewWizer,
        this.objToNaWiz.unidadeDeNegocio.toLowerCase(),
        this.token
      );
    }
  }

  public acaoRetornoEmailCorporativo() {
    // funcao de retorno apos a busca de email corporativos
    if (Object.keys(this.emailRecomendado).length === 0) {
      this.showCreateEmailCorporativo = false;
      this.mensagemErroEmail = `Olá wizzer, não há e-mail disponível, procure o time do COP que está fazendo a sua admissão!`;
    }
    if (
      this.emailRecomendado &&
      Object.keys(this.emailRecomendado).length !== 0
    ) {
      this.runAnimation();
      this.createAccountSSO = true;
    } else if (this.objToNaWiz.semSugestaoEmail) {
      // verificando se o usuario tem flag de email nao disponive
      this.resetShowInputs();
      this.showContatoGestor = true;
      this.mensagemErroEmail = `Olá Wizzer! Seu caso ainda está sendo analisado, tente novamente mais tarde ou procure o time do
      COP que está fazendo a sua admissão.`;
    } else {
      this.showContatoGestor = true;
      this.showCreateEmailCorporativo = false;
      //console.log('nao ha email disponivel!'); // console debbug
      if (
        this.objToNaWiz.emailGestor !== undefined &&
        this.objToNaWiz.emailGestor !== null &&
        this.objToNaWiz.emailGestor !== ''
      ) {
        this.mensagemErroEmail = `É necessário que você contate seu gestor: ${this.objToNaWiz.emailGestor.toLowerCase()} e
        peça para ele solicitar a criação do email ao time de Serviços Unificados!`;
      } else {
        this.mensagemErroEmail = `É necessário que você contate seu gestor e peça para ele solicitar a criação do email ao time de Serviços
         Unificados!`;
      }
    }
  }

  // funcao que retorna todos os emails corporativos disponíveis
  public getEmailSugeridos(
    nomeCompleto: string,
    idNewWizer: string,
    un: string,
    token: string,
  ) {
    this.loadingEmailRecomendado = true;
    if (!environment.production) {
      let emailRecomendado = new EmailRecomendado(
        'Teste Seleção',
        'teste',
        'teste@wiz.co',
        'true'
      );
      this.emailRecomendado = [emailRecomendado];
      this.loadingEmailRecomendado = false;
      this.acaoRetornoEmailCorporativo();
    } else {
      this.auth
        .getEmailsRecomendados(nomeCompleto, idNewWizer, un, token) //token error
        .subscribe(
          (email) => {
            this.emailRecomendado = email;
            this.loadingEmailRecomendado = false;
            this.acaoRetornoEmailCorporativo();
          },
          (err) => {
            this.resetShowInputs(); // zerando campos de input para apresentar mensagem de erro para usuario
            this.isReqError = true; // habilitando a mensagem de erro
            this.emailRecomendado = err;
          }
        );
    }
  }

  public preEmailSelected() {
    // funcao para mostrar email pre selecionado e botao de confirmação
    this.resetShowInputs();
    this.showWelcome = false;
    this.showConfirmationEmailSelected = true;
  }

  public confirmEmailSelected() {
    // funcao para confirmacao de email selecionado
    this.showConfirmationEmailSelected = false;
    this.escolherSenha();
  }

  public confirmApelido() {
    // funcao para confirmacao de apelido
    // Todo: modularizar essa função separadamente depois
    this.mensagemErro = '';
    if (
      this.loginForm.value.celular === '' ||
      this.loginForm.value.celular === undefined ||
      this.loginForm.value.celular === null
    ) {
      this.mensagemErro = 'Insira um número celular';
      return;
    }

    if (
      !this.commomValidatorService.verificaNumeroCelular(
        this.loginForm.value.celular
      )
    ) {
      this.mensagemErro = 'Digite um número válido';
    }

    // this.resetShowInputs();
    // this.escolherSenha();
  }

  public confirmCellphone(): void {
    this.mensagemErro = '';
    if (
      this.loginForm.value.celular === '' ||
      this.loginForm.value.celular === undefined ||
      this.loginForm.value.celular === null
    ) {
      this.mensagemErro = 'Insira um número celular';
      return;
    }

    if (
      !this.commomValidatorService.verificaNumeroCelular(
        this.loginForm.value.celular
      )
    ) {
      this.mensagemErro = 'Digite um número válido';
      return;
    }

    this.criarContaNoAD();
  }

  public completeRegistration(): void {
    this.showWelcome = false;
    this.showInputCelular = false;
    this.registrationSuccess = true;
  }

  public criarContaNoAD() {
    // funcao para criacao de conta corporativa no SSO/AD
    this.showInputCelular = false;
    this.showEmailCorporativo = false;
    this.showInputNovaSenha = false;
    this.loadingCriandoContaAD = true;

    // controle necessario para evitar passagem errada de atributo de email corporativo
    // necessario p/ o fluxo de quando a pessoa ja tem email corporativo
    if (this.temEmailCorporativo) {
      this.emailCorporativoAD = this.loginForm.value.emailCorporativo;

      if (this.loginForm.value.useSocialName) {
        this.nomeAD = this.loginForm.value.apelido;
      } else {
        this.nomeAD = this.objToNaWiz?.nome;
      }
    } else {
      this.emailCorporativoAD = this.loginForm.value.emailCorporativo.email;
      this.nomeAD = this.loginForm.value.emailCorporativo.displayName;
    }

    const authObj = {
      id: this.objToNaWiz?.idNewWizer,
      nomeCompleto: this.nomeAD,
      apelido: this.loginForm.value.apelido,
      celular: this.loginForm.value.celular,
      email: this.emailCorporativoAD,
      numeroCasoAdmissao: this.objToNaWiz?.idCaso,
      dataAdmissao: this.objToNaWiz?.dataContratacao,
      senha: this.loginForm.value.senha,
      unidadeDeNegocio: this.objToNaWiz?.unidadeDeNegocio,
      matricula: 'TBD',
      userName: this.emailCorporativoAD?.split('@')[0],
      cargo: this.objToNaWiz?.cargo,
      departamento: this.objToNaWiz?.grupoHierarquico,
      cpf: this.objToNaWiz?.cpf,
      centroDeCusto: this.objToNaWiz?.centroDeCusto,
      cidade: this.objToNaWiz?.cidade,
      jaTenhoEmailCorporativo: this.temEmailCorporativo,
      gestor: this.objToNaWiz?.emailGestor?.split('@')[0],
      enabled: true,
    };

    if (!environment.production) {
      this.loadingCriandoContaAD = false;
      this.completeRegistration();
    } else {
      this.auth.setUser(this.loginForm.value.senha, authObj).subscribe(
        (res) => {
          this.loadingCriandoContaAD = false;
          this.completeRegistration();
        },
        (error) => {
          this.resetShowInputs(); // zerando campos de input para apresentar mensagem de erro para usuario
          this.isReqError = true; // habilitando a mensagem de erro
        }
      );
    }
  }

  public addCellphoneNumber(): void {
    this.showInputNovaSenha = false;
    this.showInputCelular = true;
  }

  public previousEmailSelected() {
    // funcao para controle do proximo e voltar, na escolha de email corporativo
    this.showCreateEmailCorporativo = true;
    this.showConfirmationEmailSelected = false;
  }

  public login() {
    // funcao que efetua login no onboarding
    this.mensagemErro = '';
    this.loadingButton = true;

    const cpf = this.loginForm.value.cpf
      .replaceAll('.', '')
      .replaceAll('-', '');
    this.sso
      .loginWithCredentials({
        userName: cpf,
        password: this.loginForm.value.senha,
      })
      .subscribe(
        (res) => {
          this.checkCPFWizPos(res);
        },
        (error) => {
          this.loadingButton = false;
          // tslint:disable-next-line: max-line-length
          this.mensagemErro = `Senha incorreta. Tente novamente`;
          this.checkCPFWizPos(error);
        }
      );
  }

  public sendUNCollaborator(un: string) {
    // funcao p/ enviar estatistica pro google analytics sobre qual unidade de negocio pertence o usuario
    this.googleAnalyticsService.eventEmitter(
      'login',
      'unidade de negócio',
      un?.toLowerCase(),
      39
    );
  }

  public getLastCellDigits(): string {
    let lastDigits = this.loginForm.value.cellphone.firstDigit;
    lastDigits += this.loginForm.value.cellphone.secondDigit;
    lastDigits += this.loginForm.value.cellphone.thirdDigit;
    lastDigits += this.loginForm.value.cellphone.fourthDigit;

    return lastDigits;
  }

  public checkCPFWizPos(tokenResult: any) {
    // funcao que verifica se houve retorno correto do token no SSO
    if (tokenResult === null) {
      this.resetShowInputs(); // zerando campos de input para apresentar mensagem de erro para usuario
      this.isReqError = true; // habilitando a mensagem de erro
    } else {
      // verificação pra identificar login de adminstrador.
      try {
        const userProfile = this.profile.checkUserProfile();
        // salva o tipo de perfil de acordo com a claim do token
        // verificação para ver se o perfil tem permissao de administrador e redirecionar corretamente
        if (
          userProfile === 'admin' ||
          userProfile === 'operador-full' ||
          userProfile === 'operador'
        ) {
          const fieldToken = 'hash'; // campo que sera buscado no local storage

          const jwData = this.profile.parseJwt(
            JSON.parse(window.localStorage.getItem('auth_data')!)[fieldToken]
          ); // resgatando token
          const obj = {
            // objeto que sera salvo no local storage, p/ que o header e foto no teams utilize os campos necessarios
            emailCorporativo:
              jwData[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
              ],
            nome: jwData[
              'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
            ],
            cpf: jwData.documento,
          };
          window.localStorage.setItem('wizer', JSON.stringify(obj));
          this.router.navigate(['admin']);
          return;
        }
      } catch (e: any) {
        throw e('Erro na decodificação do token');
      }
      this.auth
        .checkCPFToNaWiz(this.loginForm.value.cpf) // se token nao eh nulo, faz autenticacao no tonawiz
        .subscribe(
          (res) => {
            this.loadingButton = false;
            this.checkCPFWizOnboarding(res);
          },
          (err) => {
            this.isReqError = true; // zerando campos de input para apresentar mensagem de erro para usuario
            this.resetShowInputs(); // habilitando a mensagem de erro
            this.checkCPFWizOnboarding(err);
          }
        );
    }
  }

  public checkCPFWizOnboarding(obj: any) {
    // funcao para fazer o redirecionamento para o onboarding
    if (obj === null) {
      this.router.navigate(['login']); // Reinicia o processo de login
    } else {
      localStorage.setItem('wizer', JSON.stringify(obj)); // Armazena o objeto to na Wiz

      this.sendUNCollaborator(JSON.stringify(obj.unidadeDeNegocio)); // passando unidade de negócio para estatistica no google analytics

      this.router.navigate(['onboarding']);
    }
  }

  public runAnimation() {
    // funcao que controlar execução de animação
    this.animationController = true;
    setTimeout(() => (this.animationController = false), 8000);
  }

  // função para controlar exibição dos inputs do esqueci a senha
  public esqueciSenha() {
    this.resetShowInputs();
    this.loginForm.patchValue({ celular: '' });
    this.showEsqueciSenha = true;
    this.showWelcome = false;
  }

  //  função chamada após o usuário inserir o email dele pra o envio do código para mudar a senha.
  public confirmaEmailEnvioCod() {
    this.mensagemErro = '';
    this.loadingCodCelular = true;

    // consultando usuario por cpf, para resgatar o email dele
    this.auth.checkCPFToNaWiz(this.loginForm.value.cpf).subscribe(
      (res) => {
        this.loadingCodCelular = false;

        // TODO: verificar se precisa mesmo
        localStorage.setItem('wizer', JSON.stringify(res)); // Armazenamento do objeto do usuario no localstorage

        const wizerObj = localStorage.getItem('wizer') as any;

        this.objToNaWiz = JSON.parse(wizerObj);
        if (
          this.objToNaWiz.celular === undefined ||
          this.objToNaWiz.celular === null ||
          this.objToNaWiz.celular === ''
        ) {
          this.mensagemErro = `Talvez você não esteja conseguindo redefinir a sua senha por que não temos o seu número de celular, que tal
            conversar com alguém do COP para adicionar essa informação aqui no Tô na Wiz e ai então você pode vir aqui
            redefinir a sua senha!`;
          return;
        }

        // validação de celular associado com o cpf
        if (this.objToNaWiz.celular.substr(-4) === this.getLastCellDigits()) {
          this.enviarCodCelular();
        } else {
          this.invalidCell = true;
        }
      },
      (error) => {
        // Caso retorne erro, entao eh pq o cpf nao existe no tonawiz
        this.resetShowInputs();
        if (error.status === 404) {
          this.mensagemErro = 'Usuário não existe';
        }
        this.isReqError = true;
      }
    );
  }

  // controla exibição de avançar e voltar o step de inserir a senha
  public voltarInserirSenha() {
    this.resetShowInputs();
    this.showInputCPF = true;
    this.showLoginButton = true;
    // this.showInputSenha = true;
    // this.isSimpleLogin = true;
  }

  public enviarCodCelular() {
    this.loadingCodCelular = true;
    const user = {
      cpf: this.loginForm.value.cpf,
      celular: this.loginForm.value.celular,
    };

    this.auth.sendCodForgetPassword(user).subscribe(
      (res) => {
        this.loadingCodCelular = false;
        this.router.navigate(['new-password', user.cpf]);
      },
      (err) => {
        this.mensagemErro = 'Houve um erro ao enviar o código';
        this.loadingCodCelular = false;
      }
    );
  }

  public confirmSocialName(event: any): void {
    this.socialNameConfirmed = event?.checked;
  }

  public resetShowInputs() {
    // função para resetar inputs gerais
    this.loadingCpf = false;
    this.loadingCriandoContaAD = false;
    this.showLoginButton = false;
    this.showInputCPF = false;
    this.showInputSenha = false;
    this.showInputNovaSenha = false;
    this.showInputApelido = false;
    this.showCreateEmailCorporativo = false;
    this.showCreateCelular = false;
    this.isValidPassword = false;
    this.isSimpleLogin = false;
    this.loadingEmailRecomendado = false;
    this.showConfirmationEmailSelected = false;
    this.temEmailCorporativo = false;
    this.naoTemEmailDisponivel = false;
    this.showEmailCorporativo = false;
    this.showContatoGestor = false;
    this.createAccountSSO = false;
    this.mensagemErro = '';
    this.showEsqueciSenha = false;
    this.loadingUserToNaWiz = false;
    this.loadingCodEmail = false;
    this.showSocialName = false;
    this.socialNamePage = false;
    this.showWelcome = true;
    this.showInputCelular = false;
    this.registrationSuccess = false;
    this.showHeader = true;
    this.socialNameConfirmed = false;
  }

  public loginEnabled(): boolean {
    return this.loginForm.controls.senha.valid;
  }

  public canSubmitCell(): boolean {
    return this.loginForm.controls.cellphone.valid;
  }

  public checkApelido(): boolean {
    return this.loginForm.value.apelido !== '' && this.socialNameConfirmed;
  }

  public testPasswordEquality() {
    this.requisitePassword.passwordCoincides =
      this.loginForm.value.senha === this.loginForm.value.confirmaSenha;
  }

  public passwordCheck() {
    const requisites = this.requisitePassword;

    const requisitesLowUpper =
      requisites.hasLowerCase && requisites.hasUpperCase;
    const requisitesMinNumCarac =
      requisites.hasMinCarac &&
      requisites.hasNumeric &&
      requisites.noContainsUsername;

    return (
      requisitesLowUpper &&
      requisitesMinNumCarac &&
      this.loginForm.value.senha === this.loginForm.value.confirmaSenha
    );
  }
}
