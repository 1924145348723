import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-admin-home',
  templateUrl: './page-admin-home.component.html',
  styleUrls: ['./page-admin-home.component.scss'],
})
export class PageAdminHomeComponent implements OnInit {
  // Variavel que guarda perfil do usuário
  userProfile = '';

  constructor(
    public profile: ProfileService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.userProfile = this.profile.checkUserProfile()!;

    // controle de visibilidade de páginas
    if (this.userProfile === 'colaborador') {
      this.router.navigateByUrl('login');
    }
  }
}
