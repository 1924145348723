<div class="page-tour">
  <div class="page-tour_hero">
    <div class="hero-content">
      <h1>Conheça os escritórios da Wiz</h1>
      <h3>
        O Heverton, nosso CEO gravou esse vídeo de boas vindas especialmente pra
        você.
      </h3>
      <div class="hero_video embed-responsive embed-responsive-21by9">
        <iframe
          width="660"
          height="368"
          class="embed-responsive embed-responsive-21by9"
          src="https://www.youtube.com/embed/e9rlZDKcAeA"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          title="Videos do tour na wiz"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
  <div class="page-tour_videos">
    <div class="container">
      <h2>Preparamos esse tour pra você</h2>
      <h3>
        Você vai conhecer como é por dentro de cada uma das áreas da Wiz. Só
        apertar o play!
      </h3>
      <br />
      <div class="row mt-5" *ngFor="let info of informacoes">
        <div
          class="col-12 col-md-5 video embed-responsive embed-responsive-21by9"
        >
          <youtube-player
            [videoId]="info.url"
            [playerVars]="{ autoplay: 1 }"
            [width]="300"
            [height]="227"
          >
          </youtube-player>
        </div>
        <div class="col-12 col-md-7">
          <div class="conteudo-video">
            <img src="{{ info.img }}" alt="" />
            <h2>{{ info.title }}</h2>
            <p>{{ info.description }}</p>
          </div>
        </div>
      </div>

      <div class="row mt-5" *ngFor="let info of informacoesSemVideo">
        <div class="col-12 col-md-5 img-background">
          <img class="adjustImg" src="{{ info.imgExample }}" alt="" />
        </div>
        <div class="col-12 col-md-7">
          <div class="conteudo-video">
            <img src="{{ info.img }}" alt="" />
            <p class="mt-4">{{ info.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
