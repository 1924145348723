import { Injectable } from '@angular/core';
import { AppConfiguration } from '../configuration';
import { ReplaySubject } from 'rxjs';
import { LocalStorageManagerService } from '../../local-storage-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationInMemoryService {
  private configuration: AppConfiguration | undefined;
  private configurationReplay = new ReplaySubject<AppConfiguration>(1);
  public configurationCurrent$ = this.configurationReplay.asObservable();

  constructor(
    private localStorageService: LocalStorageManagerService
  ){}

  saveConfiguration(configuration: AppConfiguration): void {
    this.configurationReplay.next(configuration);
    this.configuration = configuration;
    this.localStorageService.ShortName = configuration["shortName"] ?? '';
    this.localStorageService.Theme = JSON.stringify(configuration) ?? '';
    this.localStorageService.Tenant = configuration["id"] ?? '';
  }

  getConfiguration(): AppConfiguration | undefined {
    return this.configuration;
  }
}
