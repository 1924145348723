import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-concluido-button',
  templateUrl: './concluido-button.component.html',
  styleUrls: ['./concluido-button.component.scss']
})
export class ConcluidoButtonComponent {
  @Input() buttonText: string;
  @Input() completedStep: boolean;
  @Input() buttonStyle: string;

  getButtonStyles() {
    if (this.completedStep) {
      return {
        'border-radius': '24px',
        'border': '1px solid var(--primary-medium)',
        'background': 'var(--primary-medium)',
        'color': 'var(--primary-extra-light, #E8F1FB)',
        'padding': '4px 12px'
      };
    } else {
      return {
        'border-radius': '24px',
        'border': '1px solid var(--secondary-dark)',
        'background': 'var(--secondary-extra-light)',
        'color': 'var(--secondary-darkest)',
        'padding': '4px 12px'
      };
    }
  }

  getButtonText() {
    return this.completedStep ? 'Concluído' : 'Concluir';
  }
}
