import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { first, debounceTime, switchMap } from 'rxjs/operators';
import { OnboardingServiceService } from '../../services/onboarding-service.service';
import { CommomValidatorsService } from '../../core/services/commom-validators.service';
import { PublicAuthService } from 'src/app/services/public-auth.service';
import { ValidateCPFService } from 'src/app/core/services/validate-cpf.service';

@Component({
  selector: 'app-page-admin-edit-colab',
  templateUrl: './page-admin-edit-colab.component.html',
  styleUrls: ['./page-admin-edit-colab.component.scss'],
})
export class PageAdminEditColabComponent implements OnInit {
  status = ['placement', 'inativo', 'ativo', 'cancelado'];

  estados = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MS',
    'MT',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  // variaveis de controle utilizada para verificar mudança de valor no input
  nomeColabControl = new UntypedFormControl('', Validators.required);
  nomeSocialControl = new UntypedFormControl('', Validators.required);
  nomePadrinhoControl = new UntypedFormControl('', Validators.required);
  nomeGestorControl = new UntypedFormControl('', Validators.required);

  editForm: UntypedFormGroup; // formulario de edicao do colaborador
  cpfUserEdit: string; // cpf do colaborador que sera editado, utilizado para preencher campos no formulario
  private sub: any;
  submitted: boolean; // variavel que controle se formulario foi submetido
  mensagemErro: string; // variavel utilizada para listagem de erros
  cpfValido: boolean; // variavel para fazer controle do digito verificador do cpf
  conteudoPadrinho: any[]; // variavel que guarda o conteudo retornado do padrinho
  conteudoGestor: any[]; // variavel que guarda o conteudo retornado do gestor
  loadingPadrinho: boolean;
  loadingGestor: boolean;
  messageSearchADSuccess: string; // mensagem que sera apresentada ao usuario no retorno da busca do AD.
  messageSearchADErro: string; // mensagem que p mostrar ao usuario no retorno da busca do AD de quando usuario nao existe ou houve erro.
  messageEditUserSuccess: string; // variavel de controle pra apresentar mensagem de sucesso na edição.
  messageEditUserErro: string; // variavel de controle pra apresentar mensagem de erro na edição.
  mensagemErroPadrinho: string; // variavel de controle pra apresentar mensagem de erro na pesquisa de gestor.
  mensagemErroGestor: string; // variavel de controle pra apresentar mensagem de erro na pesquisa de padrinho.
  loadingSearchAD: boolean; // variavel de controle, na pesquisa de usuario no AD
  loadingEditUser: boolean;
  loadingObjWizzer: boolean;
  loadingTermoCE: boolean;
  loadingTermoBen: boolean;
  loadingWizity: boolean;
  mensagemErroWizity: string;
  mensagemTermoCE: string;
  mensagemErroTermoBen: string;
  mensagemEtapaAtt: string;
  loadingEtapaAtt: boolean;

  // Variavel que guarda perfil do usuário
  userProfile = '';
  // Objeto para guardar informações do wizer no onboarding
  objWizzer: any;
  etapas: any;

  etapaSelecionada: number;
  statusEtapaSelecionada: boolean;
  resConcluiuTrilha: boolean;
  resAssinouTermoCE: boolean;
  resAssinouTermoBen: boolean;
  idTermoCondutaEtica = '2058'; // codigo da politica no compliance do termo de etica e conduta.
  idTermoConnfirmacaoBeneficios = '12'; // codigo da politica no compliance do termo de confirmação de beneficios selecionados no xerpa
  idCursoWizityImersao = '1538';
  idCursoWizityLGPD = '5359';
  idCursoWizityCompliance = '302';
  // idCursoWizityWizWelcome = "2462";
  idCursoWizityWizWelcome = '5434';
  cursoWizityLGPDDone: boolean;
  cursoWizityImersaoDone: boolean;
  cursoWizityComplianceDone: boolean;
  showInfoTrilha: boolean;

  userAd: any;
  loadingUserAd: boolean;
  emailRecomendado: any;
  loadingEmailRecomendado: boolean;
  naoTemRecomendacaoEmail: boolean;
  errorRecomendacaoEmail: boolean;

  loadingUserSSO: boolean;
  userSSO: any;

  public unidadeDeNegocio: any[];
  public tokenObj: Object;
  public token: string;

  constructor(
    private readonly router: Router,
    public profile: ProfileService,
    private readonly adminService: AdminService,
    private readonly fb: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    public onboardingService: OnboardingServiceService,
    public commomValidatorService: CommomValidatorsService,
    public readonly auth: PublicAuthService,
    public validateCPFService: ValidateCPFService
  ) {}

  ngOnInit() {
    this.etapaSelecionada = 1; // Inicializando select com id da etapa envie seus documentos
    this.statusEtapaSelecionada = true; // Inicializando select com ação de concluir etapa
    this.loadingObjWizzer = true;
    this.objWizzer = [];
    this.userProfile = this.profile.checkUserProfile()!;

    this.getBusinessUnits();
    this.getToken();

    // controle de visibilidade de página de acordo com o perfil
    if (this.userProfile === 'colaborador') {
      this.router.navigateByUrl('login');
    }

    // extraindo cpf do usuario que sera editado da url
    this.sub = this.route.params.subscribe((params) => {
      const key = 'cpf';
      this.cpfUserEdit = params[key];
      // TODO: INSERIR LOADING... enquanto carrega info do usuario que vai ser editado.
    });

    // Todos os campos utilizados no formulario e seus respectivos validators
    this.editForm = this.fb.group({
      idNewWizer: ['', Validators.required],
      idCaso: [''],
      matricula: [''],
      ativo: [false, Validators.required],
      status: ['', Validators.required],
      nome: this.nomeColabControl,
      cpf: ['', Validators.required],
      dataContratacao: ['', Validators.required],
      dataIntegracao: [null, ''],
      emailCorporativo: [null],
      emailPessoal: ['', Validators.required],
      telefone: [''],
      celular: [''],
      cargo: ['', Validators.required],
      unidadeDeNegocio: ['', Validators.required],
      localDeTrabalho: [''],
      // lotação do ASVEN
      agencia: [''],
      bairro: [''],
      cidade: ['', Validators.required],
      uf: ['', Validators.required],
      // dados padrinho
      cpfPadrinho: ['', Validators.required],
      emailPadrinho: ['', Validators.required],
      nomePadrinho: this.nomePadrinhoControl,
      // dados gestor
      emailGestor: ['', Validators.required],
      cpfGestor: ['', Validators.required],
      nomeGestor: this.nomeGestorControl,
      grupoHierarquico: [''],
      centroDeCusto: [''],
      apelido: [''],
    });

    // para ver os atributos do form
    //console.log(this.editForm.value);

    this.adminService.getUserByCpf(this.cpfUserEdit).subscribe(
      (data) => {
        data.unidadeDeNegocio = data.unidadeDeNegocio.toLowerCase();
        data.status = data.status.toLowerCase();
        // Convertendo data para preencher o formulario de edicao
        const dtcto = new Date(data.dataContratacao);
        data.dataContratacao = dtcto.toISOString().substring(0, 10);
        if (data.dataIntegracao) {
          const dtInteg = new Date(data.dataIntegracao);
          data.dataIntegracao = dtInteg.toISOString().substring(0, 10);
        }
        this.objWizzer = data;
        this.etapas = this.objWizzer.etapas;
        this.etapas.sort((a: { ordem: number }, b: { ordem: number }) => {
          return a.ordem - b.ordem;
        });
        // laço necessário para adicionar atributo de estilo CSS, para etapa concluida / nao concluida
        this.objWizzer.etapas.forEach(
          (element: { concluido: any; style: string }) => {
            if (element.concluido) {
              element.style = 'etapaConcluida';
            } else {
              element.style = 'etapaNaoConcluida';
            }
          }
        );

        this.editForm.patchValue(data);

        this.consultaUsuarioAd();
        this.getEmailSugeridos(
          this.objWizzer.nome,
          this.objWizzer.idNewWizer,
          this.objWizzer.unidadeDeNegocio
        );
        this.searchFuncEquipePadrinho();
        this.searchFuncEquipeGestor();
        this.consultarUsuarioSSO();
        this.loadingObjWizzer = false;
      },
      (err) => {
        // TODO: colocar msg de erro
        this.loadingObjWizzer = false;
      }
    );
  }

  public getBusinessUnits(): void {
    this.onboardingService.getBusinessUnitList().subscribe((result) => {
      this.unidadeDeNegocio = result;
    });
  }

  public consultarUsuarioSSO(): void {
    this.loadingUserSSO = true;
    this.auth.getAccountSSO(this.objWizzer.cpf).subscribe(
      (res) => {
        this.userSSO = res;
        this.loadingUserSSO = false;
      },
      (err) => {
        this.userSSO = null;
        this.loadingUserSSO = false;
      }
    );
  }

  public getEmailSugeridos(nomeCompleto: any, idNewWizer: any, un: any): void {
    this.loadingEmailRecomendado = true;
    this.errorRecomendacaoEmail = false;
    this.auth.recomendaEmail(nomeCompleto, idNewWizer, un).subscribe(
      (email) => {
        this.emailRecomendado = email;
        this.loadingEmailRecomendado = false;

        if (Object.keys(this.emailRecomendado).length === 0) {
          this.naoTemRecomendacaoEmail = true;
        }
      },
      (err) => {
        this.errorRecomendacaoEmail = true;
        this.loadingEmailRecomendado = false;
      }
    );
  }

  public consultaUsuarioAd(): void {
    this.loadingUserAd = true;
    this.adminService.searchUserADByCpf(this.objWizzer.cpf).subscribe(
      (res: any) => {
        const fieldRes = 'Usuario';
        const userAD = res[fieldRes];

        const userAdEmail = userAD?.Email !== (null || undefined || '');
        const objWizzerEmail =
          !this.objWizzer.emailCorporativo ||
          this.objWizzer.emailCorporativo === '';

        if (userAdEmail && objWizzerEmail) {
          this.editForm.patchValue({
            emailCorporativo: userAD?.Email,
          });
          this.updateAutomaticWizzer();
        }
        this.userAd = res[fieldRes];
        this.loadingUserAd = false;
      },
      (err) => {
        this.loadingUserAd = false;
      }
    );
  }

  // facilitador para extrair informacao dos valores do formulario
  get f() {
    return this.editForm.controls;
  }

  public updateAutomaticWizzer() {
    this.adminService
      .updateUser(this.editForm.value.idNewWizer, this.editForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    // Se o formulario eh invalido ele para aqui
    // TODO: fazer um melhor tratamento
    if (
      !this.commomValidatorService.verificaNumeroCelular(
        this.editForm.value.celular
      )
    ) {
      alert('Digite um número celular válido');
      this.submitted = false;
      return;
    }

    // if (this.editForm.invalid || this.cpfValido) {
    //   alert('Formulário inválido!');
    //   return;
    // }

    this.messageEditUserSuccess = '';
    this.messageEditUserErro = '';
    this.loadingEditUser = true;

    // Se o formulario eh valido, ele chama o metodo para editar usuario
    this.adminService
      .updateUser(this.editForm.value.idNewWizer, this.editForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.messageEditUserSuccess = 'Colaborador editado com sucesso.';
          this.loadingEditUser = false;
          setTimeout(() => (this.messageEditUserSuccess = ''), 8000);
        },
        (error) => {
          this.messageEditUserErro = 'Erro na edição de colaborador.';
          this.loadingEditUser = false;
          setTimeout(
            () => (this.messageEditUserErro = 'Erro na edição de colaborador.'),
            20000
          );
        }
      );
  }

  public getToken() {
    this.tokenObj = this.adminService.token().subscribe(
      (token) => {
        this.tokenObj = token;
        this.token = token.access_token;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // metodo para pesqusiar padrinho por nome
  searchFuncEquipePadrinho() {
    this.nomePadrinhoControl.valueChanges
      .pipe(
        /* utilizado para evitar multiples hits no back.
        So consulta num intervalo de 500ms que o usuario parou de digitar */
        debounceTime(500),
        switchMap((nome) => {
          this.loadingPadrinho = true;
          if (nome.length > 3) {
            this.mensagemErroPadrinho = '';
            return this.adminService.searchFuncAll(nome, this.token);
          } else {
            this.loadingPadrinho = false;
            return [];
          }
        })
      )
      .subscribe(
        (res) => {
          this.conteudoPadrinho = res;
          this.loadingPadrinho = false;
        },
        (err) => {
          this.loadingPadrinho = false;
          this.mensagemErroPadrinho =
            'Método de consulta de padrinho não está funcionando.';
          this.conteudoPadrinho = err;
        }
      );
  }

  // insere informacao complementar de acordo com o padrinho que foi pesquisado.
  setInformationPadrinho(selectedPadrinho: any) {
    this.editForm.patchValue({
      nomePadrinho: selectedPadrinho.nome,
      emailPadrinho: selectedPadrinho.email,
      cpfPadrinho: selectedPadrinho.cpf,
    });
  }

  // metodo para pesqusiar gestor por nome0
  searchFuncEquipeGestor() {
    this.nomeGestorControl.valueChanges
      .pipe(
        // utilizado para evitar multiples hits no back. So consulta num intervalo de 500ms que o usuario parou de digitar
        debounceTime(500),
        switchMap((nome) => {
          this.loadingGestor = true;
          // if necessario pois endpoint so pesquisa com string de tamanho 3
          if (nome.length > 3) {
            this.mensagemErroGestor = '';
            return this.adminService.searchFuncAll(nome, this.token);
          } else {
            this.loadingGestor = false;
            return [];
          }
        })
      )
      .subscribe(
        (res) => {
          this.conteudoGestor = res;
          this.loadingGestor = false;
        },
        (err) => {
          this.loadingGestor = false;
          this.mensagemErroGestor =
            'Método de consulta de gestor não está funcionando.';
          this.conteudoGestor = err;
        }
      );
  }

  // insere informacao complementar de acordo com o gestor que foi pesquisado.
  setInformationGestor(selectedGestor: any) {
    this.editForm.patchValue({
      nomeGestor: selectedGestor.nome,
      emailGestor: selectedGestor.email,
      cpfGestor: selectedGestor.cpf,
    });
  }

  // Função que busca o email que está cadastro no AD para o usuario que está sendo editado.
  searchEmailAD() {
    this.loadingSearchAD = true;
    this.messageSearchADSuccess = '';
    this.messageSearchADErro = '';
    this.adminService.searchUserADByCpf(this.editForm.value.cpf).subscribe(
      (res: any) => {
        const fieldRes = 'Usuario';
        const userAD = res[fieldRes];
        this.loadingSearchAD = false;
        if (userAD?.Email !== ('' || undefined || '')) {
          this.editForm.patchValue({
            emailCorporativo: userAD?.Email,
          });

          this.messageSearchADSuccess = 'Campo atualizado com o AD';
          setTimeout(() => (this.messageSearchADSuccess = ''), 8000);
        }
      },
      (err) => {
        this.loadingSearchAD = false;
        this.messageSearchADErro =
          'Erro na busca de usuário no AD, ou usuário não existe';
        setTimeout(
          () =>
            (this.messageSearchADErro =
              'Erro na busca de usuário no AD, ou usuário não existe'),
          8000
        );
      }
    );
  }

  // Função para validar cpf
  verficadorCpf(inputCPF: string) {
    if (inputCPF.length > 13) {
      this.mensagemErro = '';
      this.cpfValido = this.validateCPFService.validate(inputCPF);
      if (!this.cpfValido) {
        this.mensagemErro = 'CPF inválido.';
      }
    }
  }

  // metodo para atualizar etapa manualmente de acordo com os parametros passados  pelo front.
  atualizarEtapa() {
    this.loadingEtapaAtt = true;
    this.onboardingService
      .updateOnbordingSteps({
        IDOnboarding: this.etapas[this.etapaSelecionada]?.idOnboarding,
        done: this.statusEtapaSelecionada,
        cpf: this.cpfUserEdit,
      })
      .subscribe(
        (res) => {
          this.loadingEtapaAtt = false;
          // atualizando objeto atual para não precisar uma nova consulta.
          this.etapas[this.etapaSelecionada].concluido =
            this.statusEtapaSelecionada;
          // controle necessario para atualizar o style do objeto com as etapas
          if (this.statusEtapaSelecionada) {
            this.etapas[this.etapaSelecionada].style = 'etapaConcluida';
          } else {
            this.etapas[this.etapaSelecionada].style = 'etapaNaoConcluida';
          }

          this.mensagemEtapaAtt = 'Etapa atualizada com sucesso';
        },
        (err) => {
          this.mensagemEtapaAtt = 'Erro ao atualizar etapa';
          this.loadingEtapaAtt = false;
        }
      );
  }

  verificaCadeiaDeCursos() {
    this.showInfoTrilha = true;
    this.verifyEndCurso(this.idCursoWizityImersao);
    this.verifyEndCurso(this.idCursoWizityCompliance);
    this.verifyEndCurso(this.idCursoWizityLGPD);
  }

  verifyEndCurso(idCurso: string) {
    this.mensagemErroWizity = '';
    this.loadingWizity = true;
    this.onboardingService
      .verifyEndTrilha(idCurso, this.objWizzer.cpf)
      .subscribe(
        (res) => {
          if (this.idCursoWizityImersao === idCurso) {
            this.cursoWizityImersaoDone = res;
          }

          if (this.idCursoWizityLGPD === idCurso) {
            this.cursoWizityLGPDDone = res;
          }

          if (this.idCursoWizityCompliance === idCurso) {
            this.cursoWizityComplianceDone = res;
          }
          this.loadingWizity = false;
          // console.log(idCurso, ": ", res);
        },
        (err) => {
          this.mensagemErroWizity = 'Erro ao consultar endpoint da Wizity.';
          this.loadingWizity = false;
          // console.error(idCurso, ": ", err);
        }
      );
  }

  // faz  consulta diretamente nos endpoint da wizity e portal compliance para verficiar se usuario completou a etapa
  atualizaStatusOnboarding() {
    this.loadingTermoCE = true;
    this.loadingTermoBen = true;
    this.mensagemTermoCE = '';
    this.mensagemErroTermoBen = '';

    // endpoint wizity
    this.verificaCadeiaDeCursos();

    // endpoint portal compliance
    this.onboardingService
      .getAcceptedTerm(this.objWizzer.cpf, this.idTermoCondutaEtica)
      .subscribe(
        (res) => {
          this.loadingTermoCE = false;
          this.resAssinouTermoCE = res.aceito;
        },
        (err) => {
          this.loadingTermoCE = false;
          this.mensagemTermoCE =
            'Erro ao consultar assinatura do termo de Conduta e Ética.';
          this.resAssinouTermoCE = err;
        }
      );

    // endpoint portal compliance
    this.onboardingService
      .getAcceptedTerm(this.objWizzer.cpf, this.idTermoConnfirmacaoBeneficios)
      .subscribe(
        (res) => {
          this.loadingTermoBen = false;
          this.resAssinouTermoBen = res.aceito;
        },
        (err) => {
          this.mensagemErroTermoBen =
            'Erro ao consultar assinatura do termo de Confirmação de Benefícios.';
          this.loadingTermoBen = false;
          this.resAssinouTermoBen = err;
        }
      );
  }
}
