<app-header></app-header>

<app-previous-route></app-previous-route>

<div class="content-all">
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a
        class="nav-item nav-link active mr-1"
        id="nav-home-tab"
        data-toggle="tab"
        href="#nav-home"
        role="tab"
        aria-controls="nav-home"
        aria-selected="true"
        >Informações Básicas</a
      >
      <a
        class="nav-item nav-link mr-1"
        id="nav-profile-tab"
        data-toggle="tab"
        href="#nav-profile"
        role="tab"
        aria-controls="nav-profile"
        aria-selected="false"
        >Ver Etapas</a
      >
      <a
        class="nav-item nav-link"
        id="nav-contact-tab"
        data-toggle="tab"
        href="#nav-contact"
        role="tab"
        aria-controls="nav-contact"
        aria-selected="false"
        >Situação no AD</a
      >
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <!-- ############ ABA INFORMAÇÕES BASICA COLABORADOR ############# -->
    <div
      class="tab-pane fade show active"
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
    >
      <form class="" [formGroup]="editForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div
            class="row d-flex align-items-center justify-content-center loadingIzzy"
          >
            <img
              *ngIf="loadingObjWizzer"
              class="img-fluid"
              src="../../../assets/multitenant/loading.svg"
              alt="Gif loading"
            />
          </div>

          <div *ngIf="!loadingObjWizzer" class="col item-card radius-to-aba">
            <div class="row">
              <div class="col-6">
                <p style="margin-top: 25px; font-size: 20px; font-weight: 400;">
                  Dados do Colaborador
                </p>
              </div>
              <div class="col-6 d-flex justify-content-end required">
                <label class="" for="nome">Campo Obrigatório</label>
              </div>
            </div>
            <!-- FORM ROW -->
            <div class="form-row">
              <div class="col-xl-1 col-lg-2 mb-3">
                <label class="mt-2" for="nome">Matricula</label>
                <input
                  title="matricula"
                  formControlName="matricula"
                  name="matricula"
                  class="form-control"
                  id="matricula"
                  [ngClass]="{ 'is-invalid': submitted && f.matricula.errors }"
                />
                <div
                  *ngIf="submitted && f.matricula.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.matricula.errors.required">
                    Campo matricula é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-xl-2 col-lg-2 mb-3 required">
                <label class="mt-2" for="nome"> Status </label>
                <select
                  title="status"
                  class="form-control"
                  formControlName="status"
                  [ngClass]="{ 'is-invalid': submitted && f.status.errors }"
                >
                  <option *ngFor="let item of status" value="{{ item }}">
                    {{ item }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && f.status.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.status.errors.required">
                    Campo status é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-xl-2 col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">Data de contratação</label>
                <input
                  title="dataContratacao"
                  type="date"
                  formControlName="dataContratacao"
                  name="dataContratacao"
                  class="form-control"
                  id="dataContratacao"
                  [ngClass]="{
                    'is-invalid': submitted && f.dataContratacao.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.dataContratacao.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.dataContratacao.errors.required">
                    Campo data de contratação é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-xl-2 col-lg-3 mb-3">
                <label class="mt-2" for="nome">Data de integração</label>
                <input
                  title="dataIntegracao"
                  type="date"
                  formControlName="dataIntegracao"
                  name="dataIntegracao"
                  class="form-control"
                  id="dataIntegracao"
                  [ngClass]="{
                    'is-invalid': submitted && f.dataIntegracao.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.dataIntegracao.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.dataIntegracao.errors.required">
                    Campo data de integração é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-12 mb-3">
                <label class="mt-2" for="nome">Ativo</label>
                <div
                  class="form-check mt-1"
                  [ngClass]="{ 'is-invalid': submitted && f.ativo.errors }"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="ativo"
                    id="ativo1"
                    [value]="true"
                    formControlName="ativo"
                    checked
                  />
                  <label class="form-check-label" for="ativo1"> Ativado </label>
                  <input
                    class="form-check-input ml-4"
                    type="radio"
                    name="ativo"
                    id="ativo2"
                    [value]="false"
                    formControlName="ativo"
                  />
                  <label class="form-check-label ml-5" for="ativo2">
                    Desativado
                  </label>
                </div>
                <div
                  *ngIf="submitted && f.ativo.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.ativo.errors.required">
                    Campo ativo é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <!-- FORM ROW -->

            <!-- FORM ROW -->
            <div class="form-row">
              <div class="col-xl-4 col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">Nome</label>
                <input
                  formControlName="nome"
                  name="nome"
                  class="form-control"
                  id="nome"
                  [ngClass]="{ 'is-invalid': submitted && f.nome.errors }"
                />
                <div
                  *ngIf="submitted && f.nome.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.nome.errors.required">
                    Campo nome é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">Nome Social</label>
                <input
                  title="apelido"
                  formControlName="apelido"
                  name="nomeSocial"
                  class="form-control"
                  id="nomeSocial"
                  [ngClass]="{ 'is-invalid': submitted && f.apelido.errors }"
                />
                <div
                  *ngIf="submitted && f.apelido.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.apelido.errors.required">
                    Campo nome é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-xl-2 col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">CPF</label>
                <input
                  title="cpf"
                  formControlName="cpf"
                  name="cpf"
                  class="form-control"
                  id="cpf"
                  maxlength="14"
                  mask="000.000.000-00"
                  (keyup)="verficadorCpf($any($event.target).value)"
                  [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }"
                />
                <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                  <div *ngIf="f.cpf.errors.required">
                    Campo cpf é obrigatório
                  </div>
                </div>
                <div *ngIf="mensagemErro" class="mensagem-error mt-1">
                  <img
                    src="./assets/general/to-na-wiz/login//img/icons/alert.png"
                    width="17"
                    class="mr-2"
                    alt=""
                  />{{ mensagemErro }}
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">Email Pessoal</label>
                <input
                  title="emailPessoal"
                  formControlName="emailPessoal"
                  name="emailPessoal"
                  class="form-control"
                  id="emailPessoal"
                  [ngClass]="{
                    'is-invalid': submitted && f.emailPessoal.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.emailPessoal.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.emailPessoal.errors.required">
                    Campo email pessoal é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 mb-3">
                <label
                  *ngIf="!messageSearchADSuccess && !messageSearchADErro"
                  class="mt-2"
                  for="nome"
                >
                  Email Corporativo
                </label>
                <label *ngIf="messageSearchADSuccess" class="mt-2" for="nome"
                  ><span class="badge badge-success">
                    {{ messageSearchADSuccess }}
                  </span></label
                >
                <label *ngIf="messageSearchADErro" class="mt-2" for="nome"
                  ><span class="badge badge-danger">
                    {{ messageSearchADErro }}
                  </span></label
                >

                <span *ngIf="loadingSearchAD" class="icon-spinner fa-lg ml-3">
                  <em class="fas fa-spinner fa-pulse"></em>
                </span>
                <div class="input-group">
                  <input
                    title="emailCorporativo"
                    formControlName="emailCorporativo"
                    name="emailCorporativo"
                    class="form-control"
                    id="emailCorporativo"
                    [ngClass]="{
                      'is-invalid': submitted && f.emailCorporativo.errors
                    }"
                    [disabled]="true"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      (click)="searchEmailAD()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Pesquisar se tem email no AD"
                      [disabled]="loadingSearchAD"
                    >
                      <em class="fa fa-search"></em>
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="submitted && f.emailCorporativo.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.emailCorporativo.errors.required">
                    Campo email corporativo é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <!-- FORM ROW -->

            <!-- FORM ROW -->
            <div class="form-row">
              <div class="col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">Cargo</label>
                <input
                  title="cargo"
                  formControlName="cargo"
                  name="cargo"
                  class="form-control"
                  id="cargo"
                  [ngClass]="{ 'is-invalid': submitted && f.cargo.errors }"
                />
                <div
                  *ngIf="submitted && f.cargo.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.cargo.errors.required">
                    Campo cargo é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">Unidade de Negócio</label>
                <select
                  title="un"
                  class="form-control"
                  formControlName="unidadeDeNegocio"
                  [ngClass]="{
                    'is-invalid': submitted && f.unidadeDeNegocio.errors
                  }"
                >
                  <option
                    *ngFor="let item of unidadeDeNegocio"
                    value="{{ item?.key }}"
                  >
                    {{ item?.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && f.unidadeDeNegocio.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.unidadeDeNegocio.errors.required">
                    Campo unidade de negócio é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-lg-3 mb-3">
                <label class="mt-2" for="nome">Local de Trabalho</label>
                <input
                  title="localDeTrabalho"
                  formControlName="localDeTrabalho"
                  name="localDeTrabalho"
                  class="form-control"
                  id="localDeTrabalho"
                  [ngClass]="{
                    'is-invalid': submitted && f.localDeTrabalho.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.localDeTrabalho.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.localDeTrabalho.errors.required">
                    Campo local de trabalho é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-lg-3 mb-3">
                <label class="mt-2" for="nome">Agência</label>
                <input
                  title="agencia"
                  formControlName="agencia"
                  name="agencia"
                  class="form-control"
                  id="agencia"
                />
              </div>
            </div>
            <!-- FORM ROW -->

            <!-- FORM ROW -->
            <div class="form-row">
              <div class="col-lg-2 mb-3 required">
                <label class="mt-2" for="nome">Cidade</label>
                <input
                  title="cidade"
                  formControlName="cidade"
                  name="cidade"
                  class="form-control"
                  id="cidade"
                  [ngClass]="{ 'is-invalid': submitted && f.cidade.errors }"
                />
                <div
                  *ngIf="submitted && f.cidade.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.cidade.errors.required">
                    Campo cidade é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-lg-1 mb-3 required">
                <label class="mt-2" for="nome">UF</label>
                <select
                  title="estado"
                  class="form-control"
                  formControlName="uf"
                  [ngClass]="{ 'is-invalid': submitted && f.uf.errors }"
                >
                  <option *ngFor="let item of estados" value="{{ item }}">
                    {{ item }}
                  </option>
                </select>
                <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
                  <div *ngIf="f.uf.errors.required">Campo UF é obrigatório</div>
                </div>
              </div>

              <div class="col-lg-3 mb-3">
                <label class="mt-2" for="nome">Bairro</label>
                <input
                  title="bairro"
                  formControlName="bairro"
                  name="bairro"
                  class="form-control"
                  id="bairro"
                />
              </div>

              <div class="col-lg-3 mb-3">
                <label class="mt-2" for="nome">Telefone</label>
                <input
                  title="telefone"
                  formControlName="telefone"
                  name="telefone"
                  class="form-control"
                  id="telefone"
                  malgength="13"
                  mask="(00) 000000000"
                  [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }"
                />
                <div
                  *ngIf="submitted && f.telefone.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.telefone.errors.required">
                    Campo telefone é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-lg-3 mb-3">
                <label class="mt-2" for="nome">Celular</label>
                <input
                  title="cel"
                  formControlName="celular"
                  name="celular"
                  class="form-control"
                  id="celular"
                  maxlength="14"
                  mask="(00) 000000000"
                  [ngClass]="{ 'is-invalid': submitted && f.celular.errors }"
                />
                <div
                  *ngIf="submitted && f.celular.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.celular.errors.required">
                    Campo celular é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <!-- FORM ROW -->

            <!-- FORM ROW -->
            <div class="form-row">
              <div class="col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">Grupo Hierárquico</label>
                <input
                  title="grupoHierarquico"
                  formControlName="grupoHierarquico"
                  name="grupoHierarquico"
                  class="form-control"
                  id="grupoHierarquico"
                  [ngClass]="{
                    'is-invalid': submitted && f.grupoHierarquico.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.grupoHierarquico.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.grupoHierarquico.errors.required">
                    Campo Grupo Hierárquico é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-lg-3 mb-3 required">
                <label class="mt-2" for="nome">Centro de Custo</label>
                <input
                  title="centroDeCusto"
                  formControlName="centroDeCusto"
                  name="centroDeCusto"
                  class="form-control"
                  id="centroDeCusto"
                  [ngClass]="{
                    'is-invalid': submitted && f.centroDeCusto.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.centroDeCusto.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.centroDeCusto.errors.required">
                    Campo Centro de custo é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <!-- FORM ROW -->
          </div>

          <!-- INFORMACOES GESTOR -->
          <div *ngIf="!loadingObjWizzer" class="col item-card">
            <div
              class="row"
              style="margin-top: 20px; padding: 10px; font-size: 25px"
            >
              <p style="margin-bottom: 15px">Dados do Gestor</p>
            </div>

            <div
              *ngIf="mensagemErroPadrinho"
              class="row alert alert-warning position-alert mt-4"
            >
              {{ this.mensagemErroGestor }}
            </div>

            <!-- FORM ROW -->
            <div class="form-row">
              <div class="col-lg-5 mb-3">
                <label class="mt-2" for="">Indique o gestor</label>
                <span *ngIf="loadingGestor" class="icon-spinner fa-lg ml-3"
                  ><em class="fas fa-spinner fa-pulse"></em
                ></span>
                <input
                  name="padrinho"
                  formControlName="nomeGestor"
                  class="form-control form-control"
                  type="text"
                  autocomplete="off"
                  placeholder="Pesquisar Gestor"
                  [ngClass]="{ 'is-invalid': submitted && f.nomeGestor.errors }"
                />
                <div
                  *ngIf="submitted && f.nomeGestor.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.nomeGestor.errors.required">
                    Campo nome do gestor é obrigatório
                  </div>
                </div>
                <div class="result-auto-complete">
                  <div class="item-container">
                    <a
                      *ngFor="let r of conteudoGestor; let em = index"
                      class="item-result"
                      (click)="setInformationGestor(r)"
                    >
                      <div class="item-list">
                        {{ r.nome }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 mb-3 required">
                <label class="mt-2" for="nome">CPF do gestor</label>
                <input
                  title="cpfGestor"
                  formControlName="cpfGestor"
                  name="cpfGestor"
                  class="form-control"
                  id="cpfg"
                  [disabled]="true"
                  [ngClass]="{ 'is-invalid': submitted && f.cpfGestor.errors }"
                />
                <div
                  *ngIf="submitted && f.cpfGestor.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.cpfGestor.errors.required">
                    Campo cpf do gestor é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-lg-5 mb-3 required">
                <label class="mt-2" for="nome">Email do gestor</label>
                <input
                  title="emailGestor"
                  formControlName="emailGestor"
                  name="emailGestor"
                  class="form-control"
                  id="emailg"
                  [ngClass]="{
                    'is-invalid': submitted && f.emailGestor.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.emailGestor.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.emailGestor.errors.required">
                    Campo email do gestor é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- FORM ROW -->

          <!-- INFORMACOES PADRINHO  -->
          <div *ngIf="!loadingObjWizzer" class="col item-card">
            <div
              class="row"
              style="margin-top: 20px; padding: 10px; font-size: 25px"
            >
              <p style="margin-bottom: 15px">Dados do Padrinho</p>
            </div>

            <div
              *ngIf="mensagemErroPadrinho"
              class="row alert alert-warning position-alert mt-4"
              aria-label="Close"
            >
              {{ this.mensagemErroPadrinho }}
            </div>

            <!-- FORM ROW -->
            <div class="form-row">
              <div class="col-lg-5 mb-3">
                <label class="mt-2" for="">Indique o padrinho</label>
                <span *ngIf="loadingPadrinho" class="icon-spinner fa-lg ml-3"
                  ><em class="fas fa-spinner fa-pulse"></em
                ></span>
                <input
                  name="padrinho"
                  formControlName="nomePadrinho"
                  class="form-control form-control"
                  type="text"
                  autocomplete="off"
                  placeholder="Pesquisar Padrinho"
                  [ngClass]="{
                    'is-invalid': submitted && f.nomePadrinho.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.nomePadrinho.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.nomePadrinho.errors.required">
                    Campo nome do padrinho é obrigatório
                  </div>
                </div>

                <div class="result-auto-complete">
                  <div class="item-container">
                    <a
                      *ngFor="let r of conteudoPadrinho; let em = index"
                      class="item-result"
                      (click)="setInformationPadrinho(r)"
                    >
                      <div class="item-list">
                        {{ r.nome }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 mb-3 required">
                <label class="mt-2" for="nome">CPF do padrinho</label>
                <input
                  title="cpfPadrinho"
                  formControlName="cpfPadrinho"
                  name="cpfPadrinho"
                  class="form-control"
                  id="cpfp"
                  [disabled]="true"
                  [ngClass]="{
                    'is-invalid': submitted && f.cpfPadrinho.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.cpfPadrinho.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.cpfPadrinho.errors.required">
                    Campo cpf do padrinho é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-lg-5 mb-3 required">
                <label class="mt-2" for="nome">Email do padrinho</label>
                <input
                  title="emailPadrinho"
                  formControlName="emailPadrinho"
                  name="emailPadrinho"
                  class="form-control"
                  id="emailp"
                  [ngClass]="{
                    'is-invalid': submitted && f.emailPadrinho.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.emailPadrinho.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.emailPadrinho.errors.required">
                    Campo email do padrinho é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="messageEditUserSuccess"
            class="row alert alert-success position-alert mt-4"
          >
            {{ this.messageEditUserSuccess }}
          </div>

          <div
            *ngIf="messageEditUserErro"
            class="row alert alert-danger position-alert mt-4"
          >
            {{ this.messageEditUserErro }}
          </div>

          <!-- FORM ROW -->
          <div
            *ngIf="!loadingObjWizzer"
            class="row"
            style="
              margin-left: 1px;
              justify-content: flex-end;
              margin-top: 10px;
              padding: 30px;
            "
          >
            <button class="new-collab-button" [disabled]="loadingEditUser">
              <span *ngIf="!loadingEditUser">Salvar</span>
              <span *ngIf="loadingEditUser">
                <em class="fas fa-circle mt-2-notch fa-spin mr-2"></em>
                Editando
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- ########### ACOMPANHAMENTO DE ETAPAS DO COLABORADOR ############# -->
    <div
      *ngIf="!loadingObjWizzer"
      class="col tab-pane fade"
      id="nav-profile"
      role="tabpanel"
      aria-labelledby="nav-profile-tab"
    >
      <div class="row">
        <div class="col item-card radius-to-aba">
          <p style="margin-top: 25px; font-size: 25px">Status Onboarding</p>
          <div class="row mt-2 shadow-sm item-etapa">
            <div *ngFor="let etapa of etapas" class="col {{ etapa?.style }}">
              <div class="row d-flex justify-content-center">
                <span *ngIf="!etapa?.concluido">
                  <em class="far fa-circle mt-2"></em>
                </span>
                <span *ngIf="etapa?.concluido">
                  <em class="fas fa-circle mt-2"></em>
                </span>
              </div>
              <span>{{ etapa?.descricao }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- ########## CONSULTA DE ETAPA AUTOMATICAS ########### -->
        <div class="col-6 item-card">
          <p style="margin-top: 25px; font-size: 25px">
            Etapas de Conclusão Automática
          </p>
          <p>
            Utilize o botão abaixo para verificar se o usuário terminou as
            seguintes etapas de conclusão automática: Trilha TôNaWiz e
            Assinatura dos Termos.
          </p>

          <button
            class="btn btn-refresh-etapas mb-2"
            (click)="atualizaStatusOnboarding()"
            [disabled]="loadingTermoCE || loadingTermoBen || loadingWizity"
          >
            Verificar Etapas de Conclusão Automática
          </button>
          <!-- INFORMACOS SOBRE ANDAMENTO DE TRILHA DO COLABORADOR -->
          <p *ngIf="loadingWizity">
            <em class="fas fa-spinner fa-spin"></em>
            Consultando conclusão trilha na wizity...
          </p>

          <div *ngIf="showInfoTrilha" class="">
            <p *ngIf="cursoWizityImersaoDone && !loadingWizity">
              <em class="far fa-check-circle text-success mt-2"></em> Trilha de
              Imersão na Wiz Concluída
            </p>
            <p *ngIf="cursoWizityComplianceDone && !loadingWizity">
              <em class="far fa-check-circle text-success mt-2"></em> Trilha de
              Código de Conduta Ética Wiz - Compliance Concluída
            </p>
            <p *ngIf="cursoWizityLGPDDone && !loadingWizity">
              <em class="far fa-check-circle text-success mt-2"></em> Trilha de
              Introdução à Segurança da Informação Concluída
            </p>
            <p *ngIf="!cursoWizityImersaoDone && !loadingWizity">
              <em class="far fa-clock text-warning"></em> Trilha de Imersão na
              Wiz em Andamento
            </p>
            <p *ngIf="!cursoWizityComplianceDone && !loadingWizity">
              <em class="far fa-clock text-warning"></em> Trilha de Código de
              Conduta Ética Wiz - Compliance em Andamento
            </p>
            <p *ngIf="!cursoWizityLGPDDone && !loadingWizity">
              <em class="far fa-clock text-warning"></em> Trilha de Introdução à
              Segurança da Informação em Andamento
            </p>
          </div>

          <div
            *ngIf="mensagemErroWizity"
            class="row alert alert-danger position-alert mt-2"
          >
            <img
              src="./assets/general/to-na-wiz/login//img/icons/alert.png"
              width="25"
              class="mr-2"
              alt=""
            />
            {{ this.mensagemErroWizity }}
          </div>

          <!-- INFORMACOES SOBRE ASSINATURA DE TERMO DE CONDUTA E ETICA DO COLABORADOR -->
          <p *ngIf="loadingTermoCE">
            <em class="fas fa-spinner fa-spin"></em> Consultando assinatura
            Termo de Conduta e Ética no Portal Compliance...
          </p>
          <p *ngIf="resAssinouTermoCE === true && !loadingTermoCE">
            <em class="far fa-check-circle text-success"></em> Termo de Conduta
            e Ética assinado
          </p>
          <p *ngIf="resAssinouTermoCE === false && !loadingTermoCE">
            <em class="far fa-clock text-warning"></em> Termo de Conduta e Ética
            não assinado
          </p>

          <div
            *ngIf="mensagemTermoCE"
            class="row alert alert-danger position-alert mt-2"
          >
            <img
              src="./assets/general/to-na-wiz/login//img/icons/alert.png"
              width="25"
              class="mr-2"
              alt=""
            />
            {{ this.mensagemTermoCE }}
          </div>

          <!-- INFORMACOES SOBRE ASSINATURA DE TERMO DE CONFIMACAO DE BENEFICIOS DO COLABORADOR -->
          <!-- <p *ngIf="loadingTermoBen">
            <em class="fas fa-spinner fa-spin"></em>
            Consultando assinatura Termo de Confirmação de Benefícios no Portal Compliance...
          </p>
          <p *ngIf="resAssinouTermoBen === true && !loadingTermoBen">
            <em class="far fa-check-circle text-success"></em> Termo de Confirmação de Benefícios assinado
          </p>
          <p *ngIf="resAssinouTermoBen === false && !loadingTermoBen">
            <em class="far fa-clock text-warning"></em> Termo de Confirmação de Benefícios não assinado
          </p>

          <div *ngIf="mensagemErroTermoBen" class="row alert alert-danger position-alert mt-2">
            <img src="./assets/general/to-na-wiz/login//img/icons/alert.png" width="25" class="mr-2" alt="">
            {{ this.mensagemErroTermoBen }}
          </div> -->
        </div>

        <!-- ######## ATUALIZAR ETAPAS MANUELAMENTE ################ -->
        <div class="col-6 item-card">
          <p style="margin-top: 25px; font-size: 25px">
            Atualizar Etapa Manualmente
          </p>
          <p>
            Selecione qual etapa/ação deseja, para fazer o ajuste. Obs: Essa
            modificação será visível para o colaborador assim que o mesmo
            acessar o Onboarding.
          </p>
          <div class="row">
            <div class="col-6">
              <span class="mt-2" for="nome">Etapa: </span>
              <select
                title="etapa"
                class="form-control"
                name=""
                id=""
                [(ngModel)]="etapaSelecionada"
              >
                <option *ngFor="let etapa of etapas; index as i" [value]="i">
                  {{ etapa?.descricao }}
                </option>
              </select>
            </div>

            <div class="col-6">
              <span class="mt-2" for="nome">Ação: </span>
              <select
                title="statusEtapa"
                class="form-control"
                name=""
                id=""
                [(ngModel)]="statusEtapaSelecionada"
              >
                <option [ngValue]="true">Concluir Etapa</option>
                <option [ngValue]="false">Desfazer Etapa</option>
              </select>
            </div>
          </div>

          <button
            class="btn-refresh-etapas mt-2 mb-2"
            [disabled]="loadingEtapaAtt"
            (click)="atualizarEtapa()"
          >
            <span *ngIf="!loadingEtapaAtt">Atualizar</span>
            <span *ngIf="loadingEtapaAtt">
              Atualizando
              <em class="fas fa-spinner fa-spin"></em>
            </span>
          </button>

          <div
            *ngIf="mensagemEtapaAtt"
            class="row alert alert-warning position-alert mt-3"
          >
            {{ this.mensagemEtapaAtt }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="col tab-pane fade"
      id="nav-contact"
      role="tabpanel"
      aria-labelledby="nav-contact-tab"
    >
      <div class="row">
        <div class="col-12 item-card radius-to-aba">
          <p style="margin-top: 25px; margin-bottom: 25px; font-size: 25px">
            Informação do usuário
          </p>
          <p>{{ objWizzer.nome }}</p>

          <table>
            <tr>
              <th id="nameSystem">Sistema</th>
              <th id="statusUserInSystem">Possui cadastro?</th>
              <th id="isActive">Ativo</th>
              <th id="emailAD">Email</th>
              <th id="obs">Observação</th>
            </tr>

            <tr *ngIf="loadingUserAd">
              <td>AD</td>
              <td>
                <em class="fas fa-circle-notch fa-spin"></em>
              </td>
              <td>
                <em class="fas fa-circle-notch fa-spin"></em>
              </td>
              <td>
                <em class="fas fa-circle-notch fa-spin"></em>
              </td>
              <td>
                <em class="fas fa-circle-notch fa-spin"></em>
              </td>
            </tr>

            <tr *ngIf="!loadingUserAd">
              <td>AD</td>
              <!-- ######### If userAd non exists ######## -->
              <td *ngIf="!userAd">
                <em class="fas fa-exclamation-circle color-danger"></em>
              </td>
              <td *ngIf="!userAd">
                <em class="fas fa-exclamation-circle color-danger"></em>
              </td>
              <td *ngIf="!userAd">
                <em class="fas fa-exclamation-circle color-danger"></em>
              </td>

              <!-- ######### If userAd exists ######## -->
              <td *ngIf="userAd">
                <em class="fas fa-check color-success"></em>
              </td>

              <td *ngIf="userAd && userAd.Enabled">
                <em class="fas fa-check color-success"></em>
              </td>

              <td *ngIf="userAd && !userAd.Enabled">
                <em class="fas fa-exclamation-circle color-danger"></em>
              </td>

              <td *ngIf="userAd && userAd.Email">
                {{ userAd.Email }}
              </td>

              <!-- ######### obs ######## -->
              <td *ngIf="!userAd">
                Usuário não existe no AD. Pode ser que ele ainda não tenha
                acessado o TNW.
              </td>

              <td *ngIf="userAd && !userAd.Email">
                <em class="fas fa-exclamation-circle color-danger"></em>
              </td>

              <td *ngIf="userAd && userAd.Email && !userAd.Enabled">
                Usuário existe no AD porém não está habilitado. Pocure o SU para
                fazê-lo.
              </td>

              <td *ngIf="userAd && !userAd.Email">
                Usuário existe no AD porém não possui email corporativo
                associado. Procure o SU para fazê-lo.
              </td>
            </tr>

            <tr *ngIf="loadingUserSSO">
              <td>SSO</td>
              <td>
                <em class="fas fa-circle-notch fa-spin"></em>
              </td>

              <td>
                <em class="fas fa-circle-notch fa-spin"></em>
              </td>

              <td>
                <em class="fas fa-circle-notch fa-spin"></em>
              </td>

              <td>
                <em class="fas fa-circle-notch fa-spin"></em>
              </td>
            </tr>

            <!-- ########### user non exists sso ########### -->
            <td *ngIf="!loadingUserSSO && !userSSO">
              <em class="fas fa-exclamation-circle color-danger"></em>
            </td>

            <td *ngIf="!loadingUserSSO && !userSSO">
              <em class="fas fa-exclamation-circle color-danger"></em>
            </td>

            <td *ngIf="!loadingUserSSO && !userSSO">
              <em class="fas fa-exclamation-circle color-danger"></em>
            </td>

            <!-- ########### user exists sso ########### -->
            <!-- <td *ngIf="!loadingUserSSO && userSSO">
                <em class="fas fa-check color-success"></em>
              </td>

              <td *ngIf="!loadingUserSSO && userSSO">
                <em class="fas fa-check color-success"></em>
              </td>

              <td *ngIf="!loadingUserSSO && userSSO.email">
                {{ userSSO.email }}
              </td>

              <td *ngIf="!loadingUserSSO && !userSSO">
                Usuário não existe no SSO. Pode ser que ele ainda não tenha acessado o TNW.
              </td>

              <td *ngIf="!loadingUserSSO && userSSO && !loadingUserAd && !userAd">
                Usuário existe no SSO, porém não existe no AD. Pode ser que seja um colaborador vindo uma empresa parceira,
                procure a Squad SX!
              </td>
            </tr> -->
          </table>
        </div>

        <div class="col-12 item-card mb-4">
          <div
            class="row"
            style="margin-top: 20px; padding: 10px; font-size: 25px"
          >
            <p style="margin-bottom: 15px">
              Recomendação de email
              <span *ngIf="loadingEmailRecomendado"
                ><em class="fas fa-circle-notch fa-spin"></em
              ></span>
            </p>
          </div>
          <p>
            Opções de email corporativo disponivel
          </p>
          <div
            *ngIf="naoTemRecomendacaoEmail && !errorRecomendacaoEmail"
            class="mb-4"
          >
            <em class="color-warning fas fa-exclamation-triangle mr-2"></em>
            <label
              ><strong>Atenção!</strong> Não há email disponível para o
              colaborador. Procure o <strong>SU</strong>.</label
            >
          </div>

          <div *ngIf="errorRecomendacaoEmail" class="mb-4">
            <em class="color-warning fas fa-exclamation-triangle mr-2"></em>
            <label
              ><strong>Atenção!</strong> Houve um erro ao utilizar o serviço de
              sugestão de email. Procure o <strong>SU</strong>.</label
            >
          </div>

          <table *ngIf="!loadingEmailRecomendado && !naoTemRecomendacaoEmail">
            <tr>
              <th id="displayName">Nome que será mostrado</th>
              <th id="emailDisponivel">Email</th>
              <th id="accountDisponivel">Account</th>
            </tr>
            <tr *ngFor="let item of emailRecomendado">
              <td>{{ item.displayName }}</td>
              <td class="recomendacao-email-color">{{ item.email }}</td>
              <td>{{ item.account }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
