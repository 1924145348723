import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigurationApiService } from './api/configuration-api.service';
import { ConfigurationCacheService } from './cache/configuration-cache.service';
import { AppConfiguration } from './configuration';
import defaultConfig from './default-configuration';
import { ConfigurationInMemoryService } from './in-memory/configuration-in-memory.service';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  constructor(
    private api: ConfigurationApiService,
    private cache: ConfigurationCacheService,
    private inMemory: ConfigurationInMemoryService
  ) {}

  tenantId = '';

  private isCacheEnabled = true;

  enableCache(): void {
    this.isCacheEnabled = true;
  }

  disableCache(): void {
    this.isCacheEnabled = false;
  }

  getConfig(token?: any): Observable<AppConfiguration> {
    const inMemoryConfiguration = this.getFromMemory();
    if (inMemoryConfiguration) {
      return of(inMemoryConfiguration);
    }
    const cacheResponse = this.cache.getConfiguration();
    if (cacheResponse) {
      this.getFromApi(true, token);
    }
    return cacheResponse
      ? this.getFromCache(cacheResponse)
      : this.getFromApi(true, token);
  }

  getConfigSync(): AppConfiguration | undefined {
    return this.getFromMemory();
  }

  private getFromApi(isCacheEnabled = false, token: string): Observable<AppConfiguration> {
    return this.api
      .fetchConfig(token)
      ?.pipe(catchError(() => of(defaultConfig)))
      .pipe(
        tap((val: any) => {
          this.inMemory.saveConfiguration(val);
          if (isCacheEnabled) {
            this.cache.saveConfiguration(val);
          }
        })
      );
  }

  getFromCache(
    cacheResponse: AppConfiguration
  ): Observable<AppConfiguration> {
    return of(cacheResponse).pipe(
      tap((val: any) => {
        this.inMemory.saveConfiguration(val);
      })
    );
  }

  private getFromMemory(): AppConfiguration | undefined {
    return this.inMemory.getConfiguration();
  }
}
