import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getDataStorage } from 'src/app/shared/utils/token.storage';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private _tenantId = new BehaviorSubject<string>('');
  readonly tenantId$ = this._tenantId.asObservable();

  constructor() {
    const tenantStorage = getDataStorage('tenant', 'w-theme')
    if (tenantStorage) {
      this.setTenant(tenantStorage);
    }
  }

  //// Tenant settings
  getTenant(): string {
    try {
      return this._tenantId.getValue();
    } catch (error) {
      return '';
    }
  }


  setTenant(tenantId: string): void {
    // Url personalizada para testes em ambiente de homologação.
    const tenantInterceptor = tenantId
    this._tenantId.next(tenantInterceptor);
  }

}
