import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageOnboardingComponent } from './pages/page-onboarding/page-onboarding.component';
import { PagetourComponent } from './pages/page-tour/page-tour.component';
import { AuthGuard } from '@wizsolucoes/ngx-wiz-sso';
import { PageAdminNewColabComponent } from './pages/page-admin-new-colab/page-admin-new-colab.component';
import { PageAdminHomeComponent } from './pages/page-admin-home/page-admin-home.component';
import { PageAdminEditColabComponent } from './pages/page-admin-edit-colab/page-admin-edit-colab.component';
import { PageAdminNewOperadorComponent } from './pages/page-admin-new-operador/page-admin-new-operador.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { LandingPagePesquisaExV2Component } from './pages/landing-page-pesquisa-ex-v2/landing-page-pesquisa-ex-v2.component';
import { NgxMicrosoftBotFrameworkModule } from 'ngx-microsoft-bot-framework-15';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageAuthenticationComponent } from './pages/page-authentication/page-authentication.component';
import { PageAdminButtonsComponent } from './pages/page-admin-buttons/page-admin-buttons.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: PageLoginComponent,
  },
  {
    path: '',
    component: PageLoginComponent,
  },
  {
    path: 'welcome',
    component: PageAuthenticationComponent,
  },
  {
    canActivate: [AuthGuard],
    path: 'onboarding',
    component: PageOnboardingComponent,
  },
  {
    path: 'tour',
    component: PagetourComponent,
  },
  {
    canActivate: [AuthGuard],
    path: 'sdadsasd',
    component: PageAdminButtonsComponent,
  },
  {
    canActivate: [AuthGuard],
    path: 'admin',
    component: PageAdminHomeComponent,
  },
  {
    canActivate: [AuthGuard],
    path: 'new-operator',
    component: PageAdminNewOperadorComponent,
  },
  {
    canActivate: [AuthGuard],
    path: 'new-colab',
    component: PageAdminNewColabComponent,
  },
  {
    canActivate: [AuthGuard],
    path: 'edit-colab/:cpf',
    component: PageAdminEditColabComponent,
  },
  {
    path: 'new-password/:cpf',
    component: NewPasswordComponent,
  },
  {
    path: 'pesquisa-ex',
    component: LandingPagePesquisaExV2Component,
  },
  {
    path: 'pesquisa-ex/:value',
    component: LandingPagePesquisaExV2Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), NgxMicrosoftBotFrameworkModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
