import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PublicAuthService } from '../../services/public-auth.service';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { throwError } from 'rxjs';
import { ConfigurationInMemoryService } from 'src/app/services/configuration/in-memory/configuration-in-memory.service';
import { AppConfiguration } from 'src/app/services/configuration/configuration';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  // formulario utilizado para extracao de dados para efetuar login ou cadastrar usuario
  public resetPasswordForm = this.fb.group({
    cpf: [''],
    senha: [''],
    confirmaNovaSenha: [''],
    email: [''],
    codigo: [''],
  });

  public codeForm = this.fb.group({
    input1: ['', [Validators.required]],
    input2: ['', [Validators.required]],
    input3: ['', [Validators.required]],
    input4: ['', [Validators.required]],
    input5: ['', [Validators.required]],
    input6: ['', [Validators.required]],
  });

  public requisitePassword = {
    // objeto para a validacao de atributos respectivos a requisito de senha
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumeric: false,
    hasMinCarac: false,
    noContainsUsername: true,
    passwordCoincides: false,
  };
  public objToNaWiz: any;
  public isValidPassword: boolean;
  public mensagemErro: string;
  public loadingResetPassword: boolean;
  public isReqError: boolean;
  public isSuccessUpdatedPassword: boolean;
  public showInputsResetPassword = true;
  public showCodePage = false;

  public codeController: any;
  public invalidCode = false;

  public hide = true;
  public hideConf = true;
  public cpfUser: string;
  public loadingReset: boolean;
  private sub: any;
  public inputNovaSenha: string;
  public configuration: AppConfiguration | undefined;

  constructor(
    private readonly fb: UntypedFormBuilder,
    public readonly auth: PublicAuthService,
    private readonly route: ActivatedRoute,
    private readonly adminService: AdminService,
    private inMemoryService: ConfigurationInMemoryService
  ) {}

  ngOnInit() {
    this.inMemoryService.configurationCurrent$.subscribe((res) => {
      this.configuration = res;
      console.log('this.configuration newpassword', this.configuration)
    });
    const tempString = localStorage.getItem('wizer');
    this.objToNaWiz = JSON.parse(tempString!);
    // extraindo cpf do usuario que ira trocar a senha
    this.sub = this.route.params.subscribe((params) => {
      const key = 'cpf';
      this.cpfUser = params[key];
    });

    this.adminService.getUserByCpf(this.cpfUser).subscribe(
      (data) => {
        this.resetPasswordForm.patchValue({
          cpf: this.cpfUser,
          email: this.objToNaWiz.emailCorporativo,
        });
      },
      (err) => {
        // TODO: ADD ERROR
        return throwError(err);
      }
    );
  }

  public checkCode(data: any): void {
    this.showCodePage = data.showCodePage;
    this.resetPasswordForm.value.codigo = data.confirmationCode;
    this.showInputsResetPassword = true;
  }

  public testPasswordEquality(event: any) {
    this.requisitePassword.passwordCoincides =
      this.resetPasswordForm.value.senha ===
      this.resetPasswordForm.value.confirmaNovaSenha;
  }

  public testaSenha(inputNovaSenha: any) {
    // funcao para testar forca de senha de acordo com os requisitos
    const regexLowerCase = /^(?=.*[a-z])/;
    const regexUpperCase = /^(?=.*[A-Z])/;
    const regexNumerics = /^(?=.*\d)/;
    const regexMinCarac = /^(?=.[0-9a-zA-Z$*&@#]{7,}$)/;

    this.requisitePassword.hasLowerCase = regexLowerCase.test(inputNovaSenha);
    this.requisitePassword.hasUpperCase = regexUpperCase.test(inputNovaSenha);
    this.requisitePassword.hasNumeric = regexNumerics.test(inputNovaSenha);
    this.requisitePassword.hasMinCarac = regexMinCarac.test(inputNovaSenha);
    this.requisitePassword.passwordCoincides =
      this.resetPasswordForm.value.senha ===
      this.resetPasswordForm.value.confirmaNovaSenha;

    // rule to not contain the user’s account name or parts of the user’s full name that exceed two consecutive characters
    if (inputNovaSenha.length > 2) {
      const partsOfThreeLetters = this.objToNaWiz?.nome
        .match(/.{3}/g)
        .concat(
          this.objToNaWiz?.nome.substr(1).match(/.{3}/g),
          this.objToNaWiz?.nome.substr(2).match(/.{3}/g)
        );
      this.requisitePassword.noContainsUsername = !partsOfThreeLetters.some(
        (x: string) =>
          inputNovaSenha.toLowerCase().includes(
            x
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );
    }
  }

  public joinCode(): string {
    const digits = this.codeForm.value;

    return (
      digits.input1 +
      digits.input2 +
      digits.input3 +
      digits.input4 +
      digits.input5 +
      digits.input6
    );
  }

  public createNewPassword() {
    if (!this.codeForm.valid) {
      alert('Preencha o campo código!');
      return;
    } else {
      this.resetPasswordForm.value.codigo = this.joinCode();
    }

    this.loadingReset = true;

    if (this.confirmPassword()) {
      // se a senha atende os requisitos entao cria conta no ad

      this.resetPassword();
    }
  }

  public confirmPassword() {
    // faz validacao da nova senha, nos requisitos e forca de senha.
    const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.[0-9a-zA-Z$*&@#]{7,}$)/;
    this.mensagemErro = '';
    this.isValidPassword = regexp.test(this.resetPasswordForm.value.senha);

    let noContainsUsername = true;
    const partsOfThreeLetters = this.objToNaWiz.nome
      .match(/.{3}/g)
      .concat(
        this.objToNaWiz.nome.substr(1).match(/.{3}/g),
        this.objToNaWiz.nome.substr(2).match(/.{3}/g)
      );
    noContainsUsername = !partsOfThreeLetters.some((x: string) =>
      this.resetPasswordForm.value.senha.toLowerCase().includes(x.toLowerCase())
    );

    if (
      this.resetPasswordForm.value.senha !==
      this.resetPasswordForm.value.confirmaNovaSenha
    ) {
      // verifica se senhas sao iguais
      this.mensagemErro = 'Atenção! As senhas não coincidem';
      return false;
    } else if (!this.isValidPassword || !noContainsUsername) {
      this.mensagemErro = 'Senha não atende os requisitos listados';
      return false;
    } else {
      return true;
    }
  }

  public resetPassword() {
    this.loadingResetPassword = true;

    this.auth
      .resetPassword(
        this.resetPasswordForm.value,
        this.resetPasswordForm.value.codigo
      )
      .subscribe(
        (res) => {
          // TODO: message correct
          this.loadingReset = false;
          this.isSuccessUpdatedPassword = true;
          this.loadingResetPassword = false;
        },
        (err) => {
          this.resetShowInputs();
          this.isSuccessUpdatedPassword = false;
          // TODO: colocar erro
          this.invalidCode = true;
          // this.isReqError = true;s
        }
      );
  }

  public resetShowInputs() {
    this.isValidPassword = false;
    this.mensagemErro = '';
    this.loadingResetPassword = false;
    this.showInputsResetPassword = true;
    this.loadingReset = false;
  }

  public passwordCheck() {
    const requisites = this.requisitePassword;

    const requisitesLowUpper =
      requisites.hasLowerCase && requisites.hasUpperCase;
    const requisitesMinNumCarac =
      requisites.hasMinCarac &&
      requisites.hasNumeric &&
      requisites.noContainsUsername;

    return (
      requisitesLowUpper &&
      requisitesMinNumCarac &&
      this.resetPasswordForm.value.senha ===
        this.resetPasswordForm.value.confirmaNovaSenha
    );
  }

  public canResetPassword(): boolean {
    return this.codeForm.valid && this.passwordCheck();
  }
}
