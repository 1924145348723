import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { debounceTime, switchMap } from 'rxjs/operators';
import { EmailRecomendado } from '../../models/email-recomendado.model';
import { PublicAuthService } from '../../services/public-auth.service';
import { ProfileService } from '../../services/profile.service';
import { SSOConectorService } from '@wizsolucoes/ngx-wiz-sso';
import { ValidateCPFService } from 'src/app/core/services/validate-cpf.service';
import { OnboardingServiceService } from 'src/app/services/onboarding-service.service';
import { CaseModel } from 'src/app/models/case-model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-page-admin-new-colab',
  templateUrl: './page-admin-new-colab.component.html',
  styleUrls: ['./page-admin-new-colab.component.scss'],
})
export class PageAdminNewColabComponent implements OnInit {
  public submitted: boolean; // variavel que controle se formulario foi submetido
  public conteudoPadrinho: any[]; // variavel que guarda o conteudo retornado do padrinho
  public conteudoGestor: any[]; // variavel que guarda o conteudo retornado do gestor
  public emailRecomendado: EmailRecomendado; // lista com os email sugeridos
  public mensagemErro: string; // variavel utilizada para listagem de erros
  public messageCreateUserErro: string; // variavel utlizada para retornar erro para usuario, caso haja erro na criação de usuario
  public messageCreateUserSuccess: string; // variavel utlizada para retornar informação de cadastro com sucesso para o usuario
  public cpfValido: boolean; // variavel para fazer controle do digito verificador do cpf
  public loadingPadrinho: boolean;
  public loadingGestor: boolean;
  public loadingCaseData = false;
  public loadingEmailRecomendado: boolean;
  public loadingSavingUser: boolean;
  public loadingSearchAD: boolean; // variavel de controle, na pesquisa de usuario no AD
  public mensagemErroPadrinho: string; // variavel de controle pra apresentar mensagem de erro na pesquisa de gestor.
  public mensagemErroGestor: string; // variavel de controle pra apresentar mensagem de erro na pesquisa de padrinho.
  public mensagemErroCaso: string; // variavel de controle pra apresentar mensagem de erro na pesquisa de padrinho.
  public messageSearchADSuccess: string; // mensagem que sera apresentada ao usuario no retorno da busca do AD.
  public messageSearchADErro: string; // mensagem que p mostrar ao usuario no retorno da busca do AD de quando usuario nao existe ou houve erro.

  public userProfile = this.profile.checkUserProfile();

  public status = ['Placement', 'Inativo', 'Ativo', 'CANCELADO'];

  public estados = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MS',
    'MT',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  // variaveis de controle utilizada para verificar mudança de valor no input
  public nomeColabControl = new UntypedFormControl('', Validators.required);
  public nomePadrinhoControl = new UntypedFormControl('', Validators.required);
  public nomeGestorControl = new UntypedFormControl('', Validators.required);

  // Todos os campos utilizados no formulario e seus respectivos validators
  public createUserForm = this.fb.group({
    matricula: [''],
    ativo: [false, Validators.required],
    status: ['Placement', Validators.required],
    nome: this.nomeColabControl,
    cpf: ['', Validators.required],
    datacontratacao: ['', Validators.required],
    dataintegracao: [null],
    emailcorporativo: [null],
    emailpessoal: ['', Validators.required],
    telefone: [''],
    celular: [''],
    cargo: ['', Validators.required],
    unidadedenegocio: ['rede', Validators.required],
    localdetrabalho: [''],
    // lotação do ASVEN
    agencia: [''],
    bairro: [''],
    cidade: ['', Validators.required],
    uf: ['DF', Validators.required],

    // dados padrinho
    cpfpadrinho: ['', Validators.required],
    emailpadrinho: ['', Validators.required],
    nomepadrinho: this.nomePadrinhoControl,

    // dados gestor
    emailgestor: ['', Validators.required],
    cpfgestor: ['', Validators.required],
    nomegestor: this.nomeGestorControl,

    grupohierarquico: ['', Validators.required],
    centrodecusto: ['', Validators.required],
  });

  public unidadeDeNegocio: any[];
  public tokenObj: Object;
  public token: string;

  constructor(
    private datePipe: DatePipe,
    private readonly fb: UntypedFormBuilder,
    private readonly adminService: AdminService,
    private readonly router: Router,
    public readonly auth: PublicAuthService,
    public profile: ProfileService,
    private readonly sso: SSOConectorService,
    public validateCPFService: ValidateCPFService,
    private readonly onboardingService: OnboardingServiceService
  ) {}

  public ngOnInit() {
    this.getBusinessUnits();
    this.searchFuncEquipePadrinho();
    this.searchFuncEquipeGestor();
    this.getToken();

    if (this.userProfile === 'colaborador') {
      this.sso.logOut();
      this.router.navigate(['login']);
    }
  }

  // facilitador para extrair informacao dos valores do formulario
  public get f() {
    return this.createUserForm.controls;
  }

  public getBusinessUnits(): void {
    this.onboardingService.getBusinessUnitList().subscribe(
      (res) => {
        this.unidadeDeNegocio = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public onSubmit() {
    this.submitted = true;
    // Se o formulario eh invalido ele para aqui
    if (this.createUserForm.invalid) {
      alert('Formulário Inválido!');
      return;
    }

    this.loadingSavingUser = true;

    // Se o formulario eh valido, ele chama o metodo para criar usuario
    this.adminService.createUser(this.createUserForm.value).subscribe(
      (data) => {
        this.messageCreateUserSuccess = 'Usuário criado com sucesso.';
        setTimeout(() => {
          this.loadingSavingUser = false;
          this.router.navigate(['admin']);
        }, 3000);
      },
      (err) => {
        this.loadingSavingUser = false;
        this.messageCreateUserErro = 'Houve um erro na criação do usuário.';
      }
    );
  }

  public getToken() {
    this.tokenObj = this.adminService.token().subscribe(
      (token) => {
        this.tokenObj = token;
        this.token = token.access_token;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // metodo para pesqusiar padrinho por nome
  public searchFuncEquipePadrinho() {
    this.nomePadrinhoControl.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((nome) => {
          this.loadingPadrinho = true;
          if (nome.length > 3) {
            this.mensagemErroPadrinho = '';
            return this.adminService.searchFuncAll(nome, this.token);
          } else {
            this.loadingPadrinho = false;
            return [];
          }
        })
      )
      .subscribe(
        (res) => {
          this.conteudoPadrinho = res;
          this.loadingPadrinho = false;
        },
        (err) => {
          this.loadingPadrinho = false;
          this.mensagemErroPadrinho =
            'Método de consulta de padrinho não está funcionando.';
        }
      );
  }

  // insere informacao complementar de acordo com o padrinho que foi pesquisado.
  public setInformationPadrinho(selectedPadrinho: any) {
    this.createUserForm.patchValue({
      nomepadrinho: selectedPadrinho.nome,
      emailpadrinho: selectedPadrinho.email,
      cpfpadrinho: selectedPadrinho.cpf,
    });
  }


  // metodo para pesqusiar gestor por nome
  public searchFuncEquipeGestor() {
    this.nomeGestorControl.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((nome) => {
          this.loadingGestor = true;
          // if necessario pois endpoint so pesquisa com string de tamanho 3
          if (nome.length > 3) {
            this.mensagemErroGestor = '';
            return this.adminService.searchFuncAll(nome, this.token);
          } else {
            this.loadingGestor = false;
            return [];
          }
        })
      )
      .subscribe(
        (res) => {
          this.conteudoGestor = res;
          this.loadingGestor = false;
        },
        (err) => {
          this.loadingGestor = false;
          this.mensagemErroGestor =
            'Método de consulta de gestor não está funcionando.';
        }
      );
  }

  // insere informacao complementar de acordo com o gestor que foi pesquisado.
  public setInformationGestor(selectedGestor: any) {
    this.createUserForm.patchValue({
      nomegestor: selectedGestor.nome,
      emailgestor: selectedGestor.email,
      cpfgestor: selectedGestor.cpf,
    });
  }

  //insere informações no novo colaborador conforme caso pesquisado
  public setInformationNewWizzer(caseData: any) {
    this.createUserForm.patchValue({
      status: 'Placement',
      nome: caseData.nome,
      cpf: caseData.cpf,
      datacontratacao: this.datePipe.transform(
        caseData.dataContratacao,
        'yyyy-MM-dd'
      ),
      dataintegracao: caseData.dataIntegracao,
      emailpessoal: caseData.emailPessoal,
      telefone: caseData.telefone,
      celular: caseData.celular,
      cargo: caseData.cargo,
      unidadedenegocio: caseData.unidadeDeNegocio,
      localdetrabalho: caseData.tipoModeloDeTrabalho,
      // lotação do ASVEN
      bairro: caseData.bairro,
      cidade: caseData.cidade,
      uf: caseData.uf,

      // dados padrinho
      cpfpadrinho: caseData.cpfPadrinho,
      emailpadrinho: caseData.emailPadrinho,
      nomepadrinho: caseData.nomePadrinho,

      // dados gestor
      emailgestor: caseData.emailGestor,
      cpfgestor: caseData.cpfGestor,
      nomegestor: caseData.nomeGestor,

      grupohierarquico: caseData.grupoHierarquico,
      centrodecusto: caseData.centroDeCusto,
    });
  }

  // Função que busca o email que está cadastro no AD para o usuario que está sendo editado.
  public searchEmailAD() {
    this.loadingSearchAD = true;
    this.messageSearchADSuccess = '';
    this.messageSearchADErro = '';

    if (this.createUserForm.value.cpf.length < 11) {
      this.loadingSearchAD = false;
      this.messageSearchADErro = 'É necessário preenchimento do CPF';
      setTimeout(
        () => (this.messageSearchADErro = 'É necessário preenchimento do CPF'),
        8000
      );
      return;
    }

    this.adminService
      .searchUserADByCpf(this.createUserForm.value.cpf)
      .subscribe(
        (res: any) => {
          const fieldRes = 'Usuario';
          const userAD = res[fieldRes];
          this.loadingSearchAD = false;
          if (userAD.Email !== (null || undefined || '')) {
            this.createUserForm.patchValue({
              emailcorporativo: userAD.Email,
            });

            this.messageSearchADSuccess = 'Campo atualizado com o AD';
            setTimeout(
              () => (this.messageSearchADSuccess = 'Campo atualizado com o AD'),
              8000
            );
          }
        },
        (err) => {
          this.loadingSearchAD = false;
          this.messageSearchADErro =
            'Erro na busca de usuário no AD, ou usuário não existe';
          setTimeout(
            () =>
              (this.messageSearchADErro =
                'Erro na busca de usuário no AD, ou usuário não existe'),
            8000
          );
        }
      );
  }

  // Função para validar cpf
  public verficadorCpf(inputCPF: string) {
    if (inputCPF.length > 13) {
      this.mensagemErro = '';

      inputCPF = inputCPF.split('.').join('');
      inputCPF = inputCPF.split('-').join('');

      this.cpfValido = this.validateCPFService.validate(inputCPF);

      if (!this.cpfValido) {
        this.mensagemErro = 'CPF inválido.';
      }
    }
  }
}
