import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepInfo } from 'src/app/models/step-info-model';

@Component({
  selector: 'app-admission-steps-redesign',
  templateUrl: './admission-steps-redesign.component.html',
  styleUrls: ['./admission-steps-redesign.component.scss'],
})
export class AdmissionStepsRedesignComponent implements OnInit {
  @Input() completedSteps: StepInfo[];
  @Input() objToNaWiz: any;
  @Input() completePercentage: number;
  @Output() selectedStep = new EventEmitter<number>();

  public mobileVersion: boolean;



  constructor(public breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.checkMobile();
  }

  public checkMobile(): void {
    this.breakpointObserver
      .observe(['(min-width: 991px)'])
      .subscribe((state: BreakpointState) => {
        this.mobileVersion = !state.matches;
      });
  }

  public sendStep(position: number): void {
    this.selectedStep.emit(position);
  }
}
