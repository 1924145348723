<div class="content">
  <h1>Perfil administrador</h1>
  <div class="table">
    <div class="table-header">
      <div class="table-title">
      <p>Acompanhar novos colaboradores</p>
      </div>
      <div class="search">
        <form [formGroup]="searchForm">
          <div class="input-group">
            <input
              #search
              type="text"
              formControlName="inputSearch"
              class="form-control"
              placeholder="Digite o {{ searchBy }}"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                (click)="buttonSearch(search.value)"
                data-toggle="tooltip"
                data-placement="top"
                title="Pesquisar colaborador"
              >
                <em class="fa fa-search"></em>
              </button>
            </div>
          </div>
        </form>
      </div>


      <div class="icons">
        <div class="download-icon">
        <span
          class="material-symbols-outlined"
          (click)="downloadReport()"
        >
          download
        </span>
      </div>
      <div class="add-icon">
        <span class="material-symbols-outlined" [routerLink]="['/new-colab']">
          person_add
        </span>
      </div>
      <div class="admin-icon">
        <span
          class="material-symbols-outlined"
          [routerLink]="['/new-operator']"
        >
          admin_panel_settings
        </span>
      </div>
      </div>
    </div>

    <div class="table-content">
      <div class="col-12">
        <div class="row font-weight-bold font-info">
          <div class="col-12 col-sm-5 col-xl-3 d-flex ">
            <p>Nome</p>
          </div>
          <div
            class="col-2 col-sm-2 col-xl-1 d-flex justify-content-center dataHide sortStyle data-contratacao"
            (click)="sortDataContratacaoUsers('dataContratacao')"
          >
            <p>Data Contratação1</p>
            <em
              class="ml-1 mt-1 fas fa-sort"
              data-toggle="tooltip"
              data-placement="top"
              title="Crescente/Descrescente"
            ></em>
          </div>
          <div
            class="col-2 col-sm-2 col-xl-5 d-flex justify-content-center sizeTimeline statusHide"
          >
            <p>Status</p>
          </div>
        </div>

        <div
          class="custom-scroll search-results overflow-x-hidden;"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
          [scrollWindow]="false"
        >
          <div class="row shadow-sm info result-item" *ngFor="let user of objData">
            <div
              class="col-12 col-sm-5 col-xl-3 infoColab"
              (click)="editUser(user)"
            >
              <span class="user-name">{{ user.nome }}</span>

              <h6 class="infoEmailColab">{{ user.emailCorporativo }}</h6>
            </div>
            <div
              class="col-2 col-sm-2 col-xl-1 d-flex justify-content-center dataHide data-contratacao"
            >
              <p>{{ user.dataContratacao | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="col-4 col-sm-4 col-xl-7 mt-1 sizeTimeline statusHide">
              <div>Etapas Concluídas: {{ getCompletedSteps(user.etapas) }}</div>
            </div>
          </div>
          <span
            *ngIf="loadingUsers"
            class="d-flex justify-content-center icon-spinner fa-3x"
            ><em class="fas fa-spinner fa-spin"></em
          ></span>

          <div
            *ngIf="!loadingUsers && !resultadoVazio"
            class="row d-flex justify-content-end titleUp font-weight-light ml-2 mr-2 mt-4"
          >
            <h6>Fim dos resultados.</h6>
          </div>

          <div
            *ngIf="resultadoVazio"
            class="row shadow d-flex justify-content-center titleUp align-items-center ml-1 mr-1 mt-4"
          >
            <h5>Não há resultados para o {{ searchBy }} inserido.</h5>
            <img
              class="img-fluid"
              width="100vw"
              src="./assets/general/to-na-wiz/login/img/icons/izzy_triste.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


