import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PesquisaExService } from '../services/pesquisa-ex.service';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent implements OnInit {
  public formAnswer = this.fb.group({
    rangeAnswer: [null, Validators.required],
  });

  public formRequest = this.fb.group({
    data: {
      id: 0,
      idPesquisa: 4,
      idUser: 0,
      data: '',
      conteudoJson: {},
    },
    cpf: null,
  });

  public controlContent: boolean;
  public hide: boolean;
  public loadingSubmitAnswer: boolean;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly pesquisaExService: PesquisaExService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.hide = true;
    this.setInfoUserInForm();
  }

  public getQuestToDo(cpf: string): void {
    this.pesquisaExService.getQuestToDoByCpf(cpf).subscribe(
      (res) => {
        // verificação utilizada pra saber se há a pesquisa x pro usuário
        try {
          if (res.find((x: { id: number }) => x.id === 4)) {
            this.hide = false;
          }
        } catch (error) {
          console.log(error);
        }
      },
      (err) => {
        this.hide = true;
        console.error('err: ', err);
      }
    );
  }

  public redirectToLandingPage(): void {
    this.router.navigateByUrl(
      `pesquisa-ex/${this.formAnswer.value.rangeAnswer}`
    );
  }

  public setInfoUserInForm(): void {
    try {
      const wizzerLoggedString = localStorage.getItem('wizer') as string;

      const wizzerLogged = JSON.parse(wizzerLoggedString);
      const todayDate = new Date();
      this.formRequest.value.data.data = todayDate.toISOString();
      this.formRequest.patchValue({
        cpf: wizzerLogged.cpf,
      });
      this.getQuestToDo(wizzerLogged.cpf);
    } catch (error) {
      console.log('error get info user local storage');
    }
  }

  public sendAnswer(): void {
    this.formRequest.value.data.conteudoJson = this.formAnswer.value;
    this.formRequest.value.data.conteudoJson = JSON.stringify(
      this.formAnswer.value
    );
    this.loadingSubmitAnswer = true;
    this.pesquisaExService.sendAnswer(this.formRequest.value).subscribe(
      (res) => {
        this.loadingSubmitAnswer = false;
        this.controlContent = !this.controlContent;
      },
      (err) => {
        console.error('err: ', err);
        this.loadingSubmitAnswer = false;
        this.controlContent = !this.controlContent;
      }
    );
  }

  public close(): void {
    this.hide = true;
  }
}
