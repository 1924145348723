<div class="box-range-input-emoji">
  <span class="title" *ngIf="title">
    {{ title }}
  </span>

  <div class="row-inputs">
    <input
      title="id1"
      [(ngModel)]="input"
      type="radio"
      [id]="id + '1'"
      [name]="id + '1'"
      [value]="1"
      (ngModelChange)="onChange($event)"
    />
    <label class="" [for]="id + '1'">
      <img
        src="./assets/general/to-na-wiz/pesquisa-ex-v2/anguish-emoji.svg"
        alt="emoji triste"
      />
      <span>Péssima</span>
    </label>

    <input
      title="id2"
      [(ngModel)]="input"
      type="radio"
      [id]="id + '2'"
      [name]="id + '2'"
      [value]="2"
      (ngModelChange)="onChange($event)"
    />
    <label class="" [for]="id + '2'">
      <img
        src="./assets/general/to-na-wiz/pesquisa-ex-v2/sad-emoji.svg"
        alt="emoji triste"
      />
      <span>Ruim</span>
    </label>

    <input
      title="id3"
      [(ngModel)]="input"
      type="radio"
      [id]="id + '3'"
      [name]="id + '3'"
      [value]="3"
      (ngModelChange)="onChange($event)"
    />
    <label class="" [for]="id + '3'">
      <img
        src="./assets/general/to-na-wiz/pesquisa-ex-v2/no-mouth-emoji.svg"
        alt="emoji triste"
      />
      <span>Neutra</span>
    </label>

    <input
      title="id4"
      [(ngModel)]="input"
      type="radio"
      [id]="id + '4'"
      [name]="id + '4'"
      [value]="4"
      (ngModelChange)="onChange($event)"
    />
    <label class="" [for]="id + '4'">
      <img
        src="./assets/general/to-na-wiz/pesquisa-ex-v2/excited-emoji.svg"
        alt="emoji triste"
      />
      <span>Boa</span>
    </label>

    <input
      title="id5"
      [(ngModel)]="input"
      type="radio"
      [id]="id + '5'"
      [name]="id + '5'"
      [value]="5"
      (ngModelChange)="onChange($event)"
    />
    <label class="" [for]="id + '5'">
      <img
        src="./assets/general/to-na-wiz/pesquisa-ex-v2/blush-emoji.svg"
        alt="emoji triste"
      />
      <span>Ótima</span>
    </label>
  </div>
</div>
