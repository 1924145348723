import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StepInfo } from "src/app/models/step-info-model";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  @Input() completedSteps: StepInfo[];
  @Input() initialDate: string;
  @Input() mobileVersion: boolean;

  @Output() currentStep = new EventEmitter<number>();
  @Output() backPressed = new EventEmitter<boolean>();

  public onboardingComplete: boolean;

  constructor() {}

  ngOnInit(): void {
    this.completedSteps = !this.completedSteps ? [] : this.completedSteps;
    this.checkOnboarding();
  }

  public changeCurrentStep(step: number): void {
    this.currentStep.emit(step);
  }

  public clickOnBack(): void {
    this.backPressed.emit(true);
  }

  public checkOnboarding(): void {
    this.onboardingComplete = this.completedSteps[13]?.completed;

    // if (this.completedSteps) {
    //   this.completedSteps.forEach((step) => {
    //     if (step.completed === false) {
    //       this.onboardingComplete = false;
    //     }
    //   });
    // }
  }
}
