import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MainServiceService } from './main-service.service';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  constructor(private readonly mainService: MainServiceService) {}

  public photoProfile(email: string): Observable<string> {
    return this.mainService.request(
      'get',
      environment.gateway,
      `msgraph/v1/Profile/photo/${email}`
    );
  }
}
