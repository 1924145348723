<section class="side-nav">

    <div *ngIf="mobileVersion" class="mobile-menu">
        <img (click)="clickOnBack()" src="../assets/general/to-na-wiz/to-na-wiz/mobile-redesign/arrow-back.svg"
            alt="back">
    </div>


    <h3>Sua jornada começa aqui!</h3>

    <div class="itens" *ngIf="completedSteps">
        <!-- <div (click)="changeCurrentStep(0)" class="list-item">
            <span>Aceite a proposta</span>
            <img *ngIf="completedSteps[0]?.completed; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div> -->
        <div (click)="changeCurrentStep(1)" class="list-item">
            <span>Entregue seus documentos</span>
            <img *ngIf="completedSteps[1]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(2)" class="list-item">
            <span>Entenda seus benefícios</span>
            <img *ngIf="completedSteps[2]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(3)" class="list-item">
            <span>Conheça o Grupo Wiz Co</span>
            <img *ngIf="completedSteps[3]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(4)" class="list-item">
            <span>Descubra nossa cultura</span>
            <img *ngIf="completedSteps[4]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(5)" class="list-item">
            <span>Prepare-se para o primeiro dia</span>
            <img *ngIf="completedSteps[5]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>


        <h3 style="margin-top: 20px;">Você começa na Wiz dia {{ initialDate | date:'dd/MM'}}</h3>
        <div (click)="changeCurrentStep(7)" class="list-item">
            <span>Mostre seu orgulho em se tornar Wizzer</span>
            <img *ngIf="completedSteps[7]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(8)" class="list-item">
            <span>Resgate seus mimos</span>
            <img *ngIf="completedSteps[8]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(9)" class="list-item">
            <span>Conheça nossas ferramentas</span>
            <img *ngIf="completedSteps[9]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(10)" class="list-item">
            <span>Entenda nossas ações de diversidade</span>
            <img *ngIf="completedSteps[10]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(11)" class="list-item">
            <span>Assine alguns termos importantes</span>
            <img *ngIf="completedSteps[11]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>
        <div (click)="changeCurrentStep(12)" class="list-item">
            <span>Compareça ao Wiz Welcome</span>
            <img *ngIf="completedSteps[12]?.completed === true; else unchecked"
                src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg" alt="ok">
        </div>

        <!-- <button class="finalized-onboarding" [disabled]="!onboardingComplete">
            <img *ngIf="onboardingComplete" src="../assets/general/to-na-wiz/to-na-wiz/redesign/trophy.svg" alt="troféu">
            <span>Onboarding finalizado!</span>
        </button> -->
    </div>

    <ng-template #unchecked>
        <img src="../assets/general/to-na-wiz/to-na-wiz/redesign/uncheck_icon.svg" alt="não ok">
    </ng-template>
</section>
