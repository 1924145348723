import { Component, OnInit } from '@angular/core';
import { SSOConectorService } from '@wizsolucoes/ngx-wiz-sso';
import { Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { GraphService } from '../../../services/graph.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfigurationInMemoryService } from 'src/app/services/configuration/in-memory/configuration-in-memory.service';
import { AppConfiguration } from 'src/app/services/configuration/configuration';
import { Tenant } from 'src/app/interfaces/tenant';
import { PublicAuthService } from 'src/app/services/public-auth.service';
import { TenantService } from 'src/app/services/configuration/tenant/tenant.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    // LocalStorageManagerService,
    { provide: 'scope', useValue: 'auth' },
  ],
})
export class HeaderComponent implements OnInit {
  // objeto para recuperar informações do usuário logado.
  public photo: string;
  public userProfile = this.profile.checkUserProfile();
  public objToNaWiz: any;
  public displayName: string;
  public tenantLogo: string;
  configuration: AppConfiguration | undefined;
  public tenants: Tenant[] = [];
  public selected = "";
  public isAdmin = false

  constructor(
    private readonly router: Router,
    private readonly sso: SSOConectorService,
    public profile: ProfileService,
    public graphService: GraphService,
    private inMemoryService: ConfigurationInMemoryService,
    private readonly publicAuthService: PublicAuthService,
    private readonly configInMemoryService: ConfigurationInMemoryService,
    private readonly tenantService: TenantService
  ) {}

  ngOnInit() {

    if ( localStorage.getItem('isAdmin') === 'true') {
      this.isAdmin = true
      this.getTenants()
    }



    this.inMemoryService.configurationCurrent$.subscribe((res) => {
      this.configuration = res;
    });
    const wizzerLoggedString = localStorage.getItem('wizer');

    if (!wizzerLoggedString) {
      return;
    }

    if (this.userProfile !== 'admin') {
      const adminLoggedString = localStorage.getItem('admin');

      if (!adminLoggedString) {
        console.error('Não foi encontrado o admin no localStorage');
        return '';
      }
      this.userProfile = JSON.parse(adminLoggedString);
    }
    this.objToNaWiz = JSON.parse(wizzerLoggedString);
    this.getDisplayName();
  }

  public getTenants() {
    const token = SSOConectorService.isLogged();
    const jwtData = this.profile.parseJwt(token.hash);
    const cpf = jwtData.documento

    this.userProfile = this.profile.checkUserProfile();
    console.log('user profile', this.userProfile)

    this.publicAuthService.getTenantsByCpf(cpf).subscribe(
      (res) => {
        this.tenants = res;
        if (this.tenants.length === 1)
          this.selected = this.tenants[0].shortName;
        else
          this.selected = '';
        // this.isLoading = false;
      },
      () => {
        // this.isLoading = false;
      }
    );
  }

  public setTenant() {
    let config = this.tenants.find((x) => x.shortName === this.selected);
    this.configInMemoryService.saveConfiguration(config as AppConfiguration);
    this.tenantService.setTenant(this.selected);
  }

  public userLogout() {
    this.sso.logOut();
    this.router.navigate([`/login`])
  }

  public carregarFoto(emailCorporativo: string) {
    // Função para resgatar a foto via teams
    this.graphService.photoProfile(emailCorporativo).subscribe((data) => {
      this.photo = data;
    });
  }

  public getDisplayName(): void {
    const obj = this.objToNaWiz;
    try {
      this.displayName = obj.apelido ? obj.apelido : obj.nome;
    } catch (error) {
      this.displayName = '';
    }
  }
}
