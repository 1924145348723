export const environment = {
  production: true,
  gateway: 'https://jornadas-prd-gateway.azurewebsites.net',
  workflowApi: 'https://api-workflowwiz.azurewebsites.net',
  apiRadar: 'https://radar-prd-fn.azurewebsites.net',
  tnwApi: 'https://tonawiz-prd-api.azurewebsites.net',
  hotjar: '1512512',
  tokenBot: 'x8T9J1FqIxE.x6SrtJtHd9p95QGB60FtOLCqMOLTzEAStm2MSI_BFB0',
  equinix: false,
  sso: 'https://sso.wiz.co',
  ssoConfig: {
    apiPath: 'https://jornadas-prd-gateway.azurewebsites.net',
    clientID: 'tonawiz',
    clientSecret:
      '5iaIKUL8farxyp0xNqJD6sjJ7G6SJoZTAMAPMsHAcQOl5r4eiSN0AFEOT07Y8xr9',
    grantType: 'password',
    authedPaths: ['https://jornadas-prd-gateway.azurewebsites.net'],
    scope: 'tonawiz idwiz.api_access',
    options: {
      ssoTimeOut: 60000,
      tokenAutoRefresh: true,
      loginRoute: 'login',
    },
  },
};
