import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-default-button-pulse-ex',
  templateUrl: './default-button-pulse-ex.component.html',
  styleUrls: ['./default-button-pulse-ex.component.scss']
})
export class DefaultButtonPulseExComponent implements OnInit {

  @Input() textButton: string;
  @Input() styleButton: string;
  @Input() fontSize: string;
  @Input() isLoading: boolean;
  @Input() fitWidth: boolean;
  @Input() urlIcon: boolean;
  @Input() disabled: boolean;

  @Output() callFunction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.fontSize = this.fontSize ? this.fontSize : '18';
  }

  public emitFunction(): void {
    this.callFunction.emit();
  }

}
