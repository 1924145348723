<div class="content-all">
  <div class="lower-section">
    <span class="title">Digite o código enviado por SMS</span>
    <form [formGroup]="codeForm">
      <div class="last-digits">
        <inpu
          title="input1"
          formControlName="input1"
          class="digit"
          type="text"
          maxlength="1"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          ngDefaultControl
        />
        <input
          title="input2"
          formControlName="input2"
          class="digit"
          type="text"
          maxlength="1"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          ngDefaultControl
        />
        <input
          title="input3"
          formControlName="input3"
          class="digit"
          type="text"
          maxlength="1"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          ngDefaultControl
        />
        <input
          title="input4"
          formControlName="input4"
          class="digit"
          type="text"
          maxlength="1"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          ngDefaultControl
        />
        <input
          title="input5"
          formControlName="input5"
          class="digit"
          type="text"
          maxlength="1"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          ngDefaultControl
        />
        <input
          title="input6"
          formControlName="input6"
          class="digit"
          type="text"
          maxlength="1"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          ngDefaultControl
        />
      </div>
    </form>
    <button
      type="submit"
      id="login"
      [ngClass]="{ 'submit-button': canSubmit, 'disabled-submit': !canSubmit }"
      (click)="confirmaCodigo()"
      [disabled]="!canSubmitCode()"
    >
      <span *ngIf="!loadingCode">validar código</span>
      <span *ngIf="loadingCode">
        <em class="fas fa-circle-notch fa-spin mr-2"></em>
        Enviando
      </span>
    </button>
  </div>
</div>
