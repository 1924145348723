import { PublicAuthService } from 'src/app/services/public-auth.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { StepInfo } from 'src/app/models/step-info-model';
import { OnboardingServiceService } from 'src/app/services/onboarding-service.service';
import { Etapa } from 'src/app/models/employee-data.model';

@Component({
  selector: 'app-page-onboarding',
  templateUrl: './page-onboarding.component.html',
  styleUrls: ['./page-onboarding.component.scss'],
})
export class PageOnboardingComponent {
  public loading = true;
  public explanationToShow: number;
  public objToNaWiz: any;
  public completedSteps = new Array<StepInfo>(14);
  public completePercentage: number;
  public employeeOnboardingData: {
    ativo: boolean;
    cargo: string;
    nome: string,
    emailCorporativo: string;
    idNewWizer: number;
    matricula: string;
    etapas: Array<Etapa>;
    nomeGestor: string;
    cpf: string,
    cpfGestor: string;
    cpfPadrinho: string;
    dataContratacao: string;
    dataEnvioConvite: string;
    dataCadastro: string;
  }

  public mobileVersion: boolean;
  public toggleExplanation: boolean;
  public toggleProfile: boolean;
  // public idCursoWizityWizWelcome = "2462";
  public idCursoWizityWizWelcome = '5434';
  public idCursoWizityImersao = '1538';
  public idCursoWizityLGPD = '3407';
  public idCursoWizityCompliance = '302';
  public idCursoWizityDiversidade = '2600';

  constructor(
    public breakpointObserver: BreakpointObserver,
    private onboardingService: OnboardingServiceService,
    public publicAuthService: PublicAuthService,
  ) {
    this.checkMobile();
    const wizzerLoggedString = localStorage.getItem('wizer') as any;

    if (!wizzerLoggedString) {
      console.error('Não foi encontrado o wizer no localStorange');
    }

    this.objToNaWiz = JSON.parse(wizzerLoggedString);
    if (this.objToNaWiz) {
      this.publicAuthService.getUserData(this.objToNaWiz.cpf).subscribe(
        (res) => {
          this.employeeOnboardingData = {
            nome: res.nome,
            emailCorporativo: res.emailCorporativo,
            nomeGestor: res.nomeGestor,
            cpf: res.cpf,
            idNewWizer: res.idNewWizer,
            matricula: res.matricula,
            etapas: res.etapas,
            cpfGestor: res.cpfGestor,
            cpfPadrinho: res.cpfPadrinho,
            cargo: res.cargo,
            ativo: res.ativo,
            dataContratacao: res.dataContratacao,
            dataEnvioConvite: res.dataEnvioConvite,
            dataCadastro: res.dataCadastro,
          }
          this.mapSteps(this.employeeOnboardingData?.etapas)
          this.verifyAutomaticSteps();
          this.loading = false;
        }
      )

    } else {
      alert('Wizzer não encontrado')
    }
  }

  public verifyAutomaticSteps(): void {
    if (!this.completedSteps[0]?.completed) {
      this.completeStep(0);
    }

    if (!this.completedSteps[6]?.completed) {
      this.checkDate();
    }

    if (!this.completedSteps[8]?.completed) {
      this.verifyCursoImersao();
    }

    if (!this.completedSteps[10]?.completed) {
      this.verifyCursoDiversidade();
    }

    if (!this.completedSteps[11]?.completed) {
      this.verifyTermoEtica();
    }

    if (!this.completedSteps[12]?.completed) {
      this.verifyCursoWizWelcome();
    }

    if (!this.completedSteps[13]?.completed) {
      // this.checkOnboardingCompletion();
      this.getUpdatedSteps();
    }
  }

  public checkMobile(): void {
    this.breakpointObserver
      .observe(['(min-width: 991px)'])
      .subscribe((state: BreakpointState) => {
        this.mobileVersion = !state.matches;
      });
  }

  public verifyTermoEtica(): void {
    if (this.objToNaWiz?.tipoModeloDeTrabalho) {
      this.onboardingService
        .getAcceptedTerm(this.objToNaWiz?.cpf, '2058')
        .subscribe(
          (res) => {
            if (res.aceito) {
              this.verifyTermoResponsabilidade();
            }
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }

  public checkOnboardingCompletion(): void {
    this.completeStep(-1);
  }

  public verifyTermoResponsabilidade(): void {
    if (this.objToNaWiz?.tipoModeloDeTrabalho) {
      this.onboardingService
        .getAcceptedTerm(this.objToNaWiz?.cpf, '35')
        .subscribe(
          (res) => {
            if (res.aceito) {
              if (
                this.objToNaWiz?.tipoModeloDeTrabalho.toUpperCase() ===
                'WE FLEX'
              ) {
                this.verifyTermoWeFlex();
              } else {
                this.completeStep(11);
              }
            }
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }

  public verifyTermoWeFlex(): void {
    if (this.objToNaWiz?.tipoModeloDeTrabalho) {
      this.onboardingService
        .getAcceptedTerm(this.objToNaWiz?.cpf, '34')
        .subscribe(
          (res) => {
            if (res.aceito) {
              this.completeStep(11);
            }
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }

  public verifyCursoDiversidade(): void {
    this.onboardingService
      .verifyEndTrilha(this.idCursoWizityDiversidade, this.objToNaWiz?.cpf)
      .subscribe(
        (res) => {
          if (res === true) {
            this.completeStep(10);
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  public verifyCursoImersao(): void {
    this.onboardingService
      .verifyEndTrilha(this.idCursoWizityImersao, this.objToNaWiz?.cpf)
      .subscribe(
        (res) => {
          if (res === true) {
            this.completeStep(8);
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  public verifyCursoWizWelcome(): void {
    this.onboardingService
      .verifyEndTrilha(this.idCursoWizityWizWelcome, this.objToNaWiz?.cpf)
      .subscribe(
        (res) => {
          if (res === true) {
            this.completeStep(12);
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  public calculateCompletePercentage(): number {
    let count = 0;

    try {
      this.completedSteps?.forEach((step) => {
        if (step.completed === true) {
          count++;
        }
      });
    } catch (error) {
      console.error(error);
    }

    return Math.round((count / 14) * 100.0);
  }

  public mapSteps(data: any[]): void {
    data?.forEach((etapa) => {
      this.completedSteps[etapa.ordem - 1] = {
        id: etapa.idOnboarding,
        completed: etapa.concluido,
        title: etapa.titulo,
      };
    });

    this.completePercentage = this.calculateCompletePercentage();
  }

  public completeStep(stepId: number): void {
    const idOnboarding =
      stepId === -1 ? -1 : parseInt(this.completedSteps[stepId]?.id, 10);

    this.onboardingService
      .updateOnbordingSteps({
        IDUser: this.employeeOnboardingData?.idNewWizer,
        IDOnboarding: this.employeeOnboardingData?.etapas[0].idOnboarding,
        cpf: this.objToNaWiz?.cpf,
        done: true,
      })
      .subscribe(
        (res) => {
          this.getUpdatedSteps();
        },
        (err) => {
          ('erro ao atualizar');
        }
      );
  }

  public getUpdatedSteps(): void {
    this.onboardingService.getArraySteps(this.employeeOnboardingData?.idNewWizer.toString()).subscribe(
      (res) => {
        this.completedSteps[13] = res.etapasConcluidas;
        this.mapSteps(res.etapas);
        this.completePercentage = this.calculateCompletePercentage();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public clickOnLogo(data: number): void {
    this.toggleExplanation = false;
    if (this.mobileVersion) {
      this.toggleProfile = false;
    }
  }

  public clickOnProfile(toggle: boolean): void {
    if (this.mobileVersion) {
      this.toggleProfile = toggle;
      this.toggleExplanation = false;
    }
  }

  public receiveStep(data: number): void {
    this.toggleExplanation = true;
    this.explanationToShow = data;
  }

  public changeView(): void {
    this.toggleExplanation = false;
    this.toggleProfile = false;
  }

  public updateSteps(data: Array<StepInfo>) {
    this.completedSteps = data;
  }

  public checkDate(): void {
    const dateAdmission = new Date(this.objToNaWiz?.dataContratacao);
    if (dateAdmission.getTime() < new Date().getTime()) {
      this.completeStep(6);
    }
  }
}
