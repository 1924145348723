import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantService } from '../tenant/tenant.service';
import { setDataStorage } from 'src/app/shared/utils/token.storage';
import { AppConfiguration } from '../configuration';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { LocalStorageManagerService } from '../../local-storage-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationApiService {
  constructor(
    private http: HttpClient,
    // private environment: EnvironmentService,
    private tenantService: TenantService,
    private localStorageService: LocalStorageManagerService
  ) {}

  fetchConfig(token: string): Observable<AppConfiguration> {
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    let shortName;
    shortName = shortName = this.localStorageService
      .getItem('shortName')
      ?.replace('/', '');

    return this.http
      .get(`${environment.tnwApi}/api/v1/tenant/${shortName}`, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          this.tenantService.setTenant(res.shortName);
          let config = res;
          if (res.json) {
            config = {
              ...res,
              theme: res.themeAttributes.theme,
              tenant: res.shortName,
            };
            delete config.json;
            setDataStorage(config, 'w-theme');
          }
          console.log('config', config);
          return config;
        })
      );
  }
}
