import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MainServiceService } from './main-service.service';

@Injectable({
  providedIn: 'root',
})
export class UpdatePasswordService {
  public jornadas = environment.tnwApi;

  constructor(private readonly mainService: MainServiceService) {}

  public updatePassword(resetPassword: object): Observable<any> {
    return this.mainService.request<object>(
      'post',
      this.jornadas,
      'api/account/redefinir-senha',
      resetPassword
    );
  }

  public forgotPassword(resetPassword: any): Observable<any> {
    return this.mainService.request<object>(
      'post',
      this.jornadas,
      'api/account/esqueci-minha-senha',
      resetPassword
    );
  }
}
