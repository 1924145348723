<nav>
  <div class="logo">
    <a href="/onboarding" *ngIf="configuration?.themeAttributes?.logo">
      <img [src]="configuration?.themeAttributes?.logo" alt="tenantLogo">
    </a>
  </div>

  <div class="logout">
    <div class="field" *ngIf="isAdmin" >
      <mat-form-field subscriptSizing="dynamic" class="input" appearance="outline">
        <mat-label>Selecione a empresa</mat-label>
        <mat-select title="Empresa" required="true" formControlName="tenant" [(value)]="selected" (selectionChange)="setTenant()">
          <div *ngFor="let tenant of tenants">
            <mat-option [value]="tenant.shortName">{{tenant.shortName}}</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <a (click)="userLogout()">
      <p>Sair</p>
      <span class="material-symbols-outlined">
        logout
        </span>
    </a>
  </div>
</nav>
