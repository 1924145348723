import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidateCPFService {
  constructor() {}

  // remove os dígitos do string em formato CPF
  public removeDigits(cpf: string): string {
    let result = cpf;
    result = result.split('.').join('');
    result = result.split('-').join('');
    return result;
  }

  // verifica se tem somente dígitos na entrada do CPF
  private isDigit(cpf: string): boolean {
    return /^\d+$/.test(cpf);
  }

  // função que verifica digito de validação do cpf
  private validateDigit(cpf: string): boolean {
    let soma: any = 0;
    let resto: any;

    if (cpf === '00000000000') {
      return false;
    }
    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 0) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10), 0)) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 0) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11), 0)) {
      return false;
    }
    return true;
  }

  public validate(cpf: string): boolean {
    let temp = this.removeDigits(cpf);
    if (!this.isDigit(temp))
      return false;
    if(temp.length !== 11)
      return false;
    return this.validateDigit(temp);
  }
}
