import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class LocalStorageManagerService {
  constructor() {}
  getItem(key: string) {
    return localStorage.getItem(key);
  }
  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }
  removeItem(key: string) {
    localStorage.removeItem(key);
  }
  public get Tenant(): string {
    return this.getItem("x-tenant") ?? '';
  }

  public set Tenant(tenant: string) {
    this.setItem("x-tenant", tenant);
  }

  public get ShortName(): string {
    return this.getItem("shortName") ?? '';
  }

  public set ShortName(shortName: string) {
    this.setItem("shortName", shortName);
  }

  public get Theme(): string {
    return this.getItem("connect-wiz:theme") ?? '';
  }

  public set Theme(theme: string) {
    this.setItem("connect-wiz:theme", theme);
  }

}
