import { Injectable } from '@angular/core';
import { MainServiceService } from './main-service.service';
import { Observable, throwError } from 'rxjs';
import { UserTerm } from '../models/user-term.model';
import { ResultCPFCheck } from '../models/result-cpf-check.model';
import { Credential } from '../models/credential.model';
import { CredentialsResult } from '../models/credential-result.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmployeeData } from '../models/employee-data.model';
import { Tenant } from '../interfaces/tenant';

export interface AccessToken {
  access_token: string,
  expires_in: number,
  token_type: string,
  scope: string
}

@Injectable({
  providedIn: 'root',
})
export class PublicAuthService {
  public jornadas = environment.gateway;
  public tnwApi = environment.tnwApi;

  constructor(
    public mainService: MainServiceService,
    public httpClient: HttpClient
  ) { }


  public getTenantByShortName(shortName: string, token: AccessToken): Observable<Tenant> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token.access_token}`,
      }),
    };

    return this.httpClient.get<Tenant>(
      `${this.tnwApi}/api/v1/tenant/${shortName}`,
      httpOptions,
    );
  }

  public getTenantsByCpf(cpf: string): Observable<Tenant[]> {
    return this.mainService.request<Tenant[]>(
      'get',
      this.tnwApi,
      `api/v1/usuario/tenants?cpf=${cpf}`
    );
  }

  public checkCPFSSO(cpf: string): Observable<ResultCPFCheck> {
    return this.mainService.request<ResultCPFCheck>(
      'get',
      this.tnwApi,
      `api/v1/usuario/cpf/${cpf}`
    );
  }

  public recomendaEmail(
    nomeCompleto: string,
    idNewWizer: string,
    un: string
  ): Observable<any> {
    return this.mainService.request<any>(
      'get',
      this.tnwApi,
      `api/v1/usuario/emaildisponivel?nomeCompleto=${nomeCompleto}&id=${idNewWizer}&un=${un}`
    );
  }

  public getEmailsRecomendados(
    nomeCompleto: string,
    idNewWizer: string,
    un: string,
    token: any
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token.access_token}`,
      }),
    };
    console.log('getEmailsRecomendados', token)

    return this.httpClient.get(
      `${this.tnwApi}/api/v1/usuario/emaildisponivel?nomeCompleto=${nomeCompleto}&id=${idNewWizer}&un=${un}`,
      httpOptions
    );
  }

  public checkCPFToNaWiz(cpf: string): Observable<ResultCPFCheck> {
    return this.mainService.request<ResultCPFCheck>(
      'get',
      this.tnwApi,
      `api/v1/onboarding/cpf/${cpf}`
    );
  }
  public getUserData(cpf: string): Observable<EmployeeData> {
    return this.mainService.request<EmployeeData>(
      'get',
      this.tnwApi,
      `api/v1/onboarding/cpf/${cpf}`
    );
  }

  public setUser(
    hashUser: string,
    credentials: Credential
  ): Observable<CredentialsResult> {
    return this.mainService.request<CredentialsResult>(
      'post',
      this.tnwApi,
      `api/v1/usuario`,
      credentials
    );
  }

  // TODO: tipar
  public sendCodForgetPassword(user: any): Observable<any> {
    return this.mainService.request<any>(
      'post',
      this.tnwApi,
      `api/v1/usuario/esqueci-minha-senha`,
      user
    );
  }
  // TODO: tipar
  public resetPassword(user: any, code: string): Observable<any> {
    return this.mainService.request<any>(
      'put',
      this.tnwApi,
      `api/v1/usuario/redefirnir-minha-senha/code/${code}`,
      user
    );
  }

  public setTerm(userTerm: UserTerm): Observable<boolean> {
    return this.mainService.request<boolean>(
      'put',
      this.jornadas,
      `api/v1/onboarding`,
      userTerm
    );
  }

  public getAccountSSO(cpf: string): Observable<any> {
    return this.mainService.request<any>(
      'get',
      this.jornadas,
      `api/account?cpf=${cpf}`
    );
  }


  public authWithoutLogin(): Observable<AccessToken> {
    const ssoConfig = environment.ssoConfig
    let formData = new FormData()
    formData.append('grant_type', 'client_credentials')
    formData.append('client_id', ssoConfig.clientID)
    formData.append('client_secret', ssoConfig.clientSecret)
    formData.append('scope', 'tonawiz idwiz.api_access')

    return this.httpClient.post<AccessToken>( `${environment.sso}/connect/token`, formData)
  }
}
