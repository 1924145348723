import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { EmployeeData } from 'src/app/models/employee-data.model';
import { GraphService } from 'src/app/services/graph.service';
import { OnboardingServiceService } from 'src/app/services/onboarding-service.service';

@Component({
  selector: 'app-profile-redesign',
  templateUrl: './profile-redesign.component.html',
  styleUrls: ['./profile-redesign.component.scss'],
})
export class ProfileRedesignComponent implements OnInit {
  @Input() objToNaWiz: any;
  @Input() employeeOnboardingData: EmployeeData;
  @Input() mobile: boolean;

  @Output() backPressed = new EventEmitter<boolean>();

  public emailScreen: string;
  public personalPhoto: string;
  public personalPhotoData: string;
  public managerPhoto: string;
  public godfatherPhoto: string;
  public copEmail = 'cacwiz@wiz.co';
  public itwContact = '0800 949 0000';

  public mapNameUnToUrl: Record<string, string> = {
    bpo: 'WIZ BPO',
    corporate: 'WIZ COPORATE',
    parceiros: 'WIZ PARCEIROS',
    corporativo: 'WIZ CORPORATIVO',
    conseg: 'WIZ CONSEG',
    saude: 'WIZ SAÚDE',
    partners: 'WIZ PARTNERS',
  };

  public workModelExplanation: Record<string, string> = {
    'WE HOME': 'Você trabalhará sempre de casa',
    'WE FLEX': 'Você trabalhará 2 dias de casa e 3 no escritório',
    'WE OFFICE': 'Você trabalhará sempre no escritório da Wiz',
  };

  public emailCorporativo: string;
  public emailPessoal: string;
  public emailGestor: string;
  public emailPadrinho: string;

  public copEmailCopied: boolean;
  public supportPhoneCopied: boolean;
  public managerEmailCopied: boolean;
  public godfatherEmailCopied: boolean;

  public units: any[];
  public unitName: string;

  constructor(
    public graphService: GraphService,
    private clipboardApi: ClipboardService,
    private onboardingService: OnboardingServiceService
  ) {}

  ngOnInit(): void {
    this.tryGetEmailsNeeded();
    this.getAllPhotos();
    this.getUnits();
  }

  public tryGetEmailsNeeded(): void {
    try {
      this.emailCorporativo = this.objToNaWiz?.emailCorporativo;
      this.emailPessoal = this.objToNaWiz?.emailPessoal;
      this.emailGestor = this.objToNaWiz?.emailGestor;
      this.emailPadrinho = this.objToNaWiz?.emailPadrinho;
    } catch (error) {
      console.log('err => ', error);
    }

    if (this.emailCorporativo) {
      this.emailScreen = this.emailCorporativo;
    } else {
      this.emailScreen = this.emailPessoal;
    }
  }

  public getAllPhotos() {
    if (this.emailCorporativo) {
      this.graphService
        .photoProfile(this.emailCorporativo.toLowerCase())
        .subscribe((data) => {
          this.personalPhotoData = data;
          this.personalPhoto = `data:image/jpg;base64,${data}`;
        });
    }
    if (this.emailGestor) {
      this.graphService
        .photoProfile(this.emailGestor.toLowerCase())
        .subscribe((data) => {
          this.managerPhoto = `data:image/jpg;base64,${data}`;
        });
    }
    if (this.emailPadrinho) {
      this.graphService
        .photoProfile(this.emailPadrinho.toLowerCase())
        .subscribe((data) => {
          this.godfatherPhoto = `data:image/jpg;base64,${data}`;
        });
    }
  }

  public shouldShowWorktime(): boolean {
    if (this.objToNaWiz?.horarioDeTrabalho) {
      return (
        this.objToNaWiz?.horarioDeTrabalho.toLowerCase() !== 'livre' &&
        this.objToNaWiz?.horarioDeTrabalho.toLowerCase() !== 'comercial'
      );
    } else {
      return false;
    }
  }

  public clickOnBack(): void {
    this.backPressed.emit(true);
  }

  public copyCopEmail(): void {
    this.clipboardApi.copyFromContent(this.copEmail);
    this.copEmailCopied = true;
    setTimeout(() => (this.copEmailCopied = false), 2000);
  }

  public copySupportPhone(): void {
    this.clipboardApi.copyFromContent(this.itwContact);
    this.supportPhoneCopied = true;
    setTimeout(() => (this.supportPhoneCopied = false), 2000);
  }

  public copyManagerEmail(): void {
    this.clipboardApi.copyFromContent(
      this.objToNaWiz?.emailGestor.toLowerCase()
    );
    this.managerEmailCopied = true;
    setTimeout(() => (this.managerEmailCopied = false), 2000);
  }

  public copyGodfatherEmail(): void {
    this.clipboardApi.copyFromContent(
      this.objToNaWiz?.emailPadrinho.toLowerCase()
    );
    this.godfatherEmailCopied = true;
    setTimeout(() => (this.godfatherEmailCopied = false), 2000);
  }

  public getUnits(): void {
    this.onboardingService.getUnits().subscribe(
      (res) => {
        this.units = res;
        for (let i = 0; i < this.units.length; i++) {
          if (this.objToNaWiz?.unidadeDeNegocio === this.units[i]?.chave) {
            this.unitName = this.units[i]?.nome;
            return this.unitName;
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public getFormatName(name: string): string {
    if (name?.includes('BEN.UP')) {
      return name.toLowerCase();
    }
    if (name?.includes('WIZ Soluções')) {
      return name.replace('WIZ Soluções', 'Wiz Corporativo');
    }
    if (name?.includes('WIZ Parceiros - Matriz')) {
      return name.replace('WIZ Parceiros - Matriz', 'WIZ Parceiros');
    }
    if (name?.includes('WIZ Parceiros - DF')) {
      return name.replace('WIZ Parceiros - DF', 'WIZ Parceiros');
    }
    if (name?.includes('WIZ')) {
      return 'Wiz ' + this.getRest(name);
    }

    return name;
  }

  public getRest(name: string): string {
    return name.split('WIZ')[1];
  }
}
