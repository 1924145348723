import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { StepInfo } from 'src/app/models/step-info-model';
import { OnboardingServiceService } from 'src/app/services/onboarding-service.service';

@Component({
  selector: 'app-step-explanation',
  templateUrl: './step-explanation.component.html',
  styleUrls: ['./step-explanation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepExplanationComponent implements OnInit {
  @Input() currentStep: number;
  @Input() completedSteps: Array<StepInfo>;
  @Input() objToNaWiz: any;
  @Input() mobileVersion: boolean;

  @Output() backPressed = new EventEmitter<boolean>();
  @Output() callUpdateSteps = new EventEmitter<any>();

  public benefitsRoute: string;
  public toggleMenu: boolean;

  public benefitsMap: Record<string, string> = {
    beneficios: 'beneficios',
    benup: 'benup',
    bpo: 'concept',
    conseg: 'conseg',
    corporate: 'corporate',
    corporativo: 'matriz',
    parceiros: 'parceiros',
    partners: 'partners',
  };

  public unityMap: Record<string, string> = {
    beneficios: 'WIZ BENEFÍCIOS',
    benup: 'BEN.UP',
    bpo: 'WIZ CONCEPT',
    conseg: 'WIZ CONSEG',
    corporate: 'WIZ CORPORATE PARTNERS',
    corporativo: 'WIZ CORPORATIVO',
    parceiros: 'WIZ PARCEIROS',
  };

  // public wizityMap: Record<string, string> = {
  //   beneficios: 'https://wizity.wiz.co/lms/#/aprendizagem/trilha/396',
  //   benup: 'https://wizity.wiz.co/lms/#/aprendizagem/trilha/920',
  //   corporativo: 'https://wizity.wiz.co/lms/#/aprendizagem/trilha/369',
  //   bpo: 'https://wizity.wiz.co/lms/#/aprendizagem/trilha/384',
  //   conseg: 'https://wizity.wiz.co/lms/#/aprendizagem/trilha/802',
  //   corporate: 'https://wizity.wiz.co/lms/#/aprendizagem/trilha/375',
  //   parceiros: 'https://wizity.wiz.co/lms/#/aprendizagem/trilha/390',
  // };

  public unitsFilters: any[];

  public links: any = {
    yube: 'https://app.yube.com.br/',
    linkedIn: 'https://www.linkedin.com/company/wiz-co/mycompany/',
    instagram: 'https://www.instagram.com/wiz_co_/',
    instaCarreiras: 'https://www.instagram.com/wizcarreiras/',
    spotify: 'https://open.spotify.com/show/0x1c3MDA3KcUOuSGQvTz4u',
    facebook: 'https://www.facebook.com/wiz.co.01/',
    site: 'https://wiz.co/',
    ri: 'https://ri.wiz.co/',
    wizWay: 'https://sway.office.com/cDFMDbtn41rflNAM',
    linkedInWizzer: 'https://www.linkedin.com/in/me/details/experience/',
    betanet: 'https://facil.wiz.co/home',
    wizity: 'https://wizity.wiz.co/',
    lg: 'https://login.lg.com.br/login/wiz',
    qultureRocks: 'https://app.qulture.rocks/wiz',
    kitOnboarding: 'https://resgate.beuni.com.br/wiz-kit-onboarding',
    wizCovers: 'https://wizco3.sharepoint.com/sites/RepositrioWizCo/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=iPfWxL&cid=8b3b0bde%2D9e72%2D42ba%2Db0fa%2De3d8fa5fbaff&FolderCTID=0x012000CCED104CB4188944B51695D2676A0A0F&OR=Teams%2DHL&CT=1699474220405&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yMzEwMTIyNzcwOCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&id=%2Fsites%2FRepositrioWizCo%2FShared%20Documents%2FReposit%C3%B3rio%20Comunica%C3%A7%C3%A3o%2FCapas%20%2D%20LinkedIn&viewid=634dc67f%2Dd0c8%2D4b70%2D842f%2D2b48131b4f07',
    wizWallpapers: 'https://wizco3.sharepoint.com/sites/RepositrioWizCo/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=iPfWxL&cid=8b3b0bde%2D9e72%2D42ba%2Db0fa%2De3d8fa5fbaff&FolderCTID=0x012000CCED104CB4188944B51695D2676A0A0F&OR=Teams%2DHL&CT=1699474220405&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yMzEwMTIyNzcwOCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&id=%2Fsites%2FRepositrioWizCo%2FShared%20Documents%2FReposit%C3%B3rio%20Comunica%C3%A7%C3%A3o%2FFundos%20de%20tela%20%2D%20Teams&viewid=634dc67f%2Dd0c8%2D4b70%2D842f%2D2b48131b4f07',
    wizTreinamento: 'https://wiz.konviva.com.br/lms/#/aprendizagem/trilha/1251?c=7389&secao=7394',
  };

  public coursesTerms: any = {
    diversidadeInclusaoCurso:
      'https://wiz.konviva.com.br/lms/#/aprendizagem/catalogo/infos_gerais/?idmatricula=0&secao=7103&idcatalogo=7077&idcurso=2600',
    diversidadeCenso: 'https://bit.ly/censo-de-diversidade',
    condutaEtica:
      'https://wiz.konviva.com.br/lms/#/aprendizagem/catalogo/infos_gerais/?idmatricula=0&secao=7391&idcatalogo=7389&idcurso=302',
    termoCondutaEtica: 'https://termo.wiz.co/Politicas/cadastro/2058',
    cartilha:
      'https://tonawiz.wiz.co/assets/general/to-na-wiz/pdf/cartilha_WE_v2.pdf',
    termoWEFlex: 'https://termo.wiz.co/Politicas/Cadastro/34',
    termoWEOffice: 'https://termo.wiz.co/Politicas/Cadastro/35',
  };

  constructor(private onboardingService: OnboardingServiceService) {}

  ngOnInit(): void {
    if (this.objToNaWiz) {
      this.benefitsRoute = `https://facil.wiz.co/modeloBeneficio/un/${
        localStorage.getItem('shortName')
      }`;
    }
    this.updateSteps();
    this.getBussinessArea();
  }

  public redirectTo(url: string): void {
    window.open(url);
  }

  public changeExplanation(num: number): void {
    if (this.currentStep >= 0 && this.currentStep <= 13) {
      this.currentStep += num;
    }
  }

  public completeStepWithRedirect(link: string): void {
    this.completeStep();
    this.redirectTo(link);
  }

  public completeStep(): void {
    this.onboardingService
      .updateOnbordingSteps({
        IDUser: this.objToNaWiz.idNewWizer,
        IDOnboarding: Number(this.completedSteps[this.currentStep]?.id),
        done: true,
        cpf: this.objToNaWiz.cpf,
      })
      .subscribe(
        (res) => {
          this.completedSteps[this.currentStep]!.completed = true;
          this.updateSteps();
          console.log('ok', res);
        },
        (err) => {
          console.error(err);
        }
      );
  }

  public changeCurrentStep(step: number): void {
    this.toggleMenu = false;
    //console.log(step);
    this.currentStep = step;
  }

  public checkWeFlex(): boolean {
    return this.objToNaWiz.tipoModeloDeTrabalho?.toUpperCase() === 'WE FLEX';
  }

  public clickOnBack(): void {
    this.backPressed.emit(true);
  }

  public clickOnMenu(): void {
    this.toggleMenu = true;
  }

  public menuBackPressed(): void {
    this.toggleMenu = false;
  }

  public updateSteps(): void {
    console.log('step explanation')
    this.onboardingService
      .getArraySteps(this.objToNaWiz?.idNewWizer?.toString())
      .subscribe((res) => {
        res.etapas.forEach(
          (etapa: {
            ordem: number;
            idOnboarding: any;
            concluido: any;
            titulo: any;
          }) => {
            this.completedSteps[etapa.ordem - 1] = {
              id: etapa.idOnboarding,
              completed: etapa.concluido,
              title: etapa.titulo,
            };
            this.callUpdateSteps.emit(this.completedSteps);
          }
        );
      });
  }

  // public redirectToWizity(link: string): void {
  //   this.redirectTo(this.wizityMap[link]);
  // }

  public downloadFile(path: string, filename: string): void {
    const link = document.createElement('a');
    link?.setAttribute('target', '_blank');
    link?.setAttribute('href', path + filename);
    link?.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    // this.completedSteps[this.currentStep].completed = true;
  }

  public downloadFileAndComplete(path: string, filename: string): void {
    this.downloadFile(path, filename);
    this.completeStep();
  }

  public getBussinessArea(): void {
    this.onboardingService.getBusinessUnitList().subscribe(
      (res) => {
        this.unitsFilters = res
          .filter((x: any) => {
            if (x.active) {
              return x && x.id !== 8;
            }
          })
          .sort((a: any, b: any) => (a.id > 0 && a?.name >= b?.name ? 1 : -1));

        console.log(res);
      },
      (err) => {
        this.unitsFilters = err;
        console.error(this.unitsFilters);
      }
    );
  }

  public getUnitName(name: string): string {
    if (name.includes('BEN.UP')) {
      return name.toLowerCase();
    }
    if (name.includes('WIZ Soluções')) {
      return name.replace('WIZ Soluções', 'Wiz Co');
    }
    if (name.includes('WIZ Parceiros - Matriz')) {
      return name.replace('WIZ Parceiros - Matriz', 'WIZ Parceiros');
    }
    if (name.includes('WIZ')) {
      return 'Wiz' + this.getRest(name);
    }

    return name;
  }

  public getRest(name: string): string {
    return name.split('WIZ')[1];
  }
}
