import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public sso = environment.ssoConfig;

  constructor() {}

  perfisAdminTnw = [
    'wiz.wiz.tonawiz.operador.read',
    'wiz.wiz.tonawiz.admin.full',
    'wiz.wiz.tonawiz.operador.full',
  ];

  public checkUserProfile() {
    let userProfile = '';

    const authDataString = localStorage.getItem('auth_data');

    if (!authDataString) {
      return;
    }

    try {
      const authData = JSON.parse(authDataString);
      const retornoParseJwt = this.parseJwt(authData?.hash);

      let rolePerfil =
        retornoParseJwt[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ];

      if (!rolePerfil) {
        console.error('Perfil não foi encontrado no auth data');
        return '';
      }

      // verificação necessária pois há casos que o campo claims/role do token é somente uma string e não um array
      if (this.perfisAdminTnw.find((x) => x.includes(rolePerfil))) {
        rolePerfil = [rolePerfil];
      }

      if (rolePerfil.includes(this.perfisAdminTnw[1])) {
        userProfile = 'admin';
      } else if (rolePerfil.includes(this.perfisAdminTnw[2])) {
        userProfile = 'operador-full';
      } else if (rolePerfil.includes(this.perfisAdminTnw[0])) {
        userProfile = 'operador';
      } else {
        console.error('Não tem permissão');
      }
    } catch (error) {
      console.log('error', error);
    }

    return userProfile;
  }

  public parseJwt(token: any) {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + `00 ${c.charCodeAt(0).toString(16)}`.slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
}
