import { Component } from '@angular/core';
import {
  BotDirective,
  BotHelperDirective,
  StyleSetDirective,
  BotService,
  ComService,
} from 'ngx-microsoft-bot-framework';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  providers: [
    BotService,
    ComService,
    BotDirective,
    BotHelperDirective,
    StyleSetDirective,
  ],
  styleUrls: ['./chat-bot.component.scss'],
})
export class ChatBotComponent {}

// export class ChatBotComponent implements OnInit, AfterViewInit {

//   // The false setting for secretSetting disables the web call to the botframework/api/token generator so the url is not neccessary
//   @ViewChild('botWindow', { static: false }) botWindowElement: ElementRef;
//   passViewChild: ViewChild;
//   // renderObject: IWebChat;
//   objToNaWiz: any;
//   chatVisible = false; // control chat box visibilite

//   styleToNaWiz: DEFAULT_OPTIONS = { // variable that hold all custom style into chat box

//     // container card
//     sendBoxBorderTop: '10px',

//     // Avatar
//     // avatarSize: 40,
//     botAvatarImage: './assets/general/to-na-wiz/to-na-wiz/chat-bot/avatar-bot.png',

//     // Color and paddings
//     backgroundColor: '#ffffff',
//     cardEmphasisBackgroundColor: '#F0F0F0',

//     // Word break
//     messageActivityWordBreak: 'break-word', // 'normal' || 'break-all' || 'break-word' || 'keep-all'

//     // Fonts
//     fontSizeSmall: '80%',

//     // Bubble
//     // bubble from BOT
//     bubbleBorderColor: '#eaeef1',
//     bubbleBorderRadius: '0 25px 25px',
//     bubbleBackground: '#eaeef1',
//     bubbleBorderStyle: 'solid',
//     bubbleBorderWidth: 1,

//     // bubble from user
//     bubbleFromUserBackground: '#ff9d00',
//     bubbleFromUserBorderRadius: '25px 25px 0',
//     bubbleFromUserTextColor: '#ffffff',
//     bubbleFromUserBorderStyle: 'solid',
//     bubbleFromUserBorderWidth: 1,

//     // Send box
//     hideSendBox: false,
//     hideUploadButton: true,
//     sendBoxBackground: '#eaeef1',
//     sendBoxTextColor: 'black',

//     // Markdown
//     // markdownRespectCRLF: false,

//   };

//   constructor(
//     private readonly comService: ComService,
//     private readonly bot: BotDirective,
//     private readonly botService: BotService, ) { }

//   ngOnInit() {
//     this.objToNaWiz = JSON.parse(window.localStorage.getItem('wizer'));
//     this.customBotDirective(this.objToNaWiz);
//   }

//   public ngAfterViewInit(): void {
//     this.customBotDirective(this.objToNaWiz);
//   }

//   // commented lines below with non useds function from documentation

//   // setBotDirective(): void {
//   //   this.passViewChild = this.botWindowElement.nativeElement;
//   //   this.bot.botDirective(this.passViewChild);
//   // }

//   // obtainStylePayload() {
//   //   this.comService.obtainStylePayload(this.styleOptionsPayload, this.stylesetPayload);
//   // }

//   openChat() {
//     this.chatVisible = !this.chatVisible;
//   }

//   // custom function to make specific payload, style, events type, etc...

//   customBotDirective(user): void {
//     const token = environment.tokenBot;
//     const store = window.WebChat.createStore({}, ({ dispatch }) => next => action => {

//       if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
//         dispatch({
//           type: 'WEB_CHAT/SEND_EVENT',
//           payload: {
//             name: 'webchat/join',
//             value: {
//               nome: user.nome,
//               email: user.emailCorporativo,
//               DataContratacao: user.dataContratacao,
//               cpf: user.cpf,
//               matricula: user.matricula,
//               agencia: user.agencia,
//               localDeTrabalho: user.localDeTrabalho,
//               cargo: user.cargo,
//               unidadeDeNegocio: user.unidadeDeNegocio,
//               centroDeCusto: user.centroDeCusto
//             }
//           }
//         });

//       }
//       return next(action);
//     });

//     // function that actually wil renderizer chat box in div id selected
//     window.WebChat.renderWebChat({
//       directLine: window.WebChat.createDirectLine({ token }),
//       store,
//       locale: 'pt-br',
//       styleOptions: this.styleToNaWiz,
//     }, document.getElementById('webchat'));

//     document.querySelector('#webchat');
//   }
// }
