import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PesquisaExService } from 'src/app/components/pesquisa-ex/services/pesquisa-ex.service';

@Component({
  selector: 'app-landing-page-pesquisa-ex-v2',
  templateUrl: './landing-page-pesquisa-ex-v2.component.html',
  styleUrls: ['./landing-page-pesquisa-ex-v2.component.scss'],
})
export class LandingPagePesquisaExV2Component implements OnInit {
  public formAnswer = this.fb.group({
    motivoNota: ['', Validators.required],
    campoAbertoMotivoNota: [''],
    sugestao: [''],
  });

  public formRequest = this.fb.group({
    data: {
      id: 0,
      idPesquisa: 4,
      idUser: 0,
      data: '',
      conteudoJson: {},
    },
    cpf: null,
  });

  public showInputMotivo = false;
  public optionsToShow: string[] = [];
  public loadingSubmitAnswer = false;
  public controlContent = true;
  public msgWarning = '';
  public valueInputReceived = 3;

  constructor(
    private route: ActivatedRoute,
    private readonly fb: UntypedFormBuilder,
    private readonly pesquisaExService: PesquisaExService
  ) {}

  ngOnInit(): void {
    this.setInfoUserInForm();
    this.defineOptions();
    this.onchanges();
  }

  public onchanges() {
    this.formAnswer.valueChanges.subscribe((x) => {
      console.log('Form: ', x);
      this.validateAnswer();
    });
  }

  // get value selected previous page used in this class to define options to show
  public defineOptions() {
    try {
      const previousAnswer = parseInt(
        this.route.snapshot.paramMap.get('value')!,
        10
      );

      this.valueInputReceived = previousAnswer;

      if (previousAnswer < 3) {
        this.optionsToShow = [
          'Falta informação',
          'Atendimento',
          'Tempo longo',
          'Erros na plataforma de envio de documentação',
          'Erro no Tô na Wiz',
          'Outro',
        ];
        this.showInputMotivo = true;
      } else if (previousAnswer > 3) {
        this.optionsToShow = [
          'Informações oferecidas',
          'Atendimento',
          'Tempo de contratação',
          'Plataforma para envio de documentação',
          'Plataforma Tô na Wiz',
          'Outro',
        ];
        this.showInputMotivo = true;
      } else {
        this.optionsToShow = [];
        this.formAnswer.patchValue({
          motivoNota: 'Outro',
        });
      }
    } catch (err) {
      console.error('error to get value in previous page', err);
    }
  }

  public setInfoUserInForm() {
    const key = 'wizer';
    try {
      const wizzerLoggedString = localStorage.getItem(key) as string;

      if (!wizzerLoggedString) {
        console.error('Não foi encontrado o wizer no localStorange');
        return '';
      }

      const wizzerLogged = JSON.parse(wizzerLoggedString);
      const todayDate = new Date();
      this.formRequest.value.data.data = todayDate.toISOString();
      if (wizzerLogged && wizzerLogged.cpf !== null) {
        this.formRequest.patchValue({
          emailCorporativo: wizzerLogged.emailCorporativo,
          cpf: wizzerLogged.cpf,
        });
      }
    } catch (error) {
      console.error('error get info user local storage: ', error);
    }
  }

  public validateAnswer(): boolean {
    this.msgWarning = '';
    if (!this.formAnswer.value.motivoNota) {
      this.msgWarning = `Selecione o motivo da nota.`;
      return false;
    }

    if (
      this.formAnswer.value.motivoNota === 'Outro' &&
      !this.formAnswer.value.campoAbertoMotivoNota
    ) {
      this.msgWarning = `Escreva o motivo da nota.`;
      return false;
    }

    return true;
  }

  public sendAnswer() {
    if (!this.validateAnswer()) {
      return;
    }
    this.formRequest.value.data.conteudoJson = JSON.stringify(
      this.formAnswer.value
    );
    this.loadingSubmitAnswer = true;
    this.pesquisaExService.sendAnswer(this.formRequest.value).subscribe(
      (res) => {
        this.loadingSubmitAnswer = false;
        this.controlContent = !this.controlContent;
      },
      (err) => {
        console.error('err: ', err);
        this.loadingSubmitAnswer = false;
        this.controlContent = !this.controlContent;
      }
    );
  }
}
