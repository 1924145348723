import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import { PublicAuthService } from '../../services/public-auth.service';
import { SSOConectorService } from '@wizsolucoes/ngx-wiz-sso';
import { AdminService } from '../../services/admin.service';
import { RemovePerfilSSO } from '../../models/remove-perfil-sso';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { UpdateSSO } from '../../models/update-sso-model';
import { FuncionarioModel } from 'src/app/models/funcionario-model';

@Component({
  selector: 'app-page-admin-new-operador',
  templateUrl: './page-admin-new-operador.component.html',
  styleUrls: ['./page-admin-new-operador.component.scss'],
})
export class PageAdminNewOperadorComponent implements OnInit {
  public conteudoEmployee: any[]; // array que salva os funcionarios retornados de acordo com a pesquisa
  public listOperators: any; // array que salva os administradores do onboarding
  public isSuccesAddPerfil: boolean; // variavel para informar que o perfil foi adiconado ao usuario
  public isSuccesRemovedPerfil: boolean; // variavel para informar que o perfil foi removido ao usuario
  public userProfile = this.profile.checkUserProfile();
  public mensagemErroCadastro: string; // mensagem que sera retornada para o usuario caso a API decorra em algum erro
  public mensagemErroRemocao: string; // mensagem que sera retornada para o usuario caso a API decorra em algum erro
  public mensagemErroLoading: string; // mensagem que sera retornada para o usuario caso a API decorra em algum erro
  public userUpdate: UpdateSSO; // model com os campos necessarios para update usuario no SSO
  public corpId: string; // variavel utilizada para salvar o ID da empresa retornado pela pesquisa no SSO
  public filtroPerfil: string; // variavel utilizada para guardar o tipo de perfil selecionado no input de select
  public profiles: string[] = ['Administrador', 'Operador full', 'Operador']; // nomes dos perfis de operador existente no tonawiz
  public mapProfiles = new Map(
    // map utilizado para resgatar o value de acordo com o o nome do profile
    [
      ['Administrador', 'wiz.tonawiz.admin.full'],
      ['Operador full', 'wiz.tonawiz.operador.full'],
      ['Operador', 'wiz.tonawiz.operador.read'],
    ]
  );
  public loadingEmployee: boolean; // controla loading da pesquisa de funcionarios
  public loadingOperadores: boolean; // controal loading dos admins do onboarding

  public nameEmployeeControl = new UntypedFormControl(''); // variavel de controle para verificar mudança de valor no input do nome de funcionário
  public cadastrarForm = this.fb.group({
    profile: ['Operador'], // inicializando o select com operador
    emailOp: [''],
    employeeName: this.nameEmployeeControl,
  });

  public tokenObj: Object;
  public token: string;

  constructor(
    private readonly router: Router,
    public profile: ProfileService,
    private readonly sso: SSOConectorService,
    private readonly admin: AdminService,
    public auth: PublicAuthService,
    private readonly fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.userProfile === 'colaborador' || this.userProfile === 'operador') {
      this.sso.logOut();
      this.router.navigate(['login']);
    }
    this.loadOperadores('Operador'); // a primeira vista sempre sera carregado o perfil de operador
    this.searchFuncEquipeEmployee(); // função para capturar quando esta sendo pesquisado o nome de algum funcionário
    this.getToken();
  }

  public loadOperadores(tipoPerfil: string) {
    this.filtroPerfil = tipoPerfil; // salvando o perfil para o load de user posteriomente quando for feita a remoção de alguém no perfil
    this.loadingOperadores = true;
    this.mensagemErroLoading = '';
    console.log(this.mapProfiles.get(tipoPerfil));
    this.admin.getProfileUsers(this.mapProfiles.get(tipoPerfil)!).subscribe(
      (result) => {
        const organizacoes = result.organizacoes;

        // zerando arrays a cada nova pesquisa de perfil
        const users: any[] = [];
        this.listOperators = users;

        if (organizacoes.length > 0) {
          this.corpId = result.organizacoes[0].id; // resgtando id retornado pelo SSO, para futuramente adicionar nas resquições
          for (const orgUsr of organizacoes) {
            for (const usuario of orgUsr.usuarios) {
              users.push(usuario);
            }
          }
          this.listOperators = users;
        }
        this.loadingOperadores = false;
      },
      (err) => {
        this.mensagemErroLoading = 'Erro no carregamento de operadores.';
        this.loadingOperadores = false;
        this.corpId = err;
      }
    );
  }

  public setInformationEmployee(selectedEmployee: any) {
    console.log(selectedEmployee);

    this.cadastrarForm.patchValue({
      emailOp: selectedEmployee.email,
      employeeName: selectedEmployee.nome,
    });

    this.userUpdate = {
      nomeCompleto: selectedEmployee.nome,
      cpf: selectedEmployee.cpf,
      email: selectedEmployee.email,
    };
  }

  public getToken() {
    this.tokenObj = this.admin.token().subscribe(
      (token) => {
        this.tokenObj = token;
        this.token = token.access_token;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // função que identifica que um novo nome sera pesquisado na api de funcionarios
  public searchFuncEquipeEmployee() {
    this.mensagemErroCadastro = '';
    this.conteudoEmployee = [];

    this.nameEmployeeControl.valueChanges
      .pipe(
        debounceTime(500), // delay utilizado para evitar mutiples hits na API
        switchMap((nome) => {
          this.loadingEmployee = true;
          if (nome.length > 3) {
            return this.admin.searchFuncAll(nome, this.token);
          } else {
            this.loadingEmployee = false;
            return [];
          }
        })
      )
      .subscribe(
        (res) => {
          if (Array.isArray(res)) {
            this.conteudoEmployee = res;
          } else {
            this.conteudoEmployee = [];
          }

          this.loadingEmployee = false;
        },
        (err) => {
          this.loadingEmployee = false;
          this.conteudoEmployee = err;
          this.mensagemErroCadastro =
            'Erro na hora de buscar colaborador na base de funcionários.';
        }
      );
  }

  // função para atualizar CPF no SSO.
  public verificaSSO() {
    this.auth.checkCPFSSO(this.userUpdate?.cpf).subscribe(
      (res) => {
        this.existeSSOResult(res.sucesso);
      },
      (err) => {
        this.existeSSOResult(err);
        this.mensagemErroCadastro =
          'Houve um erro na na hora de buscar CPF no SSO.';
      }
    );
  }

  public existeSSOResult(res: boolean) {
    //console.log('existe sso? ', res); // console debbug
    if (res) {
      //console.log('Cadastra operador'); // console debbug
      this.cadastrarOperador();
    } else {
      //console.log('Atualiza operador'); // console debbug
      this.updateCPFSSO();
    }
  }

  // função que adiciona perfil a um colaborador
  public cadastrarOperador() {
    this.mensagemErroCadastro = ''; // zerando mensagem de erro
    const dados = {
      email: this.cadastrarForm.value.emailOp,
      corpID: this.corpId,
      perfil: this.mapProfiles.get(this.cadastrarForm.value.profile),
    };
    this.admin.setUserInProfile(dados).subscribe(
      (res) => {
        this.isSuccesAddPerfil = true;
        this.loadOperadores(this.cadastrarForm.value.profile);
      },
      (err) => {
        this.mensagemErroCadastro =
          'Houve um erro ao adicionar perfil ao usuário. Verifique se usuário já foi adicionado a algum perfil';
      }
    );
    setTimeout(() => (this.isSuccesAddPerfil = true), 8000);
  }

  // função que remove um perfil atribuido ao usuario
  public removePerfil(obj: any) {
    this.mensagemErroRemocao = '';
    const dados = new RemovePerfilSSO();

    dados.email = obj.email;
    dados.corpID = this.corpId;
    dados.perfil = this.mapProfiles.get(this.filtroPerfil); // map utilizado para resgatar valor

    this.admin.removeUserInProfile(dados).subscribe(
      (res) => {
        // TODO: mostrar por user
        this.isSuccesRemovedPerfil = true;
        this.loadOperadores(this.filtroPerfil);
      },
      (err) => {
        this.mensagemErroRemocao = 'Houve um erro na remoção do perfil.';
      }
    );
    setTimeout(() => (this.isSuccesRemovedPerfil = true), 8000);
  }

  public updateCPFSSO() {
    this.mensagemErroCadastro = '';
    this.admin.updateAccountSSO(this.userUpdate).subscribe(
      (res) => {
        //console.log('Adicona perfil de operador no sso...'); // console debbbug
        this.cadastrarOperador();
      },
      (err) => {
        this.mensagemErroCadastro =
          'Houve um erro na atualização de CPF no SSO.';
      }
    );
    setTimeout(
      () =>
        (this.mensagemErroCadastro =
          'Houve um erro na atualização de CPF no SSO.'),
      8000
    );
  }
}
