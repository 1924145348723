<app-header></app-header>
<app-pop-up *ngIf="!mobileVersion"></app-pop-up>

<div *ngIf="!loading" class="conteudo" [ngClass]="{'four-em-padding': !toggleExplanation && !mobileVersion,
'no-padding': (toggleExplanation || toggleProfile) && mobileVersion}">

  <div *ngIf="!toggleExplanation && !mobileVersion" class="profile">
    <app-profile-redesign [mobile]="mobileVersion" [objToNaWiz]="objToNaWiz" [employeeOnboardingData]="employeeOnboardingData"></app-profile-redesign>
  </div>

  <div *ngIf="!mobileVersion && !toggleExplanation && !toggleProfile; else mobile_version" class="time-line">
    <app-admission-steps-redesign [completePercentage]="completePercentage" [objToNaWiz]="objToNaWiz"
      [completedSteps]="completedSteps" (selectedStep)="receiveStep($event)">
    </app-admission-steps-redesign>
  </div>

  <ng-template #mobile_version>
    <div *ngIf="!toggleExplanation && !toggleProfile" class="time-line">
      <app-mobile-admission-steps [completePercentage]="completePercentage" [objToNaWiz]="objToNaWiz"
        [completedSteps]="completedSteps" (selectedStep)="receiveStep($event)">
      </app-mobile-admission-steps>
    </div>
  </ng-template>

  <div *ngIf="!toggleExplanation && toggleProfile" class="profile">
    <app-profile-redesign *ngIf="mobileVersion" (backPressed)="changeView()" [mobile]="mobileVersion"
      [objToNaWiz]="objToNaWiz">
    </app-profile-redesign>
  </div>

  <div *ngIf="toggleExplanation" class="step-explanation">
    <app-step-explanation (backPressed)="changeView()" (callUpdateSteps)="updateSteps($event)"
      [completedSteps]="completedSteps" [currentStep]="explanationToShow" [objToNaWiz]="objToNaWiz"
      [mobileVersion]="mobileVersion">
    </app-step-explanation>
  </div>
</div>

<div *ngIf="loading" class="page-onboarding-loading">
  <img src="../../../assets/multitenant/loading.svg" alt="" sty>
</div>
