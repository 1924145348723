import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageManagerService } from 'src/app/services/local-storage-manager.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageManagerService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'X-Tenant': this.localStorageService.ShortName || 'wizco'
      }
    });
    return next.handle(request);
  }
}
