import { OnboardingStep } from './../models/onboarding-step.model';
import { Injectable } from '@angular/core';
import { MainServiceService } from './main-service.service';
import { Observable } from 'rxjs';
import { StepUpdateReturn } from '../models/step-update-return';
import { ArrayStepsUpdated } from '../models/array-steps-updated.model';
import { AcceptedTermReturn } from '../models/accepted-term-result.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OnboardingServiceService {
  public jornadas: string = environment.gateway;
  public tnwApi: string = environment.tnwApi;
  public radar: string = environment.apiRadar;

  constructor(public mainService: MainServiceService) {
  }

  public updateOnbordingSteps(
    onboardingStep: OnboardingStep
  ): Observable<StepUpdateReturn> {
    return this.mainService.request<StepUpdateReturn>(
      'put',
      this.tnwApi,
      `api/v1/onboarding/etapa`,
      onboardingStep,
      'private'
    );
  }
  public getArraySteps(iduser: string): Observable<any> {
    return this.mainService.request<ArrayStepsUpdated>(
      'get',
      this.tnwApi,
      `api/v1/onboarding/user/${iduser}/etapas`,
      null,
      'private'
    );
  }
  public verifyEndTrilha(idCurso: string, cpf: string): Observable<boolean> {
    return this.mainService.request<boolean>(
      'get',
      this.tnwApi,
      `api/v1/onboarding/etapa/curso/${idCurso}/cpf/${cpf}`,
      null,
      'private'
    );
  }
  public getAcceptedTerm(
    cpf: string,
    id: string
  ): Observable<AcceptedTermReturn> {
    return this.mainService.request<AcceptedTermReturn>(
      'get',
      this.jornadas,
      `api/v1/usuarios/aceite/termo/cpf/${cpf}/politica/${id}`
    );
  }

  public getBusinessUnitList(): Observable<any> {
    return this.mainService.request<any>(
      'get',
      this.radar,
      `api/v1/organization/businessunit/all`,
      null,
      'private'
    );
  }

  public getAreasOfOperation(): Observable<any> {
    return this.mainService.request<any>(
      'GET',
      this.radar,
      `api/v1/organization/areaofoperation?includeAll=true`,
      null,
      'private'
    );
  }

  public getUnits(): Observable<any> {
    return this.mainService.request<any>(
      'GET',
      this.tnwApi,
      `api/v1/unidadenegocio`,
      null,
      'private'
    );
  }

  public createUnit(id: string, data: any): Observable<any> {
    return this.mainService.request<any>(
      'POST',
      this.tnwApi,
      `api/v1/unidadenegocio/${id}`,
      data,
      'private'
    );
  }

  public deleteUnit(id: string): Observable<any> {
    return this.mainService.request<any>(
      'DELETE',
      this.tnwApi,
      `api/v1/unidadenegocio/${id}`,
      null,
      'private'
    );
  }

  public getUnitById(id: string): Observable<any> {
    return this.mainService.request<any>(
      'GET',
      this.tnwApi,
      `api/v1/unidadenegocio/${id}`,
      null,
      'private'
    );
  }
}
