<app-header></app-header>

<app-previous-route></app-previous-route>

<div class="content-all">
  <form [formGroup]="createUserForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <div class="title-position row">
        <p
          class="title-first"
          style="margin-top: 25px; font-size: 25px; font-weight: 700"
        >
          Formulário de Criação
        </p>
      </div>

      <div class="col shadow + item-card">
        <div class="row">
          <div class="col-6">
            <p style="margin-top: 25px; font-size: 25px">
              Dados do Colaborador
            </p>
          </div>
          <div class="col-6 d-flex justify-content-end required">
            <label class="" for="nome">Campos Obrigatório</label>
          </div>
        </div>
        <!-- FORM ROW -->
        <div class="form-row">
          <div class="col-xl-1 col-lg-2 mb-3">
            <label class="mt-2" for="nome">Matricula</label>
            <input
              title="matricula"
              formControlName="matricula"
              name="matricula"
              class="form-control"
              id="matricula"
              [ngClass]="{ 'is-invalid': submitted && f.matricula.errors }"
            />
            <div
              *ngIf="submitted && f.matricula.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.matricula.errors.required">
                Campo matricula é obrigatório
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-2 mb-3 required">
            <label class="mt-2" for="nome"> Status </label>
            <select
              title="status"
              class="form-control"
              formControlName="status"
              [ngClass]="{ 'is-invalid': submitted && f.status.errors }"
            >
              <option *ngFor="let item of status" value="{{ item }}">
                {{ item }}
              </option>
            </select>
            <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
              <div *ngIf="f.status.errors.required">
                Campo status é obrigatório
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 mb-3 required">
            <label class="mt-2" for="nome">Data de contratação</label>
            <input
              title="dataContratacao"
              type="date"
              formControlName="datacontratacao"
              name="datacontratacao"
              class="form-control"
              id="datacontratacao"
              [ngClass]="{
                'is-invalid': submitted && f.datacontratacao.errors
              }"
            />
            <div
              *ngIf="submitted && f.datacontratacao.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.datacontratacao.errors.required">
                Campo data de contratação é obrigatório
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 mb-3">
            <label class="mt-2" for="nome">Data de integração</label>
            <input
              title="dataintegracao"
              type="date"
              formControlName="dataintegracao"
              name="dataintegracao"
              class="form-control"
              id="dataintegracao"
              [ngClass]="{ 'is-invalid': submitted && f.dataintegracao.errors }"
            />
            <div
              *ngIf="submitted && f.dataintegracao.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.dataintegracao.errors.required">
                Campo data de integração é obrigatório
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-12 mb-3">
            <label class="mt-2" for="nome">Ativo</label>
            <div
              class="form-check mt-1"
              [ngClass]="{ 'is-invalid': submitted && f.ativo.errors }"
            >
              <input
                class="form-check-input"
                type="radio"
                name="ativo"
                id="ativo1"
                [value]="true"
                formControlName="ativo"
                checked
              />
              <label class="form-check-label" for="ativo1"> Ativado </label>
              <input
                class="form-check-input ml-4"
                type="radio"
                name="ativo"
                id="ativo2"
                [value]="false"
                formControlName="ativo"
              />
              <label class="form-check-label ml-5" for="ativo2">
                Desativado
              </label>
            </div>
            <div *ngIf="submitted && f.ativo.errors" class="invalid-feedback">
              <div *ngIf="f.ativo.errors.required">
                Campo ativo é obrigatório
              </div>
            </div>
          </div>
        </div>
        <!-- FORM ROW -->

        <!-- FORM ROW -->
        <div class="form-row">
          <div class="col-xl-4 col-lg-3 mb-3 required">
            <label class="mt-2" for="nome">Nome</label>
            <input
              formControlName="nome"
              name="nome"
              class="form-control"
              id="nome"
              [ngClass]="{ 'is-invalid': submitted && f.nome.errors }"
            />
            <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
              <div *ngIf="f.nome.errors.required">Campo nome é obrigatório</div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-3 mb-3 required">
            <label class="mt-2" for="nome">CPF</label>
            <input
              title="cpf"
              formControlName="cpf"
              name="cpf"
              class="form-control"
              id="cpf"
              maxlength="14"
              mask="000.000.000-00"
              (keyup)="verficadorCpf($any($event.target).value)"
              [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }"
            />
            <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
              <div *ngIf="f.cpf.errors.required">Campo cpf é obrigatório</div>
            </div>
            <div *ngIf="mensagemErro" class="mensagem-error mt-1">
              <img
                src="../../../assets/general/to-na-wiz/login//img/icons/alert.png"
                width="17"
                class="mr-2"
                alt=""
              />{{ mensagemErro }}
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 mb-3 required">
            <label class="mt-2" for="nome">Email Pessoal</label>
            <input
              title="emailPessoal"
              formControlName="emailpessoal"
              name="emailpessoal"
              class="form-control"
              id="emailpessoal"
              [ngClass]="{ 'is-invalid': submitted && f.emailpessoal.errors }"
            />
            <div
              *ngIf="submitted && f.emailpessoal.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.emailpessoal.errors.required">
                Campo email pessoal é obrigatório
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 mb-3">
            <label
              *ngIf="!messageSearchADSuccess && !messageSearchADErro"
              class="mt-2"
              for="nome"
            >
              Email Corporativo
            </label>
            <label *ngIf="messageSearchADSuccess" class="mt-2" for="nome"
              ><span class="badge badge-success">
                {{ messageSearchADSuccess }}
              </span></label
            >
            <label *ngIf="messageSearchADErro" class="mt-2" for="nome"
              ><span class="badge badge-danger">
                {{ messageSearchADErro }}
              </span></label
            >

            <span *ngIf="loadingSearchAD" class="icon-spinner fa-lg ml-3">
              <em class="fas fa-spinner fa-pulse"></em>
            </span>
            <div class="input-group">
              <input
                title="emailCorporativo"
                formControlName="emailcorporativo"
                name="emailcorporativo"
                class="form-control"
                id="emailcorporativo"
                [ngClass]="{
                  'is-invalid': submitted && f.emailcorporativo.errors
                }"
                [disabled]="loadingSearchAD"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="searchEmailAD()"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Pesquisar se tem email no AD"
                  [disabled]="loadingSearchAD"
                >
                  <em class="fa fa-search"></em>
                </button>
              </div>
            </div>
            <div
              *ngIf="submitted && f.emailcorporativo.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.emailcorporativo.errors.required">
                Campo email corporativo é obrigatório
              </div>
            </div>
          </div>
        </div>
        <!-- FORM ROW -->

        <!-- FORM ROW -->
        <div class="form-row">
          <div class="col-lg-3 mb-3 required">
            <label class="mt-2" for="nome">Cargo</label>
            <input
              title="cargo"
              formControlName="cargo"
              name="cargo"
              class="form-control"
              id="cargo"
              [ngClass]="{ 'is-invalid': submitted && f.cargo.errors }"
            />
            <div *ngIf="submitted && f.cargo.errors" class="invalid-feedback">
              <div *ngIf="f.cargo.errors.required">
                Campo cargo é obrigatório
              </div>
            </div>
          </div>

          <div class="col-lg-3 mb-3 required">
            <label class="mt-2" for="nome">Unidade de Negócio</label>
            <select
              title="unidadeDeNegocio"
              class="form-control"
              formControlName="unidadedenegocio"
              [ngClass]="{
                'is-invalid': submitted && f.unidadedenegocio.errors
              }"
            >
              <option
                *ngFor="let item of unidadeDeNegocio"
                value="{{ item?.key }}"
              >
                {{ item?.name }}
              </option>
            </select>
            <div
              *ngIf="submitted && f.unidadedenegocio.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.unidadedenegocio.errors.required">
                Campo unidade de negócio é obrigatório
              </div>
            </div>
          </div>

          <div class="col-lg-3 mb-3">
            <label class="mt-2" for="nome">Local de Trabalho</label>
            <input
              title="localDeTrabalho"
              formControlName="localdetrabalho"
              name="localdetrabalho"
              class="form-control"
              id="localdetrabalho"
              [ngClass]="{
                'is-invalid': submitted && f.localdetrabalho.errors
              }"
            />
            <div
              *ngIf="submitted && f.localdetrabalho.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.localdetrabalho.errors.required">
                Campo local de trabalho é obrigatório
              </div>
            </div>
          </div>

          <div class="col-lg-3 mb-3">
            <label class="mt-2" for="nome">Agência</label>
            <input
              title="agencia"
              formControlName="agencia"
              name="agencia"
              class="form-control"
              id="agencia"
            />
          </div>
        </div>
        <!-- FORM ROW -->

        <!-- FORM ROW -->
        <div class="form-row">
          <div class="col-lg-2 mb-3 required">
            <label class="mt-2" for="nome">Cidade</label>
            <input
              title="cidade"
              formControlName="cidade"
              name="cidade"
              class="form-control"
              id="cidade"
              [ngClass]="{ 'is-invalid': submitted && f.cidade.errors }"
            />
            <div *ngIf="submitted && f.cidade.errors" class="invalid-feedback">
              <div *ngIf="f.cidade.errors.required">
                Campo cidade é obrigatório
              </div>
            </div>
          </div>

          <div class="col-lg-1 mb-3 required">
            <label class="mt-2" for="nome">UF</label>
            <select
              title="estado"
              class="form-control"
              formControlName="uf"
              [ngClass]="{ 'is-invalid': submitted && f.uf.errors }"
            >
              <option *ngFor="let item of estados" value="{{ item }}">
                {{ item }}
              </option>
            </select>
            <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
              <div *ngIf="f.uf.errors.required">Campo UF é obrigatório</div>
            </div>
          </div>

          <div class="col-lg-3 mb-3">
            <label class="mt-2" for="nome">Bairro</label>
            <input
              title="bairro"
              formControlName="bairro"
              name="bairro"
              class="form-control"
              id="bairro"
            />
          </div>

          <div class="col-lg-3 mb-3">
            <label class="mt-2" for="nome">Telefone</label>
            <input
              title="telefone"
              formControlName="telefone"
              name="telefone"
              class="form-control"
              id="telefone"
              malgength="13"
              mask="(00) 00000000"
              [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }"
            />
            <div
              *ngIf="submitted && f.telefone.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.telefone.errors.required">
                Campo telefone é obrigatório
              </div>
            </div>
          </div>

          <div class="col-lg-3 mb-3">
            <label class="mt-2" for="nome">Celular</label>
            <input
              title="celular"
              formControlName="celular"
              name="celular"
              class="form-control"
              id="celular"
              maxlength="14"
              mask="(00) 000000000"
              [ngClass]="{ 'is-invalid': submitted && f.celular.errors }"
            />
            <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
              <div *ngIf="f.celular.errors.required">
                Campo celular é obrigatório
              </div>
            </div>
          </div>
        </div>
        <!-- FORM ROW -->

        <!-- FORM ROW -->
        <div class="form-row">
          <div class="col-lg-3 mb-3 required">
            <label class="mt-2" for="nome">Grupo Hierárquico</label>
            <input
              title="grupoHierarquico"
              formControlName="grupohierarquico"
              name="grupohierarquico"
              class="form-control"
              id="grupohierarquico"
              [ngClass]="{
                'is-invalid': submitted && f.grupohierarquico.errors
              }"
            />
            <div
              *ngIf="submitted && f.grupohierarquico.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.grupohierarquico.errors.required">
                Campo Grupo Hierárquico é obrigatório
              </div>
            </div>
          </div>

          <div class="col-lg-3 mb-3 required">
            <label class="mt-2" for="nome">Centro de Custo</label>
            <input
              title="centroDeCusto"
              formControlName="centrodecusto"
              name="centrodecusto"
              class="form-control"
              id="centrodecusto"
              [ngClass]="{ 'is-invalid': submitted && f.centrodecusto.errors }"
            />
            <div
              *ngIf="submitted && f.centrodecusto.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.centrodecusto.errors.required">
                Campo Centro de custo é obrigatório
              </div>
            </div>
          </div>
        </div>
        <!-- FORM ROW -->
      </div>

      <!-- INFORMACOES GESTOR -->
      <div class="col shadow + item-card">
        <div
          class="row"
          style="margin-top: 20px; padding: 10px; font-size: 25px"
        >
          <p style="margin-bottom: 15px">Dados do Gestor</p>
        </div>

        <div
          *ngIf="mensagemErroGestor"
          class="row alert alert-warning position-alert mt-4"
        >
          {{ this.mensagemErroGestor }}
        </div>

        <!-- FORM ROW -->
        <div class="form-row">
          <div class="col-lg-5 mb-3">
            <label class="mt-2" for="">Indique o gestor</label>
            <span *ngIf="loadingGestor" class="icon-spinner fa-lg ml-3"
              ><em class="fas fa-spinner fa-pulse"></em
            ></span>
            <input
              name="padrinho"
              formControlName="nomegestor"
              class="form-control form-control"
              type="text"
              autocomplete="off"
              placeholder="Pesquisar Gestor"
              [ngClass]="{ 'is-invalid': submitted && f.nomegestor.errors }"
            />
            <div
              *ngIf="submitted && f.nomegestor.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.nomegestor.errors.required">
                Campo nome do gestor é obrigatório
              </div>
            </div>
            <div class="result-auto-complete">
              <div class="item-container">
                <a
                  *ngFor="let r of conteudoGestor; let em = index"
                  class="item-result"
                  (click)="setInformationGestor(r)"
                >
                  <div class="item-list">
                    {{ r.nome }}
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-2 mb-3 required">
            <label class="mt-2" for="nome">CPF do gestor</label>
            <input
              title="cpfGestor"
              formControlName="cpfgestor"
              name="cpfgestor"
              class="form-control"
              id="cpfg"
              [disabled]="loadingGestor"
              [ngClass]="{ 'is-invalid': submitted && f.cpfgestor.errors }"
            />
            <div
              *ngIf="submitted && f.cpfgestor.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.cpfgestor.errors.required">
                Campo cpf do gestor é obrigatório
              </div>
            </div>
          </div>

          <div class="col-lg-5 mb-3 required">
            <label class="mt-2" for="nome">Email do gestor</label>
            <input
              title="emailGestor"
              formControlName="emailgestor"
              name="emailgestor"
              class="form-control"
              id="emailg"
              [disabled]="loadingGestor"
              [ngClass]="{ 'is-invalid': submitted && f.emailgestor.errors }"
            />
            <div
              *ngIf="submitted && f.emailgestor.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.emailgestor.errors.required">
                Campo email do gestor é obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FORM ROW -->

      <!-- INFORMACOES PADRINHO  -->
      <div class="col shadow + item-card">
        <div
          class="row"
          style="margin-top: 20px; padding: 10px; font-size: 25px"
        >
          <p style="margin-bottom: 15px">Dados do Padrinho</p>
        </div>

        <div
          *ngIf="mensagemErroPadrinho"
          class="row alert alert-warning position-alert mt-4"
          aria-label="Close"
        >
          {{ this.mensagemErroPadrinho }}
        </div>

        <!-- FORM ROW -->
        <div class="form-row">
          <div class="col-lg-5 mb-3">
            <label class="mt-2" for="">Indique o padrinho</label>
            <span *ngIf="loadingPadrinho" class="icon-spinner fa-lg ml-3"
              ><em class="fas fa-spinner fa-pulse"></em
            ></span>
            <input
              name="padrinho"
              formControlName="nomepadrinho"
              class="form-control form-control"
              type="text"
              autocomplete="off"
              placeholder="Pesquisar Padrinho"
              [ngClass]="{ 'is-invalid': submitted && f.nomepadrinho.errors }"
            />
            <div
              *ngIf="submitted && f.nomepadrinho.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.nomepadrinho.errors.required">
                Campo nome do padrinho é obrigatório
              </div>
            </div>

            <div class="result-auto-complete">
              <div class="item-container">
                <a
                  *ngFor="let r of conteudoPadrinho; let em = index"
                  class="item-result"
                  (click)="setInformationPadrinho(r)"
                >
                  <div class="item-list">
                    {{ r.nome }}
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-2 mb-3 required">
            <label class="mt-2" for="nome">CPF do padrinho</label>
            <input
              title="cpfPadrinho"
              formControlName="cpfpadrinho"
              name="cpfpadrinho"
              class="form-control"
              id="cpfp"
              [disabled]="loadingPadrinho"
              [ngClass]="{ 'is-invalid': submitted && f.cpfpadrinho.errors }"
            />
            <div
              *ngIf="submitted && f.cpfpadrinho.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.cpfpadrinho.errors.required">
                Campo cpf do padrinho é obrigatório
              </div>
            </div>
          </div>

          <div class="col-lg-5 mb-3 required">
            <label class="mt-2" for="nome">Email do padrinho</label>
            <input
              title="emailPadrinho"
              formControlName="emailpadrinho"
              name="emailpadrinho"
              class="form-control"
              id="emailp"
              [disabled]="loadingPadrinho"
              [ngClass]="{ 'is-invalid': submitted && f.emailpadrinho.errors }"
            />
            <div
              *ngIf="submitted && f.emailpadrinho.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.emailpadrinho.errors.required">
                Campo email do padrinho é obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="messageCreateUserSuccess"
        class="row alert alert-success position-alert mt-4"
      >
        {{ this.messageCreateUserSuccess }}
      </div>

      <div
        *ngIf="messageCreateUserErro"
        class="row alert alert-danger position-alert mt-4"
      >
        {{ this.messageCreateUserErro }}
      </div>

      <!-- FORM ROW -->
      <div
        class="row"
        style="
          margin-left: 1px;
          justify-content: flex-end;
          margin-top: 10px;
          border-top: 1px solid #f1f5f7;
          padding: 30px;
        "
      >
        <button class="new-collab-button" [disabled]="loadingSavingUser">
          <span *ngIf="!loadingSavingUser">Salvar</span>
          <span *ngIf="loadingSavingUser">
            <em class="fas fa-circle-notch fa-spin mr-2"></em>
            Salvando
          </span>
        </button>
      </div>
    </div>
  </form>
</div>
