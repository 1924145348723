export class EmailRecomendado {
  displayName: string;
  account: string;
  email: string;
  disponivel: string;

  constructor(
    displayName: string,
    account: string,
    email: string,
    disponivel: string
  ) {
    this.displayName = displayName;
    this.account = account;
    this.email = email;
    this.disponivel = disponivel;
  }
}
