import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepInfo } from 'src/app/models/step-info-model';

@Component({
  selector: 'app-mobile-admission-steps',
  templateUrl: './mobile-admission-steps.component.html',
  styleUrls: ['./mobile-admission-steps.component.scss'],
})
export class MobileAdmissionStepsComponent implements OnInit {
  @Input() completedSteps: StepInfo[];
  @Input() objToNaWiz: any;
  @Output() selectedStep = new EventEmitter<number>();

  public completePercentage: number;
  public mobileVersion: boolean;

  constructor() {}

  ngOnInit(): void {
    this.completePercentage = this.calculateCompletePercentage();
  }

  public sendStep(position: number): void {
    this.selectedStep.emit(position);
  }

  public calculateCompletePercentage(): number {
    let count = 0;

    if (this.completedSteps) {
      try {
        this.completedSteps.forEach((step) => {
          if (step.completed === true) {
            count++;
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    return Math.round((count / 14) * 100.0);
  }
}
