<section class="profile">
  <div *ngIf="mobile" class="mobile-menu">
    <img
      (click)="clickOnBack()"
      src="../assets/general/to-na-wiz/to-na-wiz/mobile-redesign/arrow-back.svg"
      alt="back"
    />
  </div>

  <div class="personal-info">
    <div class="header">
    <img
      class="profile-picture"
      [ngClass]="personalPhotoData ? 'profile-picture' : 'placeholder' "
      [src]="personalPhoto"
      [alt]="personalPhotoData ? 'foto' : ''"
    />
    <div class="header-data">
      <span class="name">{{
        objToNaWiz?.apelido ? objToNaWiz?.apelido : (objToNaWiz?.nome | titlecase)
      }}</span>
      <span class="position">{{ employeeOnboardingData?.cargo }}</span>
      </div>
    </div>
    <div class="personal-info-footer">
      <strong>Contato</strong>
      <span class="corporate-email">{{
        objToNaWiz?.emailCorporativo | lowercase
      }}</span>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="work-info">
    <span class="section-title">

      <svg id="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6665 5.00008H13.3332V3.33341C13.3332 2.40841 12.5915 1.66675 11.6665 1.66675H8.33317C7.40817 1.66675 6.6665 2.40841 6.6665 3.33341V5.00008H3.33317C2.40817 5.00008 1.67484 5.74175 1.67484 6.66675L1.6665 15.8334C1.6665 16.7584 2.40817 17.5001 3.33317 17.5001H16.6665C17.5915 17.5001 18.3332 16.7584 18.3332 15.8334V6.66675C18.3332 5.74175 17.5915 5.00008 16.6665 5.00008ZM11.6665 5.00008H8.33317V3.33341H11.6665V5.00008Z"/>
      </svg>
      <span class="text">Informações de trabalho</span>
    </span>

    <div class="info">
      <span class="title">Data de início</span>
      <span class="content">{{
        objToNaWiz?.dataContratacao | date : 'dd/MM/yyy'
      }}</span>
    </div>

    <div class="info">
      <p>
        <span class="title">Modelo de trabalho</span>
        <img
          mat-raised-button
          #tooltip="matTooltip"
          [matTooltip]="
            workModelExplanation[
              objToNaWiz?.tipoModeloDeTrabalho?.toUpperCase()
            ]
          "
          matTooltipPosition="right"
          src="./assets/general/to-na-wiz/to-na-wiz/redesign/help-icon.svg"
          alt="ajuda"
        />
      </p>
      <span class="content">{{
        objToNaWiz?.tipoModeloDeTrabalho?.toUpperCase()
      }}</span>
    </div>

    <div *ngIf="objToNaWiz?.tipoModeloDeTrabalho !== 'We Home'" class="info">
      <span class="title">Local de trabalho</span>
      <span class="content">Endereço</span>
    </div>

    <div class="info">
      <p>
        <span class="title">Unidade de negócio</span>
        <img
          mat-raised-button
          #tooltip="matTooltip"
          matTooltip="Empresa dentro do conglomerado Wiz"
          matTooltipPosition="right"
          src="./assets/general/to-na-wiz/to-na-wiz/redesign/help-icon.svg"
          alt="ajuda"
        />
      </p>
      <span class="content">{{ getFormatName(unitName) }}</span>
    </div>

    <div *ngIf="shouldShowWorktime()" class="info">
      <span class="title">Horário de trabalho</span>
      <span class="content">{{ objToNaWiz?.horarioDeTrabalho }}</span>
    </div>
  </div>

  <div class="useful-contacts">
    <span class="section-title">
      <svg id="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6665 3.33325H3.33317C2.4165 3.33325 1.67484 4.08325 1.67484 4.99992L1.6665 14.9999C1.6665 15.9166 2.4165 16.6666 3.33317 16.6666H16.6665C17.5832 16.6666 18.3332 15.9166 18.3332 14.9999V4.99992C18.3332 4.08325 17.5832 3.33325 16.6665 3.33325ZM16.6665 6.66658L9.99984 10.8333L3.33317 6.66658V4.99992L9.99984 9.16658L16.6665 4.99992V6.66658Z"/>
        </svg>
      <span class="text">Contatos úteis</span>
    </span>

    <div class="useful-contact">
      <p>
        <span class="title">COP</span>
        <img
          mat-raised-button
          #tooltip="matTooltip"
          matTooltip="Responsável pela sua admissão e documentação"
          matTooltipPosition="right"
          src="./assets/general/to-na-wiz/to-na-wiz/redesign/help-icon.svg"
          alt="ajuda"
        />
      </p>
      <div class="email-field">
        <span class="contact-email">{{ copEmail }}</span>
        <mat-icon
          (click)="copyCopEmail()"
          matTooltipPosition="above"
          mat-raised-button
          #tooltip="matTooltip"
          [matTooltip]="!copEmailCopied ? 'Copiar email' : 'Email copiado'"
          [matTooltipClass]="{ 'bg-tmp': copEmailCopied }"
          class="icon"
        >
          content_copy
        </mat-icon>
      </div>
    </div>

    <div class="useful-contact">
      <p>
        <span class="title">iTW</span>
        <img
          mat-raised-button
          #tooltip="matTooltip"
          matTooltip="Time responsável por conceder licenças, resolver problemas de acesso ou acesso a ferramentas corporativas"
          matTooltipPosition="right"
          src="./assets/general/to-na-wiz/to-na-wiz/redesign/help-icon.svg"
          alt="ajuda"
        />
      </p>
      <div class="email-field">
        <span class="contact-email">emaildocop@wiz.co</span>
        <mat-icon
          mat-raised-button
          #tooltip="matTooltip"
          [matTooltip]="
            !supportPhoneCopied ? 'Copiar contato' : 'Contato copiado'
          "
          matTooltipPosition="above"
          [matTooltipClass]="{ 'bg-tmp': supportPhoneCopied }"
          class="icon"
          (click)="copySupportPhone()"
          >content_copy
        </mat-icon>
      </div>
    </div>

    <div class="contact-with-photo">
      <div class="photo-and-name">
        <img
          [ngClass]="managerPhoto ? 'profile-picture' : 'placeholder' "
          [alt]="managerPhoto ? 'foto' : ''"
          [src]="managerPhoto"
          alt="foto"
        />
        <div>
          <p>
            <span class="title">Seu gestor</span>
            <img
              mat-raised-button
              #tooltip="matTooltip"
              matTooltip="Seu líder direto"
              matTooltipPosition="right"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/help-icon.svg"
              alt="ajuda"
            />
          </p>
          <span>{{ objToNaWiz?.nomeGestor }}</span>
        </div>
      </div>
      <div class="email-field">
        <span class="contact-email">{{
          objToNaWiz?.emailGestor | lowercase
        }}</span>
        <mat-icon
          (click)="copyManagerEmail()"
          matTooltipPosition="above"
          mat-raised-button
          #tooltip="matTooltip"
          [matTooltip]="!managerEmailCopied ? 'Copiar email' : 'Email copiado'"
          [matTooltipClass]="{ 'bg-tmp': managerEmailCopied }"
          class="icon"
        >
          content_copy
        </mat-icon>
      </div>
    </div>

    <div class="contact-with-photo">
      <div class="photo-and-name">
        <img
          [ngClass]="godfatherPhoto ? 'profile-picture' : 'placeholder' "
          *ngIf="godfatherPhoto !== undefined; else photo_placeholder"
          [src]="godfatherPhoto"
          alt="foto"
        />
        <div>
          <p>
            <span class="title">Seu padrinho</span>
            <img
              mat-raised-button
              #tooltip="matTooltip"
              matTooltip="Quem te ajudará no seu processo de adaptação"
              matTooltipPosition="right"
              src="./assets/general/to-na-wiz/to-na-wiz/redesign/help-icon.svg"
              alt="ajuda"
            />
          </p>
          <span>{{ objToNaWiz?.nomePadrinho }}</span>
        </div>
      </div>
      <div class="email-field">
        <span class="contact-email">{{
          objToNaWiz?.emailPadrinho | lowercase
        }}</span>
        <mat-icon
          (click)="copyGodfatherEmail()"
          matTooltipPosition="above"
          mat-raised-button
          #tooltip="matTooltip"
          [matTooltip]="
            !godfatherEmailCopied ? 'Copiar email' : 'Email copiado'
          "
          [matTooltipClass]="{ 'bg-tmp': godfatherEmailCopied }"
          class="icon"
        >
          content_copy
        </mat-icon>
      </div>
    </div>
  </div>
</section>

<ng-template #photo_placeholder>
  <div class="placeholder"></div>
</ng-template>
