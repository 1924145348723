<section class="login-page">
  <div class="login-page-container">
    <div class="login-form">
      <div class="conteudo-login">
        <div class="welcome">
          <div class="title">Boas vindas a</div>
          <img [src]="configuration?.themeAttributes?.logo ? configuration?.themeAttributes?.logo : this.defaultLogo" alt="" />
        </div>
        <form [formGroup]="loginForm">
          <div class="form-content">
            <div class="inputs">
              <div class="field">
                <mat-form-field subscriptSizing="dynamic" class="input" appearance="outline">
                  <mat-label>CPF</mat-label>
                  <input title="CPF" matInput type="text" maxlength="14" mask="000.000.000-00" name="cpf"
                    formControlName="cpf" (keyup)="onKeyUpCpf()" />
                  <mat-spinner *ngIf="LoadingTenants" class="spinner" matSuffix [diameter]="16" />
                  <mat-hint hidden="true" />
                </mat-form-field>
                <!-- Mensagens de erro abaixo do CPF -->
                <div class="error" *ngIf="MensagemErroCpf">
                  <div class="icon">
                    <mat-icon>error</mat-icon>
                  </div>
                  <span class="text">
                    {{MensagemErroCpf}}
                  </span>
                </div>
              </div>
              <div class="field">
                <mat-form-field subscriptSizing="dynamic" class="input" appearance="outline">
                  <mat-label>Senha</mat-label>
                  <input title="Senha" [type]="hidePassword ? 'password' : 'text'" matInput (keyup)="onKeyUpSenha()"
                    formControlName="password" />
                  <link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
                    rel="stylesheet" />
                  <mat-icon matSuffix fontSet="material-icons-outlined" (click)="hidePassword = !hidePassword">{{
                    hidePassword ? 'visibility' : 'visibility_off'
                    }}</mat-icon>
                </mat-form-field>
                <!-- Mensagens de erro abaixo da senha -->
                <div class="error" *ngIf="MensagemErroSenha">
                  <div class="icon">
                    <mat-icon>error</mat-icon>
                  </div>
                  <div class="text">
                    Senha incorreta. Tente novamente.
                  </div>
                </div>
              </div>
              <div class="field">
                <mat-form-field subscriptSizing="dynamic" class="input" appearance="outline">
                  <mat-label>Selecione a empresa</mat-label>
                  <mat-select title="Empresa" required="true" formControlName="tenant" [(value)]="selected">
                    <div *ngFor="let tenant of Tenants">
                      <mat-option [value]="tenant.shortName">{{tenant.shortName}}</mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <button type="submit" id="login" (click)="login()" [disabled]="!canLogin()">
              <span *ngIf="!Logging">Entrar</span>
              <span *ngIf="Logging"><em class="fas fa-circle-notch fa-spin mr-2"></em>Carregando</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="call-user min-h-screen"
      style="background-image: url(./assets/image-login-default.JPG);"></div>
  </div>
  <div class="footer">
    <p>powered by</p>
    <img width="100" src="https://catalogoprdstg.blob.core.windows.net/tonawiz/Wizco.svg" alt="logo wizco" />
  </div>
</section>
